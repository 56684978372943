/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useCallback, useContext } from "react";
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography,
    Box,
    Paper,
    Divider,
    Stack,
    Alert,
    Grid,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import EqualizerIcon from "@mui/icons-material/Equalizer";
import ResultBarGauges from "../../components/ResultBarGauges";
import ConsumptionResultBarGraph from "../../components/ConsumptionResultBarGraph";
import EmissionResultBarGraph from "../../components/EmissionResultBarGraph";
import fetchUserResults from "../../services/transformResultData";
import UpdateTimeStampContext from "./UpdateTimestampContext";
import EnergyEfficiencyClassesContext from "./EfficiencyClassesContext";
import dayjs from "dayjs";
import { LoadingSpinner } from "../../components/LoadingSpinner";

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "left",
    color: theme.palette.text.primary,
}));

/**
 * Component for displaying building results in an accordion format.
 *
 * This component shows the results of energy consumption and CO2 emissions for a selected building. It includes
 * bar gauges and graphs to visually represent the data. The component fetches and displays user results, energy
 * efficiency classes, and CO2 emission classes. It conditionally renders content based on the availability of data.
 *
 * Props:
 *   currentBuilding (Object): The building object for which the results are being displayed.
 *
 * Returns:
 *   ReactElement: A component that renders an accordion with building results, including consumption and emission graphs.
 */
export const BuildingResultsAccordion = ({ currentBuilding, energyCertificateValue }) => {
    const [fetchingBuildingResultsData, setFetchingBuildingResultsData] = useState(true);

    // building result data (for graphs)
    const [userResults, setUserResults] = useState(false);
    const { energyEfficiencyClasses, setEnergyEfficiencyClasses } = useContext(EnergyEfficiencyClassesContext);
    const { updateTimestamp } = useContext(UpdateTimeStampContext);
    const [co2EmissionClasses, setCo2EmissionClasses] = useState(null);
    const [userPreferredYear, setUserPreferredYear] = useState(0);

    const getDefaultResultYear = useCallback(() => {
        if (!userResults) {
            return [];
        }
        if (userPreferredYear > 0) {
            userResults.forEach((result) => {
                if (result.year === userPreferredYear && !result.only_forecast_data) {
                    res = [result];
                }
            });
            if (res) {
                return res;
            }
        }
        var res = [];
        userResults.toReversed().every((result) => {
            if (!result.only_forecast_data) {
                res = [result];
                return false;
            }
            return true;
        });
        return res;
    }, [userResults]);

    const addEnergyCertificateValueToResults = useCallback(
        (results) => {
            if (results.length === 0) {
                return [
                    {
                        energy_certificate_value: energyCertificateValue,
                        total_co2_emissions_per_sqm: null,
                        total_gross_energy_consumption_per_sqm: null,
                        total_pv_co2_savings_per_sqm: null,
                        total_pv_yield_per_sqm: null,
                        // today
                        year: dayjs().year(),
                    },
                ];
            }
            results[0].energy_certificate_value = energyCertificateValue;
            return results;
        },
        [energyCertificateValue]
    );

    useEffect(() => {
        if (currentBuilding.id !== -1) {
            setFetchingBuildingResultsData(true);
            (async () => {
                fetchUserResults({
                    filter_type: "single-building",
                    filter_choice: [currentBuilding.id],
                }).then((response) => {
                    setUserResults(response.userResults);
                    setUserPreferredYear(response.userPreferredYear);
                    setEnergyEfficiencyClasses(response.energyEfficiencyClasses);
                    setCo2EmissionClasses(response.co2EmissionClasses);
                    setFetchingBuildingResultsData(false);
                });
            })();
        }
    }, [currentBuilding, updateTimestamp]);

    return (
        <>
            {(userResults && userResults.length > 0) || fetchingBuildingResultsData ? (
                <>
                    <Stack height="20ch" width="160ch" spacing={3} mt={3}>
                        <div style={{ position: "relative" }}>
                            <Item>
                                <Box height="20ch">
                                    {(co2EmissionClasses &&
                                        energyEfficiencyClasses &&
                                        ((getDefaultResultYear() && getDefaultResultYear().length > 0) ||
                                            energyCertificateValue)) ||
                                    fetchingBuildingResultsData ? (
                                        <>
                                            {fetchingBuildingResultsData ? (
                                                LoadingSpinner
                                            ) : (
                                                <>
                                                    <ResultBarGauges
                                                        co2EmissionClasses={co2EmissionClasses}
                                                        energyEfficiencyClasses={energyEfficiencyClasses}
                                                        heightScales={0.9}
                                                        userResults={addEnergyCertificateValueToResults(
                                                            getDefaultResultYear()
                                                        )}
                                                    />
                                                    <Grid
                                                        container
                                                        sx={{
                                                            display: "flex",
                                                            justifyContent: "center",
                                                            alignItems: "center",
                                                            ml: 0,
                                                        }}
                                                    >
                                                        <Box maxHeight="5ch" width="25ch">
                                                            <svg>
                                                                <g transform={`translate(10, 20)`}>
                                                                    <path
                                                                        transform={`translate(0,-1)`}
                                                                        d="M-10 0 L 10 0 L 0 -10 L -10 0 Z"
                                                                        fill={"#2D2D2D"}
                                                                    />
                                                                    <text
                                                                        transform={`translate(14, -3)`}
                                                                        fontFamily="Arial"
                                                                        fontSize="16"
                                                                        fill={"#000000"}
                                                                    >
                                                                        : Endenergieverbrauch
                                                                    </text>
                                                                </g>
                                                            </svg>
                                                        </Box>
                                                        <Box maxHeight="5ch" width="20ch">
                                                            <svg>
                                                                <g transform={`translate(10, 20)`}>
                                                                    <text
                                                                        // transform={`translate(-8.37,${1.1 + 17})`}
                                                                        fontFamily="Arial"
                                                                        fontSize="24"
                                                                        fill={"#000000"}
                                                                        fontWeight={"bold"}
                                                                    >
                                                                        A
                                                                    </text>
                                                                    <text
                                                                        transform={`translate(20, -3)`}
                                                                        fontFamily="Arial"
                                                                        fontSize="16"
                                                                        fill={"#000000"}
                                                                    >
                                                                        : Energieausweis
                                                                    </text>
                                                                </g>
                                                            </svg>
                                                        </Box>
                                                        <Box maxHeight="5ch" width="30ch" pl="10ch" mr="16ch">
                                                            <svg>
                                                                <g transform={`translate(10, 20)`}>
                                                                    <path
                                                                        transform={`translate(0,-4)`}
                                                                        d={`
                                                                            M 0 -10
                                                                            A 5.5 5.5 0 1 1 -0.01 -10
                                                                            M -12 -4
                                                                            L -8 -4
                                                                            M 12 -4
                                                                            L 8 -4
                                                                            M 0 -17
                                                                            L 0 -13
                                                                            M 0 4
                                                                            L 0 8
                                                                            M -10.1 -12.1
                                                                            L -7 -9
                                                                            M 10.1 -12.1
                                                                            L 7 -9
                                                                            M -8.1 5.1
                                                                            L -5 2
                                                                            M 8.1 5.1
                                                                            L 5 2
                                                                        `}
                                                                        strokeWidth="2"
                                                                        strokeLinecap="round"
                                                                        stroke={"#fcca03"}
                                                                        fill={"#fcca03"}
                                                                    />
                                                                    <text
                                                                        transform={`translate(15, -3)`}
                                                                        fontFamily="Arial"
                                                                        fontSize="16"
                                                                        fill={"#000000"}
                                                                    >
                                                                        : Photovoltaik-Einsparung
                                                                    </text>
                                                                </g>
                                                            </svg>
                                                        </Box>

                                                        <Box maxHeight="5ch" width="25ch">
                                                            <svg>
                                                                <g transform={`translate(10, 20)`}>
                                                                    <path
                                                                        transform={`translate(0,-1)`}
                                                                        d="M-10 0 L 10 0 L 0 -10 L -10 0 Z"
                                                                        fill={"#2D2D2D"}
                                                                    />
                                                                    <text
                                                                        transform={`translate(14, -3)`}
                                                                        fontFamily="Arial"
                                                                        fontSize="16"
                                                                        fill={"#000000"}
                                                                    >
                                                                        : Emissionen (GEG)
                                                                    </text>
                                                                </g>
                                                            </svg>
                                                        </Box>
                                                        <Box maxHeight="5ch" width="20ch">
                                                            <svg>
                                                                <g transform={`translate(10, 20)`}>
                                                                    <path
                                                                        transform={`translate(0,-7)`}
                                                                        d="M0 -7 L 7 0 L 0 7 L -7 0 Z"
                                                                        fill={"#3D3D3D"}
                                                                    />
                                                                    <text
                                                                        transform={`translate(14, -3)`}
                                                                        fontFamily="Arial"
                                                                        fontSize="16"
                                                                        fill={"#000000"}
                                                                    >
                                                                        : Emissionen (CO2KostAufG)
                                                                    </text>
                                                                </g>
                                                            </svg>
                                                        </Box>
                                                    </Grid>
                                                </>
                                            )}
                                        </>
                                    ) : (
                                        <></>
                                    )}
                                </Box>
                            </Item>
                        </div>
                    </Stack>
                    <Accordion sx={{ width: "160ch", mt: 3 }}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <EqualizerIcon sx={{ fontSize: 30 }} />{" "}
                            <Typography sx={{ ml: 2, fontSize: 20, fontWeight: "bold" }}>Verbrauchsgraphen</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Divider sx={{ mb: 2, mt: -2, width: "100%" }} />
                            <div style={{ position: "relative" }}>
                                {fetchingBuildingResultsData ? (
                                    LoadingSpinner
                                ) : (
                                    <Stack width="100%" spacing={3} mb={3}>
                                        <Item>
                                            <ConsumptionResultBarGraph
                                                userResults={userResults}
                                                heightGraphs={1.1}
                                                bigAbsoluteUnits={false}
                                                singleBuilding={true}
                                                isPortfolioAnalysis={false}
                                            />
                                        </Item>
                                        <Item>
                                            <EmissionResultBarGraph
                                                userResults={userResults}
                                                heightGraphs={1.1}
                                                bigAbsoluteUnits={false}
                                                singleBuilding={true}
                                                isPortfolioAnalysis={false}
                                            />
                                        </Item>
                                    </Stack>
                                )}
                            </div>
                        </AccordionDetails>
                    </Accordion>
                </>
            ) : (
                <Stack width="160ch" spacing={3} mt={3} mb={3}>
                    <Item>
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                minHeight: "200px",
                                width: "100%",
                                justifyContent: "center",
                            }}
                        >
                            <Alert severity="warning" sx={{ textAlign: "left", height: "10ch", fontSize: 14 }}>
                                <strong>Es liegen keine Ergebnisdaten für dieses Gebäude vor.</strong>
                                <br />
                                Es wird mindestens ein Gebäudezustand und ein angegebenes Verbrauchsjahr benötigt,
                                <br /> um Ergebnisse zu berechnen.
                                <br />
                            </Alert>
                        </Box>
                    </Item>
                </Stack>
            )}
        </>
    );
};

import { createContext, useState } from "react";

const SidebarContext = createContext();

export const SidebarProvider = ({ children }) => {
    const [isCollapsed, setIsCollapsed] = useState(false);
    const [isCollapsedFinished, setIsCollapsedFinished] = useState(false);
    const [selected, setSelected] = useState("Dashboard");

    return (
        <SidebarContext.Provider
            value={{ isCollapsed, setIsCollapsed, isCollapsedFinished, setIsCollapsedFinished, selected, setSelected }}
        >
            {children}
        </SidebarContext.Provider>
    );
};

export default SidebarContext;

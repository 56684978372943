import React from "react";
import { Snackbar, Alert } from "@mui/material";

const Toast = ({ display, setDisplay, severity, message, duration, data_testid, props }) => {
    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setDisplay(false);
    };

    return (
        <Snackbar open={display} autoHideDuration={duration} onClose={handleClose}>
            <Alert
                {...props}
                onClose={handleClose}
                severity={severity}
                sx={{ width: "100%" }}
                data-testid={data_testid}
            >
                {message}
            </Alert>
        </Snackbar>
    );
};
export default Toast;

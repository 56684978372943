/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useCallback } from "react";
import { Typography, Box, Grid } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { useTheme } from "@mui/material/styles";
import { CustomTooltip } from "../../../components/CustomTooltip";
import { Bar } from "@nivo/bar";

const colorScheme = ["#22577a", "#38a3a5", "#57cc99", "#80ed99", "#c7f9cc"];

export const LoadDistribution = ({
    buildingHeatingSystemsState,
    buildingHeatingSystemsStateTab,
    heatingSystemAccordionExpanded,
}) => {
    const chartTheme = {
        // background: "#ffffff",
        textColor: "#333333",
        fontSize: 13,
        axis: {
            domain: {
                line: {
                    stroke: "#777777",
                    strokeWidth: 2,
                },
            },
            legend: {
                text: {
                    fontSize: 16,
                    fill: "#000000",
                },
            },
            ticks: {
                line: {
                    stroke: "#777777",
                    strokeWidth: 2,
                },
                text: {
                    fontSize: 13,
                    fill: "#333333",
                },
            },
        },
        grid: {
            line: {
                stroke: "#dddddd",
                strokeWidth: 1,
            },
        },
        legends: {
            title: {
                text: {
                    fontSize: 11,
                    fill: "#333333",
                },
            },
            text: {
                fontSize: 13,
                fill: "#333333",
            },
            ticks: {
                line: {},
                text: {
                    fontSize: 10,
                    fill: "#333333",
                },
            },
        },
        annotations: {
            text: {
                fontSize: 13,
                fill: "#333333",
                outlineWidth: 2,
                outlineColor: "#ffffff",
                outlineOpacity: 1,
            },
            link: {
                stroke: "#000000",
                strokeWidth: 1,
                outlineWidth: 2,
                outlineColor: "#ffffff",
                outlineOpacity: 1,
            },
            outline: {
                stroke: "#000000",
                strokeWidth: 2,
                outlineWidth: 2,
                outlineColor: "#ffffff",
                outlineOpacity: 1,
            },
            symbol: {
                fill: "#000000",
                outlineWidth: 2,
                outlineColor: "#ffffff",
                outlineOpacity: 1,
            },
        },
        tooltip: {
            container: {
                background: "#ffffff",
                color: "#333333",
                fontSize: 12,
            },
            basic: {},
            chip: {},
            table: {},
            tableCell: {},
            tableCellValue: {},
        },
    };

    const theme = useTheme();
    const [sharesOfConsumptionRoomheatingBarChartData, setSharesOfConsumptionRoomheatingBarChartData] = useState([]);

    const transformSharesOfConsumptionData = () => {
        const roomheatingData =
            buildingHeatingSystemsState[buildingHeatingSystemsStateTab]?.estimated_shares_of_consumption_roomheating;
        const waterheatingData =
            buildingHeatingSystemsState[buildingHeatingSystemsStateTab]?.estimated_shares_of_consumption_waterheating;
        if (!roomheatingData || !waterheatingData) return [];
        // transform roomheating data
        const roomheatingTransformedData = { consumptionType: "Raumwärme" };
        var index = 0;
        for (const [key, value] of Object.entries(roomheatingData)) {
            roomheatingTransformedData["A" + String(index + 1)] = Math.round(value.real);
            index++;
        }
        // transform waterheating data
        const waterheatingTransformedData = { consumptionType: "Warmwasser" };
        index = 0;
        for (const [key, value] of Object.entries(waterheatingData)) {
            waterheatingTransformedData["A" + String(index + 1)] = Math.round(value.real);
            index++;
        }
        return [roomheatingTransformedData, waterheatingTransformedData];
    };
    useEffect(() => {
        setSharesOfConsumptionRoomheatingBarChartData(transformSharesOfConsumptionData());
    }, [
        buildingHeatingSystemsState[buildingHeatingSystemsStateTab]?.estimated_shares_of_consumption_roomheating,
        buildingHeatingSystemsState[buildingHeatingSystemsStateTab]?.estimated_shares_of_consumption_waterheating,
    ]);

    return (
        <Box
            width="21%"
            sx={{
                mt: 1,
                border: "1px solid rgba(0, 0, 0, .125)",
            }}
        >
            <Box
                sx={{
                    backgroundColor: theme.palette.mode === "dark" ? "rgba(255, 255, 255, .05)" : "rgba(0, 0, 0, .03)",
                    height: 60,
                    borderBottom: "1px solid rgba(0, 0, 0, .125)",
                }}
            >
                <Typography sx={{ pl: 4, pt: 2, fontSize: 20, fontWeight: "bold" }}>
                    Lastverteilung
                    <CustomTooltip
                        sx={{}}
                        title={
                            <>
                                Die Lastverteilung für Raumwärme und Warmwasser gibt an, wie viel des Wärmebedarfs aus
                                den zentralen Wärmeversorgungsanlagen von der jeweiligen Anlage übernommen wird. <br />
                                WICHTIG: Der Lastanteil bezieht sich nur auf die zentrale Wärmeversorgung. Das bedeutet,
                                dass die Berechnung der dezentralen Warmwasserversorgung von diesem Wert nicht betroffen
                                ist.
                            </>
                        }
                    >
                        <InfoIcon sx={{ mb: -0.4, ml: 1 }} color="neutral" />
                    </CustomTooltip>
                </Typography>
            </Box>
            <Grid container>
                <Box width="80%">
                    <Bar
                        reverse
                        theme={chartTheme}
                        height={
                            heatingSystemAccordionExpanded !== false
                                ? 375 +
                                  buildingHeatingSystemsState[buildingHeatingSystemsStateTab].heating_systems.length *
                                      57.5
                                : 300
                        }
                        width={250}
                        data={sharesOfConsumptionRoomheatingBarChartData}
                        keys={Array.from(
                            {
                                length: buildingHeatingSystemsState[buildingHeatingSystemsStateTab].heating_systems
                                    .length,
                            },
                            (_, i) => "A" + String(i + 1)
                        )}
                        indexBy="consumptionType"
                        margin={{ top: 20, right: 70, bottom: 80, left: 70 }}
                        padding={0.4}
                        valueScale={{ type: "linear" }}
                        indexScale={{ type: "band", round: true }}
                        colors={{ scheme: "accent" }}
                        borderColor={{
                            from: "color",
                            modifiers: [["darker", 1.6]],
                        }}
                        axisTop={null}
                        axisRight={null}
                        axisBottom={{
                            tickSize: 5,
                            tickPadding: 5,
                            tickRotation: -45,
                            truncateTickAt: 0,
                        }}
                        axisLeft={{
                            tickSize: 5,
                            tickPadding: 5,
                            tickRotation: 45,
                            legend: "Lastanteil [%]",
                            legendPosition: "middle",
                            legendOffset: -40,
                            truncateTickAt: 0,
                        }}
                        labelSkipWidth={12}
                        labelSkipHeight={12}
                        labelTextColor={{
                            from: "color",
                            modifiers: [["darker", 5.6]],
                        }}
                        legends={[
                            {
                                dataFrom: "keys",
                                anchor: "top-left",
                                direction: "column",
                                justify: false,
                                translateX: 120,
                                translateY: 0,
                                itemsSpacing: 2,
                                itemWidth: 100,
                                itemHeight: 20,
                                itemDirection: "left-to-right",
                                itemOpacity: 0.85,
                                symbolSize: 20,
                                effects: [
                                    {
                                        on: "hover",
                                        style: {
                                            itemOpacity: 1,
                                        },
                                    },
                                ],
                            },
                        ]}
                    />
                </Box>
            </Grid>
        </Box>
    );
};

import { MapContainer } from "react-leaflet/MapContainer";
import { TileLayer } from "react-leaflet/TileLayer";
import "leaflet/dist/leaflet.css";
import { Fab, Paper } from "@mui/material";
import { useState, useEffect, useCallback } from "react";
import "./svg-icon.js";
import { Marker } from "react-leaflet/Marker";
import CheckIcon from "@mui/icons-material/Check";
import L from "leaflet";

export const SelectLocationMap = ({ height, width, maxWidth, maxHeight, setCoordinates, center = null }) => {
    const [map, setMap] = useState(null);
    const [markerPosition, setMarkerPosition] = useState(center);

    const onClick = useCallback((e) => {
        setLocation(e);
    }, []);

    useEffect(() => {
        if (map) {
            map.on("click", onClick);
            return () => {
                map.off("click", onClick);
            };
        }
        return;
    }, [map, onClick]);

    const setLocation = (e) => {
        setMarkerPosition(e.latlng);
    };

    const saveLocation = () => {
        setCoordinates(markerPosition);
    };

    return (
        <>
            <link
                rel="stylesheet"
                href="https://unpkg.com/leaflet@1.9.4/dist/leaflet.css"
                integrity="sha256-p4NxAoJBhIIN+hmNHrzRCf9tD/miZyoHS5obTRR9BMY="
                crossOrigin=""
            />
            <script
                src="https://unpkg.com/leaflet@1.9.4/dist/leaflet.js"
                integrity="sha256-20nQCchB9co0qIjJZRGuk2/Z9VM+kNiyxNV1lvTlZBo="
                crossOrigin=""
            ></script>
            <Paper sx={{ width: "100%", mt: 3, padding: 2 }}>
                <div id="map">
                    <MapContainer
                        center={center ? center : [49.476, 8.664]}
                        zoom={13}
                        scrollWheelZoom={true}
                        style={{ height: height, width: width, maxWidth: maxWidth, maxHeight }}
                        ref={setMap}
                    >
                        <TileLayer
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        />
                        {markerPosition && (
                            <Marker
                                position={markerPosition}
                                icon={L.divIcon.svgIcon({
                                    iconSize: [30, 45],
                                    circleRatio: 0.25,
                                    fillOpacity: 1,
                                })}
                                opacity={0.7}
                            ></Marker>
                        )}
                    </MapContainer>
                </div>
            </Paper>
            <Fab
                color="secondary"
                aria-label="save"
                sx={{ position: "fixed", bottom: "0%", right: "50%" }}
                onClick={saveLocation}
            >
                <CheckIcon sx={{ fontSize: 50 }} />
            </Fab>
        </>
    );
};

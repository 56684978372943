import React from "react";

const BrickWallIcon = ({ width = 24, height = 24, fill = "currentColor" }) => (
    <svg width={width} height={height} viewBox="0 0 24 24" fill={fill} xmlns="http://www.w3.org/2000/svg">
        <rect x="2" y="2" width="20" height="4" />
        <rect x="2" y="8" width="8" height="4" />
        <rect x="14" y="8" width="8" height="4" />
        <rect x="2" y="14" width="20" height="4" />
        <rect x="2" y="20" width="8" height="4" />
        <rect x="14" y="20" width="8" height="4" />
    </svg>
);

export { BrickWallIcon };

const WindowIcon = ({ width = 24, height = 24, fill = "currentColor" }) => (
    <svg width={width} height={height} viewBox="0 0 24 24" fill={fill} xmlns="http://www.w3.org/2000/svg">
        <rect x="3" y="3" width="18" height="18" stroke={fill} strokeWidth="2" fill="none" />
        <line x1="3" y1="12" x2="21" y2="12" stroke={fill} strokeWidth="2" />
        <line x1="12" y1="3" x2="12" y2="21" stroke={fill} strokeWidth="2" />
    </svg>
);

export { WindowIcon };

const RoofIcon = ({ width = 24, height = 24, fill = "currentColor" }) => (
    <svg width={width} height={height} viewBox="0 0 24 24" fill={fill} xmlns="http://www.w3.org/2000/svg">
        <polygon points="12,2 2,12 22,12" stroke={fill} strokeWidth="2" fill="none" />
    </svg>
);

export { RoofIcon };

const CellarIcon = ({ width = 24, height = 24, fill = "currentColor" }) => (
    <svg width={width} height={height} viewBox="0 0 24 24" fill={fill} xmlns="http://www.w3.org/2000/svg">
        <rect x="2" y="16" width="20" height="6" stroke={fill} strokeWidth="2" fill="none" />
        <line x1="2" y1="16" x2="22" y2="16" stroke={fill} strokeWidth="2" />
        <line x1="8" y1="16" x2="8" y2="22" stroke={fill} strokeWidth="2" />
        <line x1="16" y1="16" x2="16" y2="22" stroke={fill} strokeWidth="2" />
    </svg>
);

export { CellarIcon };

const HouseIcon = ({ width = 48, height = 48, fill = "currentColor" }) => (
    <svg width={width} height={height} viewBox="0 0 48 48" fill={fill} xmlns="http://www.w3.org/2000/svg">
        <polygon points="24,4 4,24 44,24" stroke={fill} strokeWidth="2" fill="none" />
        <rect x="8" y="24" width="32" height="16" stroke={fill} strokeWidth="2" fill="none" />
        <rect x="12" y="28" width="8" height="8" stroke={fill} strokeWidth="2" fill="none" />
        <rect x="28" y="28" width="8" height="8" stroke={fill} strokeWidth="2" fill="none" />
        <rect x="4" y="40" width="40" height="8" stroke={fill} strokeWidth="2" fill="none" />
        <line x1="4" y1="40" x2="44" y2="40" stroke={fill} strokeWidth="2" />
        <line x1="16" y1="40" x2="16" y2="48" stroke={fill} strokeWidth="2" />
        <line x1="32" y1="40" x2="32" y2="48" stroke={fill} strokeWidth="2" />
    </svg>
);

export { HouseIcon };

const HighlightedWallHouseIcon = ({
    width = 48,
    height = 48,
    fill = "#040509",
    wallFill = "#00dffc",
    windowFill = "white",
}) => (
    <svg width={width} height={height} viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
        <polygon points="24,4 4,24 44,24" stroke={fill} strokeWidth="2" fill="none" />
        <rect x="8" y="24" width="32" height="16" stroke={fill} strokeWidth="2" fill={wallFill} />
        <rect x="12" y="28" width="8" height="8" stroke={fill} strokeWidth="2" fill={windowFill} />
        <rect x="28" y="28" width="8" height="8" stroke={fill} strokeWidth="2" fill={windowFill} />
        <rect x="4" y="40" width="40" height="8" stroke={fill} strokeWidth="2" fill="none" />
        <line x1="4" y1="40" x2="44" y2="40" stroke={fill} strokeWidth="2" />
        <line x1="16" y1="40" x2="16" y2="48" stroke={fill} strokeWidth="2" />
        <line x1="32" y1="40" x2="32" y2="48" stroke={fill} strokeWidth="2" />
    </svg>
);

export { HighlightedWallHouseIcon };
const HighlightedWindowHouseIcon = ({ width = 48, height = 48, fill = "#040509", windowFill = "#00dffc" }) => (
    <svg width={width} height={height} viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
        <polygon points="24,4 4,24 44,24" stroke={fill} strokeWidth="2" fill="none" />
        <rect x="8" y="24" width="32" height="16" stroke={fill} strokeWidth="2" fill="none" />
        <rect x="12" y="28" width="8" height="8" stroke={fill} strokeWidth="2" fill={windowFill} />
        <rect x="28" y="28" width="8" height="8" stroke={fill} strokeWidth="2" fill={windowFill} />
        <rect x="4" y="40" width="40" height="8" stroke={fill} strokeWidth="2" fill="none" />
        <line x1="4" y1="40" x2="44" y2="40" stroke={fill} strokeWidth="2" />
        <line x1="16" y1="40" x2="16" y2="48" stroke={fill} strokeWidth="2" />
        <line x1="32" y1="40" x2="32" y2="48" stroke={fill} strokeWidth="2" />
    </svg>
);

export { HighlightedWindowHouseIcon };

const HighlightedRoofHouseIcon = ({ width = 48, height = 48, fill = "#040509", roofFill = "#00dffc" }) => (
    <svg width={width} height={height} viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
        <polygon points="24,4 4,24 44,24" stroke={fill} strokeWidth="2" fill={roofFill} />
        <rect x="8" y="24" width="32" height="16" stroke={fill} strokeWidth="2" fill="none" />
        <rect x="12" y="28" width="8" height="8" stroke={fill} strokeWidth="2" fill="none" />
        <rect x="28" y="28" width="8" height="8" stroke={fill} strokeWidth="2" fill="none" />
        <rect x="4" y="40" width="40" height="8" stroke={fill} strokeWidth="2" fill="none" />
        <line x1="4" y1="40" x2="44" y2="40" stroke={fill} strokeWidth="2" />
        <line x1="16" y1="40" x2="16" y2="48" stroke={fill} strokeWidth="2" />
        <line x1="32" y1="40" x2="32" y2="48" stroke={fill} strokeWidth="2" />
    </svg>
);

export { HighlightedRoofHouseIcon };

const HighlightedBasementHouseIcon = ({ width = 48, height = 48, fill = "#040509", basementFill = "#00dffc" }) => (
    <svg width={width} height={height} viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
        <polygon points="24,4 4,24 44,24" stroke={fill} strokeWidth="2" fill="none" />
        <rect x="8" y="24" width="32" height="16" stroke={fill} strokeWidth="2" fill="none" />
        <rect x="12" y="28" width="8" height="8" stroke={fill} strokeWidth="2" fill="none" />
        <rect x="28" y="28" width="8" height="8" stroke={fill} strokeWidth="2" fill="none" />
        <rect x="4" y="40" width="40" height="8" stroke={fill} strokeWidth="2" fill={basementFill} />
        <line x1="4" y1="40" x2="44" y2="40" stroke={fill} strokeWidth="2" />
        <line x1="16" y1="40" x2="16" y2="48" stroke={fill} strokeWidth="2" />
        <line x1="32" y1="40" x2="32" y2="48" stroke={fill} strokeWidth="2" />
    </svg>
);

export { HighlightedBasementHouseIcon };

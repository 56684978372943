import { Button, TextField } from "@mui/material";
import { Link, Routes, Route } from "react-router-dom";

import { useState } from "react";
import api_client from "../../services/http-common";
import { useSearchParams } from "react-router-dom";
import NotFound from "../Error/NotFound";

/**
 * A React functional component that renders the password reset page for the application.
 *
 * This component provides two forms: one for sending a password reset email and another for setting a new password.
 * It manages local state for form inputs, error messages, and tracks the status of the password reset process.
 *
 * Returns:
 *   ReactElement: A component that displays either the send email form or the set new password form based on URL parameters.
 */
const PasswordReset = () => {
    const [email, setEmail] = useState("");
    const [emailSent, setEmailSent] = useState(false);
    const [queryParameters] = useSearchParams();
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [errorText, setErrorText] = useState("");
    const [isResetDone, setIsResetDone] = useState(false);
    const [resetDoneMessage, setResetDoneMessage] = useState("");

    const handleResetSubmit = (e) => {
        e.preventDefault();
        // only send call to backend if input makes sense
        if (email !== "" && email.includes("@") && email.includes(".")) {
            api_client.post(`/user/password/reset/`, { email: email });
        }
        setEmailSent(true);
        return;
    };

    const SendMailForm = () => {
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100vh",
                }}
            >
                <div
                    style={{
                        width: "400px",
                        padding: "30px",
                        backgroundColor: "white",
                        borderRadius: "10px",
                        boxShadow: "0px 0px 10px lightgrey",
                    }}
                >
                    <h1 style={{ textAlign: "center", marginBottom: "30px" }}>Passwort zurücksetzen</h1>
                    <form>
                        {emailSent ? (
                            <>
                                <p>
                                    Es wurde eine E-Mail mit Anweisungen zur Zurücksetzung des Passworts verschickt.
                                    Falls Sie in den nächsten Minuten keine E-Mail erhalten, prüfen Sie bitte ebenfalls
                                    ihren Spam-Ordner.
                                </p>
                                <Link
                                    to="/"
                                    style={{
                                        textDecoration: "none",
                                        marginTop: "15px",
                                        display: "block",
                                        textAlign: "center",
                                    }}
                                >
                                    Zurück zum Login
                                </Link>
                            </>
                        ) : (
                            <>
                                <TextField
                                    variant="outlined"
                                    label="Email"
                                    value={email}
                                    onChange={(event) => setEmail(event.target.value)}
                                    fullWidth
                                    style={{ marginBottom: "20px" }}
                                />
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    type="submit"
                                    fullWidth
                                    onClick={handleResetSubmit}
                                    style={{ height: "56px", marginTop: "20px" }}
                                >
                                    Passwort zurücksetzen
                                </Button>
                            </>
                        )}
                    </form>
                </div>
            </div>
        );
    };

    const validatePassword = (password) => {
        const regex = /(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d!#$%^&*\-_+]{8,}/;
        return regex.test(password);
    };

    const successMessage = "Das Passwort wurde erfolgreich zurückgesetzt.";
    const errorMessage =
        "Etwas ist schiefgelaufen und das Passwort konnte nicht zurückgesetzt werden. Bitte versuchen Sie es erneut.\n Sollte das Problem bestehen bleiben, bitte kontaktieren Sie uns unter info@advisore.eu";

    const handleNewPasswordSubmit = () => {
        if (newPassword !== confirmPassword) {
            setErrorText("Passwörter stimmen nicht überein");
            return;
        } else if (!validatePassword(newPassword)) {
            setErrorText(
                "Das Passwort sollte mindestens einen Kleinbuchstaben, einen Großbuchstaben und eine Zahl enthalten"
            );
            return;
        } else {
            setErrorText("");
            api_client
                .post(`/user/password/reset/confirm/`, {
                    new_password1: newPassword,
                    new_password2: confirmPassword,
                    token: queryParameters.get("token"),
                    uid: queryParameters.get("uid"),
                })
                .then((response) => {
                    if (response.status !== 200) {
                        setIsResetDone(true);
                        setResetDoneMessage(errorMessage);
                        return;
                    } else {
                        setIsResetDone(true);
                        setResetDoneMessage(successMessage);
                        return;
                    }
                })
                .catch((error) => {
                    setIsResetDone(true);
                    setResetDoneMessage(errorMessage);
                    return;
                });
        }
        setIsResetDone(true);
        setResetDoneMessage(successMessage);
    };

    const ResetPasswordForm = () => {
        return (
            <>
                {queryParameters.get("uid") && queryParameters.get("token") ? (
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "100vh",
                        }}
                    >
                        <div
                            style={{
                                width: "400px",
                                padding: "30px",
                                backgroundColor: "white",
                                borderRadius: "10px",
                                boxShadow: "0px 0px 10px lightgrey",
                            }}
                        >
                            <h1 style={{ textAlign: "center", marginBottom: "30px" }}>Neues Passwort setzen</h1>
                            {isResetDone ? (
                                <div>
                                    <p>{resetDoneMessage}</p>
                                    <Link
                                        to="/"
                                        style={{
                                            textDecoration: "none",
                                            marginTop: "15px",
                                            display: "block",
                                            textAlign: "center",
                                        }}
                                    >
                                        Zurück zum Login
                                    </Link>
                                </div>
                            ) : (
                                <form>
                                    <TextField
                                        variant="outlined"
                                        label="Neues Passwort"
                                        value={newPassword}
                                        onChange={(e) => setNewPassword(e.target.value)}
                                        error={!!errorText}
                                        fullWidth
                                        style={{ marginBottom: "20px" }}
                                        type="password"
                                    />
                                    <TextField
                                        variant="outlined"
                                        label="Neues Passwort bestätigen"
                                        value={confirmPassword}
                                        onChange={(e) => setConfirmPassword(e.target.value)}
                                        error={!!errorText}
                                        fullWidth
                                        style={{ marginBottom: "20px" }}
                                        type="password"
                                    />
                                    {errorText && <div style={{ color: "red", marginBottom: "20px" }}>{errorText}</div>}
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        type="submit"
                                        fullWidth
                                        onClick={handleNewPasswordSubmit}
                                        style={{ height: "56px", marginTop: "20px" }}
                                    >
                                        Bestätigen
                                    </Button>
                                </form>
                            )}
                        </div>
                    </div>
                ) : (
                    <NotFound />
                )}
            </>
        );
    };

    return (
        <>
            <Routes>
                <Route path="sendmail" element={SendMailForm()} />
                <Route path="?" element={ResetPasswordForm()} />
                <Route path="*" element={NotFound()} />
            </Routes>
        </>
    );
};

export default PasswordReset;

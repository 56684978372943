import React from "react";
import { Button, Box, Typography } from "@mui/material";

/**
 * A React functional component that renders a 'Not Found' page.
 *
 * This component displays a 404 error message indicating that the requested page could not be found.
 * It includes a button to redirect users back to the homepage. The component uses Material-UI components
 * for styling and layout.
 *
 * Returns:
 *   ReactElement: A 'Not Found' page with a 404 message and a button to navigate back to the homepage.
 */
const NotFound = () => {
    return (
        <Box
            sx={{
                position: "relative",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                paddingTop: "5%",
            }}
        >
            <Box
                sx={{
                    fontSize: "200px",
                    position: "absolute",
                    top: 0,
                    left: "50%",
                    transform: "translate(-50%, 5%)",
                    opacity: 0.1,
                    zIndex: "-1",
                }}
            >
                404
            </Box>
            <Typography variant="h4" sx={{ zIndex: "1" }}>
                Diese Seite konnte nicht gefunden werden.
            </Typography>
            <Button variant="contained" color="primary" href="/" sx={{ marginTop: "1em", zIndex: "1" }}>
                Zur Startseite
            </Button>
        </Box>
    );
};
export default NotFound;

import React, { useState, useEffect } from "react";
import api_client from "../../services/http-common";
import { Typography, Accordion, AccordionSummary, AccordionDetails, Box, Grid, Divider } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import HelpIcon from "@mui/icons-material/Help";
import HelpCenterIcon from "@mui/icons-material/HelpCenter";
import InfoIcon from "@mui/icons-material/Info";

/**
 * A React functional component that renders a Frequently Asked Questions (FAQ) section.
 *
 * This component fetches FAQ data from an API and displays it in an accordion format. Each accordion item
 * represents a group of questions and answers. The component uses Material-UI components for styling and layout.
 *
 * Returns:
 *   ReactElement: A component that displays a list of FAQs, each in an expandable accordion section.
 */
const FAQ = () => {
    const [FAQData, setFAQData] = useState([]);

    const getFAQData = () => {
        return api_client.get(`/faq/`, {});
    };
    useEffect(() => {
        getFAQData().then((resp) => {
            setFAQData(resp.data.faq_groups);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            {FAQData.length > 0 ? (
                FAQData.map((item) => {
                    return (
                        <>
                            <Box sx={{ mt: 3 }}>
                                <Accordion sx={{ width: "160ch" }} key={item.name + "-accordion"}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        key={item.name + "-accordion-summary"}
                                    >
                                        <Typography variant="h4" key={item.name + "-accordion-label"}>
                                            <HelpCenterIcon sx={{ mr: 2, mb: -1.1, fontSize: 35 }} />
                                            {item.name}
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails key={item.name + "-accordion-details"}>
                                        <Divider sx={{ mb: 2, mt: -2, width: "100%" }} />
                                        {item.members.map((member, idx) => {
                                            return (
                                                <>
                                                    <Typography
                                                        variant="h5"
                                                        key={item.name + "-question-" + idx}
                                                        sx={{ fontWeight: "bold" }}
                                                    >
                                                        <HelpIcon
                                                            sx={{ mr: 1, mb: -0.7, fontSize: 25 }}
                                                            color="secondary"
                                                        />
                                                        {member.question}
                                                    </Typography>
                                                    <Typography
                                                        sx={{ mt: 1, ml: 1 }}
                                                        variant="h6"
                                                        key={item.name + "-answer-" + idx}
                                                    >
                                                        <Box>
                                                            <Grid
                                                                container
                                                                direction="row"
                                                                justify="flex-end"
                                                                alignItems="center"
                                                            >
                                                                <Box width="3%" height="50%">
                                                                    <InfoIcon
                                                                        sx={{
                                                                            fontSize: 30,
                                                                        }}
                                                                        color="secondary"
                                                                    />
                                                                </Box>
                                                                <Box width="90%">{member.answer}</Box>
                                                            </Grid>
                                                        </Box>
                                                    </Typography>
                                                    <Divider
                                                        sx={{
                                                            ml: "20%",
                                                            mb: 3,
                                                            mt: 3,
                                                            width: "60%",
                                                        }}
                                                    />
                                                </>
                                            );
                                        })}
                                    </AccordionDetails>
                                </Accordion>
                            </Box>
                        </>
                    );
                })
            ) : (
                <></>
            )}
        </>
    );
};

export default FAQ;

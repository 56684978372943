/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useCallback, useContext } from "react";
import { useImmer } from "use-immer";
import { Typography, Box, Tabs, Tab, Fab, Modal, Divider, Grid, Alert, Button } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { styled } from "@mui/material/styles";
import api_client from "../../services/http-common";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AddIcon from "@mui/icons-material/Add";
import { useTheme } from "@mui/material/styles";
import { addDisplayNumbersInJson, addDisplayNumbersInJsonArray, cleanNumbersInJson } from "../../utils/formatting";
import DeleteIcon from "@mui/icons-material/Delete";
import HeatPumpOutlinedIcon from "@mui/icons-material/HeatPumpOutlined";
import ToastContext from "../../contexts/ToastContext";
import DeleteConfirmationModal from "../../components/DeleteConfirmationModal";
import ReportProblemTwoToneIcon from "@mui/icons-material/ReportProblemTwoTone";
import { NewHeatingSystemDetail } from "./BuildingHeatingSystems/NewHeatingSystemDetail";
import CloseIcon from "@mui/icons-material/Close";
import { CustomTooltip } from "../../components/CustomTooltip";
import { LoadingSpinner } from "../../components/LoadingSpinner";
import { HeatingSystemDetail } from "./BuildingHeatingSystems/HeatingSystemDetail";
import { LoadDistribution } from "./BuildingHeatingSystems/LoadDistributionBarChart";
import UpdateTimeStampContext from "./UpdateTimestampContext";
import { BasicTextField } from "../../components/InputFields";

const NumberInput = styled(BasicTextField)`
    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
        display: none;
    }
`;

/**
 * Component for displaying and managing building HeatingSystems in an accordion format.
 *
 * This component allows users to view, add, edit, and delete building HeatingSystems for a specific building.
 * It integrates with various contexts to fetch and display data related to energy efficiency, modernization,
 * and building statistics. It also provides interactive elements like sliders, inputs, and modals for
 * a dynamic user experience.
 *
 * Props:
 *   currentBuilding (Object): The building object for which the HeatingSystems are being managed.
 *
 * Returns:
 *   ReactElement: A component that renders an accordion with building heating-system details and management options.
 */
export const BuildingHeatingSystemsAccordion = ({
    currentBuilding,
    // consumptionTypes,
    heatingSystemTypes,
    emissionModels,
    energySources,
}) => {
    // -- CONTEXT -- //
    const { addToastMessage } = useContext(ToastContext);
    const { setUpdateTimestamp } = useContext(UpdateTimeStampContext);
    // -- CONSTANTS -- //
    const theme = useTheme();

    const emptyBuildingHeatingSystemsState = {
        heating_systems: [],
        id: null,
        individual_degrees_of_efficiency: {},
        estimated_shares_of_consumption_roomheating: {},
        estimated_shares_of_consumption_waterheating: {},
        year: null,
    };

    // -- STATES -- //
    // building-heating-systems-state data
    const [buildingHeatingSystemsStateTab, setBuildingHeatingSystemsStatesTab] = useState(0);
    const [buildingHeatingSystemsState, setBuildingHeatingSystemsState] = useImmer([emptyBuildingHeatingSystemsState]);

    const [estimatedSharesOfConsumptionRoomheating, setEstimatedSharesOfConsumptionRoomheating] = useState({});
    const [estimatedSharesOfConsumptionWaterheating, setEstimatedSharesOfConsumptionWaterheating] = useState({});

    const [fetchingBuildingHeatingSystemsStateData, setFetchingBuildingHeatingSystemsStateData] = useState(false);

    // handle functions for addYearModal in heating-systems-state
    const [addHeatingSystemsStateYearModalOpen, setAddHeatingSystemsStateYearModalOpen] = useState(false);
    const [addHeatingSystemsStateYearChoice, setAddHeatingSystemsStateYearChoice] = useState();
    const [addHeatingSystemsStateYearValid, setAddHeatingSystemsStateYearValid] = useState(false);
    const handleOpenAddHeatingSystemYear = () => setAddHeatingSystemsStateYearModalOpen(true);
    const handleCloseAddHeatingSystemYear = () => setAddHeatingSystemsStateYearModalOpen(false);

    const [editModeBuildingHeatingSystem, setEditModeBuildingHeatingSystem] = useState(false);
    const [errorBuildingHeatingSystemsState, setErrorBuildingHeatingSystemsState] = useImmer({});

    const [deleteConfirmationModalOpen, setDeleteConfirmationModalOpen] = useState(false);
    const handleOpenDeleteConfirmationModal = () => setDeleteConfirmationModalOpen(true);
    const handleCloseDeleteConfirmationModal = () => setDeleteConfirmationModalOpen(false);

    // -- FUNCTIONS -- //
    // handle the switching of heating-systems-state year (tab)
    const handleBuildingHeatingSystemTab = (event, newValue) => {
        setBuildingHeatingSystemsStatesTab(newValue);
    };

    // fetch building heating_systems from backend
    const fetchBuildingHeatingSystemData = useCallback(async () => {
        setEditModeBuildingHeatingSystem(false);
        setFetchingBuildingHeatingSystemsStateData(true);
        api_client.get(`/buildings/${currentBuilding.id}/heating-systems-states/`, {}).then((response) => {
            const data = addDisplayNumbersInJsonArray(response.data.data, [
                "id",
                "heating_system_type",
                "emission_model",
                "year",
                "also_used_by",
            ]);
            // assign local states
            setBuildingHeatingSystemsState((cond) => {
                cond.splice(0, cond.length);
                data.map((val) => {
                    cond.push(val);
                    return cond;
                });
            });
            var tab = Math.max(0, Math.min(data.length - 1, buildingHeatingSystemsStateTab));
            setBuildingHeatingSystemsStatesTab(tab);
            setHeatingSystemAccordionExpanded(data[tab]?.heating_systems.length === 0 ? -1 : 0);
            setFetchingBuildingHeatingSystemsStateData(false);
        });
    }, [currentBuilding, buildingHeatingSystemsStateTab]);

    useEffect(() => {
        fetchBuildingHeatingSystemData();
    }, [currentBuilding]);

    // delete building heating-systems-state
    const deleteBuildingHeatingSystemsState = async () => {
        handleCloseDeleteConfirmationModal();
        setFetchingBuildingHeatingSystemsStateData(true);
        api_client
            .delete(
                `/buildings/${currentBuilding.id}/heating-systems-states/${buildingHeatingSystemsState[buildingHeatingSystemsStateTab].id}/`,
                {}
            )
            .then((response) => {
                setBuildingHeatingSystemsStatesTab(
                    Math.max(0, Math.min(buildingHeatingSystemsState.length - 2, buildingHeatingSystemsStateTab))
                );
                if (response.status === 200) {
                    fetchBuildingHeatingSystemData();
                    addToastMessage(
                        "Wärmeversorgungsanlagen-Zustand erfolgreich gelöscht.",
                        "success",
                        true,
                        6000,
                        "toast-delete-heating-systems-state-success"
                    );
                    setUpdateTimestamp(Date.now());
                } else {
                    addToastMessage(
                        "Beim Löschen des Wärmeversorgungsanlagen-Zustandes ist ein Fehler aufgetreten, bitte versuchen Sie es erneut.",
                        "error",
                        true,
                        6000,
                        "toast-delete-heating-systems-state-error"
                    );
                }
                setFetchingBuildingHeatingSystemsStateData(false);
            });
    };

    // add years (tabs) to building heating_systems
    const addBuildingHeatingSystemsStateTab = () => {
        handleCloseAddHeatingSystemYear();
        const newTabIdx = buildingHeatingSystemsState.length;
        var lastEl;
        if (buildingHeatingSystemsState.length > 0) {
            lastEl = { ...buildingHeatingSystemsState[buildingHeatingSystemsState.length - 1] };
        } else {
            lastEl = { ...emptyBuildingHeatingSystemsState };
        }
        lastEl.year = parseInt(addHeatingSystemsStateYearChoice);
        lastEl.id = null;
        setBuildingHeatingSystemsState((cond) => {
            cond.push(lastEl);
        });
        setAddHeatingSystemsStateYearChoice("");
        setAddHeatingSystemsStateYearValid(false);
        setHeatingSystemAccordionExpanded(lastEl.heating_systems.length > 0 ? lastEl.heating_systems.length - 1 : -1);
        setBuildingHeatingSystemsStatesTab(newTabIdx);
        handleSaveBuildingHeatingSystemsState({ _buildingHeatingSystemsState: lastEl, tab: newTabIdx });
    };

    // handle the toggling of the edit modes
    const handleEditMode = () => {
        if (editModeBuildingHeatingSystem === true) {
            fetchBuildingHeatingSystemData();
            setEditModeBuildingHeatingSystem(false);
        } else {
            setEditModeBuildingHeatingSystem(true);
        }
        setErrorBuildingHeatingSystemsState({});
    };

    const getClosestHeatingSystem = (heatingSystemIdx) => {
        if (buildingHeatingSystemsState[buildingHeatingSystemsStateTab].heating_systems.length === 1) {
            return 0;
        } else if (
            heatingSystemIdx ===
            buildingHeatingSystemsState[buildingHeatingSystemsStateTab].heating_systems.length - 1
        ) {
            return heatingSystemIdx - 1;
        } else if (heatingSystemIdx === -1 || heatingSystemIdx === null) {
            // if we added a new heating system
            return buildingHeatingSystemsState[buildingHeatingSystemsStateTab].heating_systems.length - 1;
        } else {
            return heatingSystemIdx + 1;
        }
    };

    const getHeatingSystemByIdx = (heatingSystemIdx) => {
        return buildingHeatingSystemsState[buildingHeatingSystemsStateTab].heating_systems[heatingSystemIdx];
    };

    const handleSaveBuildingHeatingSystemsState = async ({
        _buildingHeatingSystemsState,
        tab = buildingHeatingSystemsStateTab,
    }) => {
        setEditModeBuildingHeatingSystem(false);
        setFetchingBuildingHeatingSystemsStateData(true);
        var cleanBuildingHeatingSystemsState = cleanNumbersInJson(_buildingHeatingSystemsState);
        const heatingSystemStateUrl = cleanBuildingHeatingSystemsState.id
            ? `/buildings/${currentBuilding.id}/heating-systems-states/${cleanBuildingHeatingSystemsState.id}/`
            : `/buildings/${currentBuilding.id}/heating-systems-states/new/`;
        api_client
            .post(heatingSystemStateUrl, cleanBuildingHeatingSystemsState)
            .then((response) => {
                const building_heating_system_status = response.status;
                if (building_heating_system_status === 200 && response.data.status === "SUCCESS") {
                    const data = addDisplayNumbersInJson(response.data.data, [
                        "id",
                        "heating_system_type",
                        "emission_model",
                        "year",
                        "also_used_by",
                    ]);
                    setBuildingHeatingSystemsState((cond) => {
                        cond.splice(tab, 1, data);
                    });
                    addToastMessage("Wärmeversorgungsanlagen-Zustand erfolgreich gespeichert.", "success", true, 6000);
                    setUpdateTimestamp(Date.now());
                } else {
                    addToastMessage(
                        "Beim Speichern des Wärmeversorgungsanlagen-Zustandes ist ein Fehler aufgetreten, bitte versuchen Sie es erneut.",
                        "error",
                        true,
                        6000
                    );
                }
                setFetchingBuildingHeatingSystemsStateData(false);
            })
            .catch((error) => {
                addToastMessage(
                    "Beim Speichern des Wärmeversorgungsanlagen-Zustandes ist ein Fehler aufgetreten, bitte versuchen Sie es erneut.",
                    "error",
                    true,
                    6000
                );
                setFetchingBuildingHeatingSystemsStateData(false);
            });
    };

    const handleDeleteHeatingSystem = async ({ heatingSystem, heatingSystemIdx }) => {
        setEditModeBuildingHeatingSystem(false);
        setFetchingBuildingHeatingSystemsStateData(true);

        var _buildingHeatingSystemsState = buildingHeatingSystemsState[buildingHeatingSystemsStateTab];
        var currentHeatingSystem = getHeatingSystemByIdx(heatingSystemIdx);
        var previousEstimatedShareOfConsumptionRoomheating =
            _buildingHeatingSystemsState.estimated_shares_of_consumption_roomheating[currentHeatingSystem.id].real;
        var previousEstimatedShareOfConsumptionWaterheating =
            _buildingHeatingSystemsState.estimated_shares_of_consumption_waterheating[currentHeatingSystem.id].real;
        var changedEstimatedSharesOfConsumptionRoomheating = { ...estimatedSharesOfConsumptionRoomheating }; // init this as a copy of the original state
        var changedEstimatedSharesOfConsumptionWaterheating = { ...estimatedSharesOfConsumptionWaterheating }; // init this as a copy of the original state
        var changedIndividualDegreesOfEfficiency = { ..._buildingHeatingSystemsState.individual_degrees_of_efficiency }; // init this as a copy of the original state
        if (_buildingHeatingSystemsState.heating_systems.length > 1) {
            const deltaShareOfConsumptionRoomheating = -previousEstimatedShareOfConsumptionRoomheating; // the amount by which the share of consumption has changed
            if (deltaShareOfConsumptionRoomheating !== 0) {
                const closestHeatingSystemIdx = getClosestHeatingSystem(heatingSystemIdx);
                if (closestHeatingSystemIdx !== null) {
                    const closestHeatingSystemId =
                        _buildingHeatingSystemsState.heating_systems[closestHeatingSystemIdx].id;
                    changedEstimatedSharesOfConsumptionRoomheating = {
                        ...changedEstimatedSharesOfConsumptionRoomheating,
                        [closestHeatingSystemId]: {
                            real:
                                changedEstimatedSharesOfConsumptionRoomheating[closestHeatingSystemId].real -
                                deltaShareOfConsumptionRoomheating, // subtract the difference in share of consumption from the closest heating system
                            display: "",
                        },
                    };
                }
                const deltaShareOfConsumptionWaterheating = -previousEstimatedShareOfConsumptionWaterheating; // the amount by which the share of consumption has changed
                if (deltaShareOfConsumptionWaterheating !== 0) {
                    const closestHeatingSystemIdx = getClosestHeatingSystem(heatingSystemIdx);
                    if (closestHeatingSystemIdx !== null) {
                        const closestHeatingSystemId =
                            _buildingHeatingSystemsState.heating_systems[closestHeatingSystemIdx].id;
                        changedEstimatedSharesOfConsumptionWaterheating = {
                            ...changedEstimatedSharesOfConsumptionWaterheating,
                            [closestHeatingSystemId]: {
                                real:
                                    changedEstimatedSharesOfConsumptionWaterheating[closestHeatingSystemId].real -
                                    deltaShareOfConsumptionWaterheating, // subtract the difference in share of consumption from the closest heating system
                                display: "",
                            },
                        };
                    }
                }
                // update the estimated shares of consumption states
                // * Note: just setting the state of buildingHeatingSystemsState here is not enough, as the state is not updated immediately
                setEstimatedSharesOfConsumptionRoomheating({
                    changedEstimatedSharesOfConsumptionRoomheating,
                });
                setEstimatedSharesOfConsumptionWaterheating({
                    changedEstimatedSharesOfConsumptionWaterheating,
                });

                setBuildingHeatingSystemsState((cond) => {
                    cond[buildingHeatingSystemsStateTab].estimated_shares_of_consumption_roomheating =
                        changedEstimatedSharesOfConsumptionRoomheating;
                    cond[buildingHeatingSystemsStateTab].estimated_shares_of_consumption_waterheating =
                        changedEstimatedSharesOfConsumptionWaterheating;
                });
            }
        }

        // deletion of data from the buildingHeatingSystemsState before sending to backend
        delete changedEstimatedSharesOfConsumptionRoomheating[currentHeatingSystem.id]; // remove the heating system from the estimated shares of consumption
        delete changedEstimatedSharesOfConsumptionWaterheating[currentHeatingSystem.id]; // remove the heating system from the estimated shares of consumption
        delete changedIndividualDegreesOfEfficiency[currentHeatingSystem.id]; // remove the heating system from the individual degrees of efficiency
        var updatedBuildingHeatingSystemsState = null;
        // update the heating systems state data with changed estimated shares of consumption and individual degrees of efficiency
        updatedBuildingHeatingSystemsState = {
            ..._buildingHeatingSystemsState,
            heating_systems: _buildingHeatingSystemsState.heating_systems.filter(
                (item) => item.id !== heatingSystem.id
            ), // remove the heating system from the heating systems array
            individual_degrees_of_efficiency: changedIndividualDegreesOfEfficiency,
            estimated_shares_of_consumption_roomheating: changedEstimatedSharesOfConsumptionRoomheating,
            estimated_shares_of_consumption_waterheating: changedEstimatedSharesOfConsumptionWaterheating,
        };

        // send the updated buildingHeatingSystemsState to the backend
        handleSaveBuildingHeatingSystemsState({ _buildingHeatingSystemsState: updatedBuildingHeatingSystemsState });
    };

    const handleSaveHeatingSystem = async ({ heatingSystem, heatingSystemIdx, newHeatingSystem = false }) => {
        setEditModeBuildingHeatingSystem(false);
        setFetchingBuildingHeatingSystemsStateData(true);

        var _buildingHeatingSystemsState = buildingHeatingSystemsState[buildingHeatingSystemsStateTab];
        var currentHeatingSystem = heatingSystem;
        var previousEstimatedShareOfConsumptionRoomheating = 0;
        var changedEstimatedSharesOfConsumptionRoomheating = estimatedSharesOfConsumptionRoomheating; // save this as a reference to the original state, only if it is changed we will construct a new copy
        var previousEstimatedShareOfConsumptionWaterheating = 0;
        var changedEstimatedSharesOfConsumptionWaterheating = estimatedSharesOfConsumptionWaterheating; // save this as a reference to the original state, only if it is changed we will construct a new copy

        if (newHeatingSystem === true) {
            // case when we added a new heating system
            // post the new heating-system to backend
            // choose /new/ endpoint if no id is present
            var _heatingSystem = cleanNumbersInJson(heatingSystem);

            var heatingSystemResponse = null;
            api_client
                .post(`/heating-systems/new/`, _heatingSystem)
                .then((response) => {
                    heatingSystemResponse = response.data.data;
                    const heatingSystemStatus = response.status;
                    if (heatingSystemStatus === 200) {
                        addToastMessage("Wärmeversorgungsanlage erfolgreich angelegt.", "success", true, 6000);
                        currentHeatingSystem = addDisplayNumbersInJson(heatingSystemResponse, [
                            "id",
                            "heating_system_type",
                            "emission_model",
                            "year",
                            "also_used_by",
                        ]);
                        changedEstimatedSharesOfConsumptionRoomheating = {
                            // add the new heating system to the temporary saved estimated shares of consumption for sending to the backend
                            ...changedEstimatedSharesOfConsumptionRoomheating,
                            [currentHeatingSystem.id]: {
                                real: _heatingSystem.estimated_share_of_consumption_roomheating,
                                display: "",
                            },
                        };
                        changedEstimatedSharesOfConsumptionWaterheating = {
                            // add the new heating system to the temporary saved estimated shares of consumption for sending to the backend
                            ...changedEstimatedSharesOfConsumptionWaterheating,
                            [currentHeatingSystem.id]: {
                                real: _heatingSystem.estimated_share_of_consumption_waterheating,
                                display: "",
                            },
                        };
                        // fix estimated shares of consumption after changes
                        // we added a new heating system, so we subtract the share of consumption from the closest heating system
                        // * Note: we check whether the share of consumption on the current heating system (the one the user edited) has changed
                        // * and only then we subtract the difference in share of consumption from the closest heating system
                        // * for that reason we have two different states for the estimated shares of consumption (part of buildingHeatingSystemState and the estimatedSharesOfConsumptionRoomheating in the component and same for waterheating)
                        if (_buildingHeatingSystemsState.heating_systems.length > 0) {
                            const deltaShareOfConsumptionRoomheating =
                                changedEstimatedSharesOfConsumptionRoomheating[currentHeatingSystem.id].real -
                                previousEstimatedShareOfConsumptionRoomheating; // the amount by which the share of consumption has changed
                            if (deltaShareOfConsumptionRoomheating !== 0) {
                                const closestHeatingSystemIdx = getClosestHeatingSystem(heatingSystemIdx);
                                if (closestHeatingSystemIdx !== null) {
                                    const closestHeatingSystemId =
                                        _buildingHeatingSystemsState.heating_systems[closestHeatingSystemIdx].id;
                                    const closestHeatingSystemNewShareOfConsumptionRoomheating =
                                        changedEstimatedSharesOfConsumptionRoomheating[closestHeatingSystemId].real -
                                        deltaShareOfConsumptionRoomheating; // subtract the difference in share of consumption from the closest heating system
                                    if (closestHeatingSystemNewShareOfConsumptionRoomheating < 0) {
                                        // to be sure check if the share of consumption is negative (should not be possible due to validation in form)
                                        addToastMessage(
                                            "Der geschätzte Anteil des Verbrauchs für die Wärmeversorgungsanlage " +
                                                _buildingHeatingSystemsState.heating_systems[closestHeatingSystemIdx]
                                                    .heating_system_type +
                                                " " +
                                                _buildingHeatingSystemsState.heating_systems[closestHeatingSystemIdx]
                                                    .year +
                                                " kann nicht negativ sein. Bitte korrigieren Sie die Anteile des Verbrauchs.",
                                            "error",
                                            true,
                                            6000
                                        );
                                        setFetchingBuildingHeatingSystemsStateData(false);
                                        return;
                                    }
                                    changedEstimatedSharesOfConsumptionRoomheating = {
                                        ...changedEstimatedSharesOfConsumptionRoomheating,
                                        [closestHeatingSystemId]: {
                                            real: closestHeatingSystemNewShareOfConsumptionRoomheating,
                                            display: "",
                                        },
                                    };
                                }
                            }
                            const deltaShareOfConsumptionWaterheating =
                                changedEstimatedSharesOfConsumptionWaterheating[currentHeatingSystem.id].real -
                                previousEstimatedShareOfConsumptionWaterheating; // the amount by which the share of consumption has changed
                            if (deltaShareOfConsumptionWaterheating !== 0) {
                                const closestHeatingSystemIdx = getClosestHeatingSystem(heatingSystemIdx);
                                if (closestHeatingSystemIdx !== null) {
                                    const closestHeatingSystemId =
                                        _buildingHeatingSystemsState.heating_systems[closestHeatingSystemIdx].id;
                                    const closestHeatingSystemNewShareOfConsumptionWaterheating =
                                        changedEstimatedSharesOfConsumptionWaterheating[closestHeatingSystemId].real -
                                        deltaShareOfConsumptionWaterheating; // subtract the difference in share of consumption from the closest heating system
                                    if (closestHeatingSystemNewShareOfConsumptionWaterheating < 0) {
                                        // to be sure check if the share of consumption is negative (should not be possible due to validation in form)
                                        addToastMessage(
                                            "Der geschätzte Anteil des Verbrauchs für die Wärmeversorgungsanlage " +
                                                _buildingHeatingSystemsState.heating_systems[closestHeatingSystemIdx]
                                                    .heating_system_type +
                                                " " +
                                                _buildingHeatingSystemsState.heating_systems[closestHeatingSystemIdx]
                                                    .year +
                                                " kann nicht negativ sein. Bitte korrigieren Sie die Anteile des Verbrauchs.",
                                            "error",
                                            true,
                                            6000
                                        );
                                        setFetchingBuildingHeatingSystemsStateData(false);
                                        return;
                                    }
                                    changedEstimatedSharesOfConsumptionWaterheating = {
                                        ...changedEstimatedSharesOfConsumptionWaterheating,
                                        [closestHeatingSystemId]: {
                                            real: closestHeatingSystemNewShareOfConsumptionWaterheating,
                                            display: "",
                                        },
                                    };
                                }
                            }
                        }
                        // put updated (and new) data into the buildingHeatingSystemsState
                        if (
                            changedEstimatedSharesOfConsumptionRoomheating !==
                                estimatedSharesOfConsumptionRoomheating ||
                            changedEstimatedSharesOfConsumptionWaterheating !== estimatedSharesOfConsumptionWaterheating
                        ) {
                            _buildingHeatingSystemsState = {
                                ..._buildingHeatingSystemsState,
                                heating_systems: [
                                    ..._buildingHeatingSystemsState.heating_systems,
                                    currentHeatingSystem,
                                ],
                                individual_degrees_of_efficiency: {
                                    ..._buildingHeatingSystemsState.individual_degrees_of_efficiency,
                                    [currentHeatingSystem.id]: _heatingSystem.individual_degree_of_efficiency,
                                },
                                estimated_shares_of_consumption_roomheating:
                                    changedEstimatedSharesOfConsumptionRoomheating,
                                estimated_shares_of_consumption_waterheating:
                                    changedEstimatedSharesOfConsumptionWaterheating,
                            };
                        }
                        handleSaveBuildingHeatingSystemsState({
                            _buildingHeatingSystemsState: _buildingHeatingSystemsState,
                        });
                        setHeatingSystemAccordionExpanded(_buildingHeatingSystemsState.heating_systems.length - 1);
                        setUpdateTimestamp(Date.now());
                    } else {
                        addToastMessage(
                            "Beim Anlegen der Wärmeversorgungsanlage ist ein Fehler aufgetreten, bitte versuchen Sie es erneut.",
                            "error",
                            true,
                            6000
                        );
                        setFetchingBuildingHeatingSystemsStateData(false);
                    }
                })
                .catch((error) => {
                    addToastMessage(
                        "Beim Anlegen der Wärmeversorgungsanlage ist ein Fehler aufgetreten, bitte versuchen Sie es erneut.",
                        "error",
                        true,
                        6000
                    );
                    setFetchingBuildingHeatingSystemsStateData(false);
                    return;
                });
        } else {
            previousEstimatedShareOfConsumptionRoomheating =
                _buildingHeatingSystemsState.estimated_shares_of_consumption_roomheating[currentHeatingSystem.id].real;
            previousEstimatedShareOfConsumptionWaterheating =
                _buildingHeatingSystemsState.estimated_shares_of_consumption_waterheating[currentHeatingSystem.id].real;
            // fix estimated shares of consumption after changes
            // * Note: we check whether the share of consumption on the current heating system (the one the user edited) has changed
            // * and only then we subtract the difference in share of consumption from the closest heating system
            // * for that reason we have two different states for the estimated shares of consumption (part of buildingHeatingSystemState and the estimatedSharesOfConsumptionRoomheating in the component and same for waterheating)
            if (_buildingHeatingSystemsState.heating_systems.length > 1) {
                const deltaShareOfConsumptionRoomheating =
                    changedEstimatedSharesOfConsumptionRoomheating[currentHeatingSystem.id].real -
                    previousEstimatedShareOfConsumptionRoomheating; // the amount by which the share of consumption has changed
                if (deltaShareOfConsumptionRoomheating !== 0) {
                    const closestHeatingSystemIdx = getClosestHeatingSystem(heatingSystemIdx);
                    if (closestHeatingSystemIdx !== null) {
                        const closestHeatingSystemId =
                            _buildingHeatingSystemsState.heating_systems[closestHeatingSystemIdx].id;
                        changedEstimatedSharesOfConsumptionRoomheating = {
                            ...changedEstimatedSharesOfConsumptionRoomheating,
                            [closestHeatingSystemId]: {
                                real:
                                    changedEstimatedSharesOfConsumptionRoomheating[closestHeatingSystemId].real -
                                    deltaShareOfConsumptionRoomheating, // subtract the difference in share of consumption from the closest heating system
                                display: "",
                            },
                        };
                    }
                }
                const deltaShareOfConsumptionWaterheating =
                    changedEstimatedSharesOfConsumptionWaterheating[currentHeatingSystem.id].real -
                    previousEstimatedShareOfConsumptionWaterheating; // the amount by which the share of consumption has changed
                if (deltaShareOfConsumptionWaterheating !== 0) {
                    const closestHeatingSystemIdx = getClosestHeatingSystem(heatingSystemIdx);
                    if (closestHeatingSystemIdx !== null) {
                        const closestHeatingSystemId =
                            _buildingHeatingSystemsState.heating_systems[closestHeatingSystemIdx].id;
                        changedEstimatedSharesOfConsumptionWaterheating = {
                            ...changedEstimatedSharesOfConsumptionWaterheating,
                            [closestHeatingSystemId]: {
                                real:
                                    changedEstimatedSharesOfConsumptionWaterheating[closestHeatingSystemId].real -
                                    deltaShareOfConsumptionWaterheating, // subtract the difference in share of consumption from the closest heating system
                                display: "",
                            },
                        };
                    }
                }
            }
            // if we changed the estimated shares of consumption, we need to put the new values into the data we send to the backend
            if (
                changedEstimatedSharesOfConsumptionRoomheating !== estimatedSharesOfConsumptionRoomheating ||
                changedEstimatedSharesOfConsumptionWaterheating !== estimatedSharesOfConsumptionWaterheating
            ) {
                _buildingHeatingSystemsState = {
                    ..._buildingHeatingSystemsState,
                    estimated_shares_of_consumption_roomheating: changedEstimatedSharesOfConsumptionRoomheating,
                    estimated_shares_of_consumption_waterheating: changedEstimatedSharesOfConsumptionWaterheating,
                };
            }
            handleSaveBuildingHeatingSystemsState({ _buildingHeatingSystemsState: _buildingHeatingSystemsState });
        }
    };

    const getHeatingSystemTypeFromId = (id) => {
        const heatingSystemType = heatingSystemTypes.find((item) => {
            return item.id === id;
        });
        if (heatingSystemType) {
            return heatingSystemType;
        } else {
            return null;
        }
    };

    const [heatingSystemAccordionExpanded, setHeatingSystemAccordionExpanded] = useState(
        buildingHeatingSystemsState[buildingHeatingSystemsStateTab]?.heating_systems.length === 0 ? -1 : 0
    );
    const handleHeatingSystemAccordionExpanded = (panel) => (event, newExpanded) => {
        if (!editModeBuildingHeatingSystem) {
            setHeatingSystemAccordionExpanded(newExpanded ? panel : false);
        }
    };

    // hooks to update the estimated shares of consumption states used as temporary state for user input
    useEffect(() => {
        setEstimatedSharesOfConsumptionRoomheating(
            buildingHeatingSystemsState[buildingHeatingSystemsStateTab]?.estimated_shares_of_consumption_roomheating
        );
        return () => {};
    }, [buildingHeatingSystemsState[buildingHeatingSystemsStateTab]?.estimated_shares_of_consumption_roomheating]);
    useEffect(() => {
        setEstimatedSharesOfConsumptionWaterheating(
            buildingHeatingSystemsState[buildingHeatingSystemsStateTab]?.estimated_shares_of_consumption_waterheating
        );
        return () => {};
    }, [buildingHeatingSystemsState[buildingHeatingSystemsStateTab]?.estimated_shares_of_consumption_waterheating]);

    // * Note: the following code is commented out, as it is not used in the current version of the application
    // * use it to implement a modal for editing the estimated shares of consumption
    // const [editModeConsumptionShares, setEditModeConsumptionShares] = useState(false);

    // const handleEditModeConsumptionShares = () => {
    //     if (editModeConsumptionShares === true) {
    //         fetchBuildingHeatingSystemData();
    //         setEditModeConsumptionShares(false);
    //     } else {
    //         setEditModeConsumptionShares(true);
    //     }
    // };
    // const handleSaveConsumptionShares = async () => {
    //     setEditModeConsumptionShares((prevEditMode) => !prevEditMode);
    //     handleSaveHeatingSystem();
    // };

    const HeatingSystemAccordions = (
        <Box sx={{ mt: 1, width: "50%" }}>
            {buildingHeatingSystemsState.length > 0 &&
                buildingHeatingSystemsState[buildingHeatingSystemsStateTab].heating_systems.map((val, index) => {
                    return (
                        <HeatingSystemDetail
                            key={"heating-system-" + index}
                            heatingSystem={val}
                            heatingSystemIdx={index}
                            editModeBuildingHeatingSystem={editModeBuildingHeatingSystem}
                            handleEditMode={handleEditMode}
                            handleSave={handleSaveHeatingSystem}
                            handleDelete={handleDeleteHeatingSystem}
                            energySources={energySources}
                            emissionModels={emissionModels}
                            heatingSystemTypes={heatingSystemTypes}
                            // consumptionTypes={consumptionTypes}
                            estimatedSharesOfConsumptionRoomheating={estimatedSharesOfConsumptionRoomheating}
                            setEstimatedSharesOfConsumptionRoomheating={setEstimatedSharesOfConsumptionRoomheating}
                            estimatedSharesOfConsumptionWaterheating={estimatedSharesOfConsumptionWaterheating}
                            setEstimatedSharesOfConsumptionWaterheating={setEstimatedSharesOfConsumptionWaterheating}
                            heatingSystemAccordionExpanded={heatingSystemAccordionExpanded}
                            handleHeatingSystemAccordionExpanded={handleHeatingSystemAccordionExpanded}
                            buildingHeatingSystemsState={buildingHeatingSystemsState}
                            setBuildingHeatingSystemsState={setBuildingHeatingSystemsState}
                            buildingHeatingSystemsStateTab={buildingHeatingSystemsStateTab}
                            errorBuildingHeatingSystemsState={errorBuildingHeatingSystemsState}
                            setErrorBuildingHeatingSystemsState={setErrorBuildingHeatingSystemsState}
                        />
                    );
                })}
            <NewHeatingSystemDetail
                editModeBuildingHeatingSystem={editModeBuildingHeatingSystem}
                setEditModeBuildingHeatingSystem={setEditModeBuildingHeatingSystem}
                buildingHeatingSystemsStateTab={buildingHeatingSystemsStateTab}
                buildingHeatingSystemsState={buildingHeatingSystemsState}
                heatingSystemAccordionExpanded={heatingSystemAccordionExpanded}
                handleHeatingSystemAccordionExpanded={handleHeatingSystemAccordionExpanded}
                handleSave={handleSaveHeatingSystem}
                energySources={energySources}
                emissionModels={emissionModels}
                heatingSystemTypes={heatingSystemTypes}
                // consumptionTypes={consumptionTypes}
                estimatedSharesOfConsumptionRoomheating={estimatedSharesOfConsumptionRoomheating}
                estimatedSharesOfConsumptionWaterheating={estimatedSharesOfConsumptionWaterheating}
                getHeatingSystemTypeFromId={getHeatingSystemTypeFromId}
            />
        </Box>
    );

    return (
        <>
            <DeleteConfirmationModal
                deleteConfirmationModalOpen={deleteConfirmationModalOpen}
                handleCloseDeleteConfirmationModal={handleCloseDeleteConfirmationModal}
                confirmationText={
                    "Sind Sie sicher, dass Sie " +
                    'den Wärmeversorgungsanlagen-Zustand "' +
                    buildingHeatingSystemsState[buildingHeatingSystemsStateTab]?.year +
                    '"' +
                    " und alle zugehörigen Daten löschen möchten?"
                }
                deletionTargetFunction={deleteBuildingHeatingSystemsState}
            />
            <Box sx={{ mt: 3 }}>
                <Accordion sx={{ width: "160ch" }}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <HeatPumpOutlinedIcon sx={{ fontSize: 30 }} />{" "}
                        <Typography
                            sx={{
                                ml: 2,
                                fontSize: 20,
                                fontWeight: "bold",
                            }}
                        >
                            Wärmeversorgungsanlagen
                        </Typography>
                        {buildingHeatingSystemsState.length === 0 && (
                            <CustomTooltip title="Zu diesem Gebäude sind noch keine Wärmeversorgungsanlagen hinterlegt.">
                                <ReportProblemTwoToneIcon sx={{ color: "#ff9e17", fontSize: 23, mt: 0.6, ml: 2 }} />
                            </CustomTooltip>
                        )}
                    </AccordionSummary>
                    <AccordionDetails
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                        }}
                    >
                        <Divider sx={{ mb: 2, mt: -2, width: "100%" }} />
                        {fetchingBuildingHeatingSystemsStateData && LoadingSpinner}
                        <Box sx={{ ml: 0 }} width="90%">
                            <Fab
                                variant="extended"
                                size="medium"
                                onClick={handleOpenAddHeatingSystemYear}
                                disabled={editModeBuildingHeatingSystem}
                                sx={{ zIndex: 0, boxShadow: 1, borderRadius: 1 }}
                            >
                                <AddIcon sx={{ mr: 1 }} />
                                Wärmeversorgungsanlagen-Zustand hinzufügen
                            </Fab>
                            {buildingHeatingSystemsState.length > 0 && (
                                <Fab
                                    variant="extended"
                                    size="medium"
                                    disabled={editModeBuildingHeatingSystem}
                                    onClick={(e) => {
                                        handleOpenDeleteConfirmationModal();
                                    }}
                                    sx={{ ml: 2, zIndex: 0, boxShadow: 1, borderRadius: 1 }}
                                >
                                    <DeleteIcon />
                                    Wärmeversorgungsanlagen-Zustand löschen
                                </Fab>
                            )}
                            <Modal
                                open={addHeatingSystemsStateYearModalOpen}
                                onClose={handleCloseAddHeatingSystemYear}
                                aria-labelledby="add-year-modal"
                                onKeyUp={(e) => {
                                    if (e.key === "Enter") {
                                        if (addHeatingSystemsStateYearValid) {
                                            addBuildingHeatingSystemsStateTab();
                                        }
                                    }
                                }}
                            >
                                <Box
                                    sx={{
                                        position: "absolute",
                                        top: "50%",
                                        left: "50%",
                                        transform: "translate(-50%, -50%)",
                                        width: 500,
                                        bgcolor: "background.paper",
                                        border: "2px solid #000",
                                        boxShadow: 24,
                                        p: 4,
                                    }}
                                >
                                    <Button
                                        sx={{
                                            position: "absolute",
                                            top: "10px",
                                            right: "5px",
                                        }}
                                        onClick={() => handleCloseAddHeatingSystemYear()}
                                    >
                                        <CloseIcon />
                                    </Button>
                                    <Typography id="modal-modal-title" variant="h6" component="h2">
                                        Für welches Jahr möchten Sie einen Wärmeversorgungsanlagen-Zustand hinzufügen?
                                    </Typography>
                                    <NumberInput
                                        value={addHeatingSystemsStateYearChoice}
                                        variant="outlined"
                                        error={!addHeatingSystemsStateYearValid}
                                        onChange={(e) => {
                                            if (
                                                parseInt(e.target.value) < 1800 ||
                                                parseInt(e.target.value) > 2050 ||
                                                e.target.value.length !== 4 ||
                                                !buildingHeatingSystemsState.every(
                                                    (c) => c.year !== parseInt(e.target.value)
                                                )
                                            ) {
                                                setAddHeatingSystemsStateYearValid(false);
                                            } else {
                                                setAddHeatingSystemsStateYearValid(true);
                                            }
                                            setAddHeatingSystemsStateYearChoice(parseInt(e.target.value));
                                        }}
                                        sx={{ mt: 1 }}
                                        size="small"
                                        label="Jahr"
                                        type="number"
                                    />
                                    <Fab
                                        variant="extended"
                                        size="medium"
                                        disabled={!addHeatingSystemsStateYearValid}
                                        onClick={(e) => addBuildingHeatingSystemsStateTab()}
                                        color="secondary"
                                        sx={{ ml: 3, mt: 0.8, boxShadow: 1, borderRadius: 1 }}
                                    >
                                        Speichern
                                    </Fab>
                                </Box>
                            </Modal>
                            {buildingHeatingSystemsState.length > 0 ? (
                                <>
                                    <Box
                                        component="form"
                                        noValidate
                                        autoComplete="off"
                                        width="100%"
                                        onKeyUp={(e) => {
                                            if (e.key === "Enter" && editModeBuildingHeatingSystem) {
                                                if (
                                                    !(
                                                        Object.keys(errorBuildingHeatingSystemsState).length > 0 &&
                                                        !Object.values(errorBuildingHeatingSystemsState).every(
                                                            (item) => item === false
                                                        )
                                                    )
                                                ) {
                                                    handleSaveHeatingSystem();
                                                }
                                            }
                                        }}
                                    >
                                        <div
                                            sx={{
                                                display: "flex",
                                                flexDirection: "column",
                                                gap: "0.5rem",
                                                mt: 2,
                                                border: 1,
                                                borderRadius: 2,
                                            }}
                                        >
                                            <Box
                                                sx={{ bgcolor: theme.palette.secondary.main, borderRadius: "5px" }}
                                                width="100%"
                                            >
                                                <Tabs
                                                    TabIndicatorProps={{
                                                        children: <span className="MuiTabs-indicatorSpan" />,
                                                    }}
                                                    value={buildingHeatingSystemsStateTab}
                                                    onChange={handleBuildingHeatingSystemTab}
                                                    variant="scrollable"
                                                    scrollButtons={true}
                                                    sx={{
                                                        borderColor: theme.palette.neutral.light,
                                                        height: "20px",
                                                        // bgcolor: theme.palette.primary.main,
                                                        mb: 1,
                                                        mt: 2,
                                                        "& .MuiTabs-scrollButtons.Mui-disabled": {
                                                            opacity: 0.3,
                                                        },
                                                        "& .Mui-selected": {
                                                            color: "primary",
                                                        },
                                                        "& .MuiTabs-indicator": {
                                                            display: "flex",
                                                            justifyContent: "center",
                                                            backgroundColor: "transparent",
                                                            height: 4,
                                                            mb: "7px",
                                                        },
                                                        "& .MuiTabs-indicatorSpan": {
                                                            maxWidth: 50,
                                                            width: "100%",
                                                            backgroundColor: theme.palette.background.default,
                                                            height: "100%",
                                                        },
                                                    }}
                                                >
                                                    {buildingHeatingSystemsState.map((val, index) => {
                                                        return (
                                                            <Tab
                                                                disabled={editModeBuildingHeatingSystem}
                                                                label={val.year}
                                                                id={index}
                                                                key={"heating-system-state-tab-" + index}
                                                                sx={{ fontWeight: "bold", fontSize: "20px" }}
                                                            />
                                                        );
                                                    })}
                                                </Tabs>
                                            </Box>
                                            <Grid container>
                                                {HeatingSystemAccordions}
                                                {buildingHeatingSystemsState[buildingHeatingSystemsStateTab]
                                                    .heating_systems.length > 0 && (
                                                    <LoadDistribution
                                                        buildingHeatingSystemsState={buildingHeatingSystemsState}
                                                        buildingHeatingSystemsStateTab={buildingHeatingSystemsStateTab}
                                                        heatingSystemAccordionExpanded={heatingSystemAccordionExpanded}
                                                    />
                                                )}
                                            </Grid>
                                        </div>
                                    </Box>
                                </>
                            ) : (
                                <Box
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        minHeight: "200px",
                                        width: "100%",
                                    }}
                                >
                                    <Alert severity="info" sx={{ textAlign: "left", height: "8ch", fontSize: 14 }}>
                                        <strong>Es sind keine Wärmeversorgungsanlagen-Zustände hinterlegt.</strong>
                                        <br />
                                        Bitte fügen Sie einen Wärmeversorgungsanlagen-Zustand über
                                        "Wärmeversorgungsanlagen-Zustand hinzufügen" hinzu.
                                        <br />
                                    </Alert>
                                </Box>
                            )}
                        </Box>
                    </AccordionDetails>
                </Accordion>
            </Box>
        </>
    );
};

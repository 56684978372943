import React, { useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { Typography, InputAdornment, FormControl, OutlinedInput, InputLabel, IconButton } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Link } from "react-router-dom";
// import { Button, TextField } from '@mui/material';
import { useContext } from "react";
import AuthContext from "../../contexts/AuthContext";

/**
 * A React functional component that renders the login page for the application.
 *
 * This component provides a form for users to enter their username and password to log in.
 * It uses the `AuthContext` to handle the login process and manages local state for form inputs and error messages.
 *
 * Returns:
 *   ReactElement: A component that displays the login form with username and password fields, and a submit button.
 */
const Login = () => {
    const { loginUser } = useContext(AuthContext);
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [errorTextfields, setErrorTextfields] = useState(false);
    const [showPassword, setShowPassword] = React.useState(false);

    const handleSubmit = (event) => {
        event.preventDefault();
        loginUser(username, password, setErrorMessage, setErrorTextfields);
    };

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    return (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                // height: "100vh",
            }}
            data-testid="login-page"
        >
            <Grid>
                <Box sx={{ width: "50%", mb: 12, ml: -7, paddingTop: 12 }}>
                    <a href="https://advisore.eu">
                        <img src={"/Logo_ohne_Hintergrund.png"} alt="AdvisoRE Logo" width="500" />
                    </a>
                </Box>
                <div
                    style={{
                        width: "400px",
                        padding: "30px",
                        backgroundColor: "white",
                        borderRadius: "10px",
                        boxShadow: "0px 0px 10px lightgrey",
                    }}
                >
                    <Typography
                        variant="h2"
                        fontWeight="bold"
                        sx={{
                            m: "20px 0px 40px 0",
                            alignContent: "center",
                            display: "flex",
                            justifyContent: "center",
                        }}
                    >
                        <img src={"/EnergyARK_Full.png"} alt="EnergyARK Logo" width="250" height="45" />
                    </Typography>
                    <form
                        data-testid="login-form"
                        // onKeyUp={(e) => {
                        //     if (e.key === "Enter") {
                        //         handleSubmit(e);
                        //     }
                        // }}
                    >
                        <TextField
                            error={errorTextfields}
                            variant="outlined"
                            label="Benutzername"
                            value={username}
                            onChange={(event) => {
                                setUsername(event.target.value);
                                setErrorTextfields(false);
                            }}
                            fullWidth
                            style={{ marginBottom: "20px" }}
                            data-testid="username-input"
                        />
                        <FormControl sx={{ width: "100%" }}>
                            <InputLabel htmlFor="outlined-adornment-password">Passwort</InputLabel>
                            <OutlinedInput
                                error={errorTextfields}
                                variant="outlined"
                                label="Passwort"
                                value={password}
                                onChange={(event) => {
                                    setPassword(event.target.value);
                                    setErrorTextfields(false);
                                }}
                                style={{ marginBottom: "20px" }}
                                type={showPassword ? "text" : "password"}
                                data-testid="password-input"
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                            data-testid="password-toggle"
                                        >
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                        </FormControl>
                        {errorMessage && ( // Conditionally render error message
                            <Typography
                                variant="body1"
                                style={{ color: "red", marginLeft: "15px", marginBottom: "20px" }}
                                data-testid="error-message"
                            >
                                {errorMessage}
                            </Typography>
                        )}
                        <Button
                            variant="contained"
                            color="secondary"
                            type="submit"
                            // className={ClassNames.submit}
                            fullWidth
                            onClick={handleSubmit}
                            style={{ height: "56px", marginTop: "20px" }}
                            data-testid="login-button"
                        >
                            Login
                        </Button>
                        <Link
                            to="/reset-password/sendmail"
                            style={{
                                textDecoration: "none",
                                marginTop: "15px",
                                display: "block",
                                textAlign: "center",
                            }}
                        >
                            Passwort vergessen?
                        </Link>
                    </form>
                </div>
                <Box sx={{ width: "50%", mt: "30%", mb: "-30%", ml: "37%", paddingBottom: 5 }}>
                    <a href="https://advisore.eu/?page_id=219" target="_blank" rel="noreferrer">
                        Impressum
                    </a>
                </Box>
            </Grid>
        </div>
    );
};

export default Login;

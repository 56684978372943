/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from "react";
import { Typography, Box, Fab, InputAdornment, MenuItem, Badge } from "@mui/material";

import InfoIcon from "@mui/icons-material/Info";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import DeleteConfirmationModal from "../../../components/DeleteConfirmationModal";

import { FloatInput, ValidatedTextField, BasicSelectField, BasicTextField } from "../../../components/InputFields";
import {
    CustomAccordion,
    CustomAccordionDetails,
    CustomAccordionSummary,
    CustomAccordionActions,
} from "../../../components/CustomAccordion";
import { CustomTooltip } from "../../../components/CustomTooltip";
import { createGermanNumberString } from "../../../utils/formatting";

export const HeatingSystemDetail = ({
    heatingSystem,
    heatingSystemIdx,
    editModeBuildingHeatingSystem,
    handleEditMode,
    handleSave,
    handleDelete,
    energySources,
    emissionModels,
    // consumptionTypes,
    heatingSystemTypes,
    heatingSystemAccordionExpanded,
    handleHeatingSystemAccordionExpanded,
    buildingHeatingSystemsState,
    setBuildingHeatingSystemsState,
    buildingHeatingSystemsStateTab,
    estimatedSharesOfConsumptionRoomheating,
    setEstimatedSharesOfConsumptionRoomheating,
    estimatedSharesOfConsumptionWaterheating,
    setEstimatedSharesOfConsumptionWaterheating,
    errorBuildingHeatingSystemsState,
    setErrorBuildingHeatingSystemsState,
}) => {
    const getHeatingSystemTypeFromId = (id) => {
        const heatingSystemType = heatingSystemTypes.find((item) => {
            return item.id === id;
        });
        if (heatingSystemType) {
            return heatingSystemType;
        } else {
            return null;
        }
    };

    const [deleteConfirmationModalOpen, setDeleteConfirmationModalOpen] = useState(false);
    const handleOpenDeleteConfirmationModal = () => setDeleteConfirmationModalOpen(true);
    const handleCloseDeleteConfirmationModal = () => setDeleteConfirmationModalOpen(false);

    const heatingSystemType = getHeatingSystemTypeFromId(heatingSystem.heating_system_type);

    const handleDeleteHeatingSystem = () => {
        handleCloseDeleteConfirmationModal();
        handleDelete({ heatingSystem: heatingSystem, heatingSystemIdx: heatingSystemIdx });
    };

    return (
        <>
            {deleteConfirmationModalOpen && (
                <DeleteConfirmationModal
                    deleteConfirmationModalOpen={deleteConfirmationModalOpen}
                    handleCloseDeleteConfirmationModal={handleCloseDeleteConfirmationModal}
                    confirmationText={
                        <>
                            Sind Sie sicher, dass Sie die Wärmeversorgungsanlage "<b>{heatingSystemType?.name}</b>
                            {heatingSystem.year ? ", eingebaut " + heatingSystem.year : ""}" aus diesem
                            Wärmeversorgungsanlagen-Zustand entfernen möchten?
                        </>
                    }
                    deletionTargetFunction={handleDeleteHeatingSystem}
                />
            )}
            <CustomAccordion
                sx={{ maxWidth: "67ch" }}
                expanded={heatingSystemAccordionExpanded === heatingSystemIdx}
                onChange={handleHeatingSystemAccordionExpanded(heatingSystemIdx)}
                disabled={!(heatingSystemAccordionExpanded === heatingSystemIdx) && editModeBuildingHeatingSystem}
            >
                <CustomAccordionSummary>
                    <Box width="93%">
                        <Typography
                            sx={{
                                ml: 2,
                                mt: 1,
                                fontSize: 15,
                            }}
                        >
                            <b>{heatingSystemType?.name}</b>
                            {heatingSystem.year ? ", eingebaut " + heatingSystem.year : ""}
                            {heatingSystem.also_used_by.length > 1 && (
                                <CustomTooltip
                                    title={
                                        <>
                                            Diese Wärmeversorgungsanlage wird ebenfalls von anderen
                                            Wärmeversorgungsanlagen-Zuständen genutzt. Wenn Sie bestimmte Angaben dieser
                                            WVA anpassen, so hat dies Einfluss auf die anderen Zustände. Angaben zur
                                            Lastverteilung und der Abweichende Jahresnutzungsgrad sind dabei unabhängig
                                            und können je Zustand separat angepasst werden.
                                            <br /> Folgende Zustände nutzen diese Anlage:{" "}
                                            {heatingSystem.also_used_by.map((val) => {
                                                return (
                                                    <>
                                                        <br />- <b>{val}</b>
                                                    </>
                                                );
                                            })}
                                        </>
                                    }
                                >
                                    <Badge badgeContent={heatingSystem.also_used_by.length} color="secondary">
                                        <InfoIcon color="neutral" sx={{ fontSize: 25, mb: 0.5, ml: 2 }} />
                                    </Badge>
                                </CustomTooltip>
                            )}
                        </Typography>
                    </Box>
                    <Box width="7%">
                        <Typography
                            sx={{
                                ml: 2,
                                mt: 1,
                                fontSize: 18,
                            }}
                        >
                            <b>A{heatingSystemIdx + 1}</b>
                        </Typography>
                    </Box>
                </CustomAccordionSummary>
                <CustomAccordionDetails sx={{ height: "34ch" }}>
                    <div>
                        <BasicSelectField
                            FormControlProps={{ sx: { mt: 1, mr: 1 } }}
                            InputLabelProps={{
                                disabled: !editModeBuildingHeatingSystem,
                                sx: {
                                    mt: !heatingSystem.energy_source ? -0.8 : 0,
                                },
                            }}
                            // variant={editModeBuildingHeatingSystem ? "outlined" : "filled"}
                            label="Energieträger"
                            size="small"
                            value={heatingSystem.energy_source ?? ""}
                            onChange={(e) =>
                                setBuildingHeatingSystemsState((cond) => {
                                    cond[[buildingHeatingSystemsStateTab]].heating_systems[
                                        heatingSystemIdx
                                    ].energy_source = e.target.value;
                                    cond[[buildingHeatingSystemsStateTab]].heating_systems[
                                        heatingSystemIdx
                                    ].degree_of_efficiency = {
                                        display: createGermanNumberString(
                                            energySources.find((item) => item.name === e.target.value)
                                                ?.degree_of_efficiency
                                        ),
                                        real: energySources.find((item) => item.name === e.target.value)
                                            ?.degree_of_efficiency,
                                    };
                                    cond[[buildingHeatingSystemsStateTab]].heating_systems[
                                        heatingSystemIdx
                                    ].emission_model =
                                        emissionModels.find(
                                            (item) => item.energy_source === e.target.value && item.is_default
                                        )?.id ?? "";
                                    cond[[buildingHeatingSystemsStateTab]].heating_systems[
                                        heatingSystemIdx
                                    ].heating_system_type = "";
                                })
                            }
                            disabled={!editModeBuildingHeatingSystem}
                            sx={{
                                width: "27ch",
                            }}
                            customMenuItems={energySources.map((item) => {
                                return (
                                    <MenuItem key={item.name} value={item.name}>
                                        {item.name}
                                    </MenuItem>
                                );
                            })}
                        />
                        <BasicSelectField
                            FormControlProps={{ sx: { mt: 1, mr: 1 } }}
                            InputLabelProps={{
                                disabled: !editModeBuildingHeatingSystem,
                                sx: {
                                    mt: !heatingSystem.heating_system_type ? -0.8 : 0,
                                },
                            }}
                            // variant={editModeBuildingHeatingSystem ? "outlined" : "filled"}
                            label="Anlagentyp"
                            size="small"
                            value={heatingSystem.heating_system_type ?? ""}
                            onChange={(e) =>
                                setBuildingHeatingSystemsState((cond) => {
                                    cond[[buildingHeatingSystemsStateTab]].heating_systems[
                                        heatingSystemIdx
                                    ].heating_system_type = e.target.value;
                                    cond[[buildingHeatingSystemsStateTab]].heating_systems[
                                        heatingSystemIdx
                                    ].degree_of_efficiency = {
                                        display: createGermanNumberString(
                                            heatingSystemTypes.find((item) => item.id === e.target.value)
                                                ?.degree_of_efficiency
                                        ),
                                        real: heatingSystemTypes.find((item) => item.id === e.target.value)
                                            ?.degree_of_efficiency,
                                    };
                                })
                            }
                            disabled={!editModeBuildingHeatingSystem}
                            sx={{
                                width: "34ch",
                            }}
                            customMenuItems={heatingSystemTypes.map((item) => {
                                if (item.energy_source === heatingSystem.energy_source) {
                                    return (
                                        <MenuItem key={item.name} value={item.id}>
                                            {item.name}
                                        </MenuItem>
                                    );
                                } else {
                                    return null;
                                }
                            })}
                        />
                        <ValidatedTextField
                            type="number"
                            // variant={editModeBuildingHeatingSystem ? "outlined" : "filled"}
                            label="Einbaujahr"
                            size="small"
                            value={heatingSystem.year}
                            onChange={(event) =>
                                setBuildingHeatingSystemsState((cond) => {
                                    cond[[buildingHeatingSystemsStateTab]].heating_systems[heatingSystemIdx].year =
                                        event.target.value;
                                })
                            }
                            disabled={!editModeBuildingHeatingSystem}
                            sx={{
                                mt: 1,
                                mr: 1,
                                width: "25ch",
                            }}
                            validate={(val) => {
                                if (
                                    !val ||
                                    Number(val) < 1900 ||
                                    Number(val) > buildingHeatingSystemsState[buildingHeatingSystemsStateTab].year
                                ) {
                                    return false;
                                }
                                return true;
                            }}
                            error={
                                errorBuildingHeatingSystemsState.year ||
                                (editModeBuildingHeatingSystem && !heatingSystem.year)
                            }
                            setError={(val) => {
                                setErrorBuildingHeatingSystemsState((err) => {
                                    err.year = val;
                                });
                            }}
                        />
                        <BasicSelectField
                            FormControlProps={{ sx: { mt: 1, mr: 1 } }}
                            InputLabelProps={{
                                disabled: !editModeBuildingHeatingSystem,
                                sx: { mt: !heatingSystem.emission_model ? -0.8 : 0 },
                            }}
                            // variant={
                            //     editModeBuildingHeatingSystem && heatingSystem.energy_source !== "-"
                            //         ? "outlined"
                            //         : "filled"
                            // }
                            label="Emissionsmodell"
                            size="small"
                            value={heatingSystem.emission_model}
                            onChange={(e) =>
                                setBuildingHeatingSystemsState((cond) => {
                                    cond[buildingHeatingSystemsStateTab].heating_systems[
                                        heatingSystemIdx
                                    ].emission_model = e.target.value;
                                })
                            }
                            disabled={!editModeBuildingHeatingSystem || heatingSystem.energy_source === "-"}
                            sx={{
                                width: "36ch",
                            }}
                            customMenuItems={emissionModels.map((item) => {
                                if (item.energy_source === heatingSystem.energy_source) {
                                    return (
                                        <MenuItem key={item.name} value={item.id}>
                                            {item.name}
                                        </MenuItem>
                                    );
                                } else {
                                    return null;
                                }
                            })}
                        />
                        {/* ------------------------------------ Lastanteil Raumwärme ------------------------------------ */}
                        {estimatedSharesOfConsumptionRoomheating &&
                            estimatedSharesOfConsumptionRoomheating[heatingSystem.id] && (
                                <FloatInput
                                    // variant={editModeBuildingHeatingSystem ? "outlined" : "filled"}
                                    label="Lastanteil Raumwärme"
                                    size="small"
                                    value={estimatedSharesOfConsumptionRoomheating[heatingSystem.id]}
                                    onChange={(val) => {
                                        setEstimatedSharesOfConsumptionRoomheating({
                                            ...estimatedSharesOfConsumptionRoomheating,
                                            [heatingSystem.id]: val,
                                        });
                                    }}
                                    disabled={
                                        !editModeBuildingHeatingSystem ||
                                        buildingHeatingSystemsState[[buildingHeatingSystemsStateTab]].heating_systems
                                            .length === 1
                                    }
                                    sx={{ mt: 1, mr: 1, ml: 0, width: "30.5ch" }}
                                    validate={(val) => {
                                        const closestHeatingSystemIdx =
                                            buildingHeatingSystemsState[[buildingHeatingSystemsStateTab]]
                                                .heating_systems.length ===
                                            heatingSystemIdx + 1
                                                ? heatingSystemIdx - 1
                                                : heatingSystemIdx + 1;
                                        if (
                                            val < 0 ||
                                            val >
                                                buildingHeatingSystemsState[[buildingHeatingSystemsStateTab]]
                                                    .estimated_shares_of_consumption_roomheating[heatingSystem.id]
                                                    .real +
                                                    buildingHeatingSystemsState[[buildingHeatingSystemsStateTab]]
                                                        .estimated_shares_of_consumption_roomheating[
                                                        buildingHeatingSystemsState[[buildingHeatingSystemsStateTab]]
                                                            .heating_systems[closestHeatingSystemIdx].id
                                                    ].real
                                        ) {
                                            return false;
                                        }
                                        return true;
                                    }}
                                    error={errorBuildingHeatingSystemsState.estimated_shares_of_consumption_roomheating}
                                    setError={(val) => {
                                        setErrorBuildingHeatingSystemsState((err) => {
                                            err.estimated_shares_of_consumption_roomheating = val;
                                        });
                                    }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <Typography sx={{ mr: 1 }}>%</Typography>
                                                <CustomTooltip
                                                    title={
                                                        <>
                                                            Der geschätze Anteil des Raumwärmebedarfs, der durch diese
                                                            Anlage bedient wird. <br />
                                                            {buildingHeatingSystemsState[
                                                                [buildingHeatingSystemsStateTab]
                                                            ].heating_systems.length > 1 ? (
                                                                <>
                                                                    Wichtig: Wenn Sie diesen Wert anpassen, hat dies
                                                                    einen impliziten Effekt auf den Raumwärme-Lastanteil
                                                                    der Anlage
                                                                    <b>
                                                                        {" "}
                                                                        A
                                                                        {buildingHeatingSystemsState[
                                                                            [buildingHeatingSystemsStateTab]
                                                                        ].heating_systems.length ===
                                                                        heatingSystemIdx + 1
                                                                            ? heatingSystemIdx
                                                                            : heatingSystemIdx + 2}
                                                                    </b>
                                                                    . Daher kann der Lastanteil dieser Anlage nicht
                                                                    größer sein als die Summe des bisherigen Lastanteils
                                                                    plus dem aktuellen Lastanteil der Anlage
                                                                    <b>
                                                                        {" "}
                                                                        A
                                                                        {buildingHeatingSystemsState[
                                                                            [buildingHeatingSystemsStateTab]
                                                                        ].heating_systems.length ===
                                                                        heatingSystemIdx + 1
                                                                            ? heatingSystemIdx
                                                                            : heatingSystemIdx + 2}
                                                                    </b>
                                                                    .
                                                                </>
                                                            ) : null}
                                                        </>
                                                    }
                                                >
                                                    <InfoIcon color="neutral" />
                                                </CustomTooltip>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            )}
                        {/* ------------------------------------ Lastanteil Warmwasser ------------------------------------ */}
                        {estimatedSharesOfConsumptionWaterheating &&
                            estimatedSharesOfConsumptionWaterheating[heatingSystem.id] && (
                                <FloatInput
                                    // variant={editModeBuildingHeatingSystem ? "outlined" : "filled"}
                                    label="Lastanteil Warmwasser"
                                    size="small"
                                    value={estimatedSharesOfConsumptionWaterheating[heatingSystem.id]}
                                    onChange={(val) => {
                                        setEstimatedSharesOfConsumptionWaterheating({
                                            ...estimatedSharesOfConsumptionWaterheating,
                                            [heatingSystem.id]: val,
                                        });
                                    }}
                                    disabled={
                                        !editModeBuildingHeatingSystem ||
                                        buildingHeatingSystemsState[[buildingHeatingSystemsStateTab]].heating_systems
                                            .length === 1
                                    }
                                    sx={{ mt: 1, width: "30.5ch" }}
                                    validate={(val) => {
                                        const closestHeatingSystemIdx =
                                            buildingHeatingSystemsState[[buildingHeatingSystemsStateTab]]
                                                .heating_systems.length ===
                                            heatingSystemIdx + 1
                                                ? heatingSystemIdx - 1
                                                : heatingSystemIdx + 1;
                                        if (
                                            val < 0 ||
                                            val >
                                                buildingHeatingSystemsState[[buildingHeatingSystemsStateTab]]
                                                    .estimated_shares_of_consumption_waterheating[heatingSystem.id]
                                                    .real +
                                                    buildingHeatingSystemsState[[buildingHeatingSystemsStateTab]]
                                                        .estimated_shares_of_consumption_waterheating[
                                                        buildingHeatingSystemsState[[buildingHeatingSystemsStateTab]]
                                                            .heating_systems[closestHeatingSystemIdx].id
                                                    ].real
                                        ) {
                                            return false;
                                        }
                                        return true;
                                    }}
                                    error={
                                        errorBuildingHeatingSystemsState.estimated_shares_of_consumption_waterheating
                                    }
                                    setError={(val) => {
                                        setErrorBuildingHeatingSystemsState((err) => {
                                            err.estimated_shares_of_consumption_waterheating = val;
                                        });
                                    }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <Typography sx={{ mr: 1 }}>%</Typography>
                                                <CustomTooltip
                                                    title={
                                                        <>
                                                            Der geschätze Anteil des Warmwasserbedarfs aus{" "}
                                                            <i>zentraler Warmwasseraufbereitung</i>, der durch diese
                                                            Anlage bedient wird. Dies hat keinen Einfluss auf die
                                                            Berechnungen zur dezentralen Warmwasseraufbereitung. <br />
                                                            {buildingHeatingSystemsState[
                                                                [buildingHeatingSystemsStateTab]
                                                            ].heating_systems.length > 1 ? (
                                                                <>
                                                                    Wichtig: Wenn Sie diesen Wert anpassen, hat dies
                                                                    einen impliziten Effekt auf den
                                                                    Warmwasser-Lastanteil der Anlage
                                                                    <b>
                                                                        {" "}
                                                                        A
                                                                        {buildingHeatingSystemsState[
                                                                            [buildingHeatingSystemsStateTab]
                                                                        ].heating_systems.length ===
                                                                        heatingSystemIdx + 1
                                                                            ? heatingSystemIdx
                                                                            : heatingSystemIdx + 2}
                                                                    </b>
                                                                    . Daher kann der Lastanteil dieser Anlage nicht
                                                                    größer sein als die Summe des bisherigen Lastanteils
                                                                    plus dem aktuellen Lastanteil der Anlage
                                                                    <b>
                                                                        {" "}
                                                                        A
                                                                        {buildingHeatingSystemsState[
                                                                            [buildingHeatingSystemsStateTab]
                                                                        ].heating_systems.length ===
                                                                        heatingSystemIdx + 1
                                                                            ? heatingSystemIdx
                                                                            : heatingSystemIdx + 2}
                                                                    </b>
                                                                    .
                                                                </>
                                                            ) : null}
                                                        </>
                                                    }
                                                >
                                                    <InfoIcon color="neutral" />
                                                </CustomTooltip>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            )}
                        <FloatInput
                            // variant={editModeBuildingHeatingSystem ? "outlined" : "filled"}
                            label="Standard-Jahresnutzungsgrad"
                            size="small"
                            value={heatingSystem.degree_of_efficiency}
                            onChange={(val) =>
                                setBuildingHeatingSystemsState((cond) => {
                                    cond[[buildingHeatingSystemsStateTab]].heating_systems[
                                        heatingSystemIdx
                                    ].degree_of_efficiency = val;
                                })
                            }
                            disabled={!editModeBuildingHeatingSystem}
                            sx={{ mt: 1, mr: 1, ml: 0, width: "28ch" }}
                            validate={(val) => {
                                return true;
                            }}
                            error={errorBuildingHeatingSystemsState.degree_of_efficiency}
                            setError={(val) => {
                                setErrorBuildingHeatingSystemsState((err) => {
                                    err.degree_of_efficiency = val;
                                });
                            }}
                        />
                        <FloatInput
                            // variant={editModeBuildingHeatingSystem ? "outlined" : "filled"}
                            label="Abweichender Jahresnutzungsgrad"
                            size="small"
                            value={
                                buildingHeatingSystemsState[[buildingHeatingSystemsStateTab]]
                                    .individual_degrees_of_efficiency[heatingSystem.id]
                            }
                            onChange={(val) =>
                                setBuildingHeatingSystemsState((cond) => {
                                    cond[[buildingHeatingSystemsStateTab]].individual_degrees_of_efficiency[
                                        heatingSystem.id
                                    ] = val;
                                })
                            }
                            disabled={!editModeBuildingHeatingSystem}
                            sx={{ mt: 1, mr: 1, ml: 0, width: "33ch" }}
                            validate={(val) => {
                                return true;
                            }}
                            error={errorBuildingHeatingSystemsState.individual_degrees_of_efficiency}
                            setError={(val) => {
                                setErrorBuildingHeatingSystemsState((err) => {
                                    err.individual_degrees_of_efficiency = val;
                                });
                            }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <CustomTooltip title="Falls in einem bestimmten Zeitraum der Jahresnutzungsgrad der Anlage vom Standard-Jahresnutzungsgrad abweicht, kann dies hier angegeben werden. Der abweichende Wert wird für alle Jahresprognosen einbezogen, die auf diesem Wärmeversorgungsanlagen-Zustand basieren.">
                                            <InfoIcon color="neutral" />
                                        </CustomTooltip>
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <BasicTextField
                            // variant={editModeBuildingHeatingSystem ? "outlined" : "filled"}
                            label="Anmerkung zur Wärmeversorgungsanlage"
                            value={heatingSystem.note}
                            onChange={(e) =>
                                setBuildingHeatingSystemsState((cond) => {
                                    cond[[buildingHeatingSystemsStateTab]].heating_systems[heatingSystemIdx].note =
                                        e.target.value;
                                })
                            }
                            disabled={!editModeBuildingHeatingSystem}
                            multiline
                            rows={2}
                            sx={{ mt: 1, mr: 1, ml: 0, width: "62ch" }}
                        />
                    </div>
                </CustomAccordionDetails>
                <CustomAccordionActions>
                    {!editModeBuildingHeatingSystem && (
                        <>
                            {buildingHeatingSystemsState
                                .map((item) => item.heating_systems.length)
                                .reduce((a, b) => a + b) === 1 && (
                                <CustomTooltip title="Für die Berechnung von Prognosedaten ist mindestens eine Wärmeversorgungsanlage notwendig. Daher kann diese Wärmeversorgungsanlage nicht gelöscht werden. Falls Sie sie ersetzen wollen, legen Sie bitte zuerst eine neue Anlage an und löschen anschließend die nicht mehr gewünschte Anlage.">
                                    <InfoIcon color="default" sx={{ fontSize: 30, ml: 2, mr: -4.3, zIndex: 100 }} />
                                </CustomTooltip>
                            )}
                            <Fab
                                variant="extended"
                                size="medium"
                                disabled={
                                    editModeBuildingHeatingSystem ||
                                    buildingHeatingSystemsState
                                        .map((item) => item.heating_systems.length)
                                        .reduce((a, b) => a + b) === 1
                                }
                                onClick={(e) => {
                                    handleOpenDeleteConfirmationModal();
                                }}
                                sx={{ ml: 2, zIndex: 0, boxShadow: 1, borderRadius: 1 }}
                            >
                                <DeleteIcon />
                                Wärmeversorgungsanlage entfernen
                            </Fab>
                        </>
                    )}
                    <Fab
                        variant="extended"
                        size="medium"
                        color={editModeBuildingHeatingSystem ? "default" : "secondary"}
                        onClick={() => {
                            handleEditMode();
                        }}
                        sx={{ zIndex: 0, mr: 1, boxShadow: 1, borderRadius: 1 }}
                    >
                        {editModeBuildingHeatingSystem ? <></> : <EditIcon sx={{ mr: 1 }} />}
                        {editModeBuildingHeatingSystem ? "Abbrechen" : "Bearbeiten"}
                    </Fab>
                    {editModeBuildingHeatingSystem && (
                        <Fab
                            disabled={
                                (Object.keys(errorBuildingHeatingSystemsState).length > 0 &&
                                    !Object.values(errorBuildingHeatingSystemsState).every((item) => item === false)) ||
                                heatingSystem.energy_source === "-" ||
                                heatingSystem.year === "" ||
                                heatingSystem.heating_system_type === "" ||
                                // heatingSystem.consumption_type === "" ||
                                heatingSystem.degree_of_efficiency.real === null ||
                                heatingSystem.degree_of_efficiency.real === ""
                            }
                            variant="extended"
                            size="medium"
                            color={editModeBuildingHeatingSystem ? "secondary" : "default"}
                            onClick={() =>
                                handleSave({
                                    heatingSystem: heatingSystem,
                                    heatingSystemIdx: heatingSystemIdx,
                                    newHeatingSystem: false,
                                })
                            }
                            sx={{ zIndex: 0, mr: 1, boxShadow: 1, borderRadius: 1 }}
                        >
                            <SaveIcon sx={{ mr: 1 }} />
                            Speichern
                        </Fab>
                    )}
                </CustomAccordionActions>
            </CustomAccordion>
        </>
    );
};

import axios from "axios";

var backendBaseUrl = "https://" + process.env.REACT_APP_ENERGYARK_BACKEND_URL + "/api";
if (!process.env.REACT_APP_ENERGYARK_BACKEND_URL.includes("advisore")) {
    // on dev backend is only reachable via http
    backendBaseUrl = "http://" + process.env.REACT_APP_ENERGYARK_BACKEND_URL + "/api";
}

/**
 * Axios instance configured for API requests to EARK backend.
 *
 * Usage:
 * - Import this instance in modules where API requests are made.
 * - Use it to send GET, POST, PUT, DELETE, etc., requests to the backend.
 *
 * Example:
 *   - import api_client from './http-common';
 *   - api_client.get('/some-endpoint/').then(response => console.log(response.data));
 */

const api_client = axios.create({
    baseURL: backendBaseUrl,
    headers: {
        "Content-type": "application/json",
    },
});
api_client.defaults.withCredentials = true;
api_client.defaults.withXSRFToken = true;
api_client.defaults.xsrfCookieName = "csrftoken";
api_client.defaults.xsrfHeaderName = "X-CSRFToken";
export default api_client;

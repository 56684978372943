import api_client from "./http-common";

class Logger {
    constructor() {
        this.log = (LogLevel, message, details) => {
            api_client
                .post("logging/", {
                    short_message: message,
                    level: LogLevel,
                    _details: details,
                })
                .then((response) => {
                    console.log(response);
                })
                .catch((error) => {
                    console.log(error);
                });
        };
    }
}
const logger = new Logger();
export default logger;

const Loglevel = {
    DEBUG: 7,
    INFO: 6,
    NOTICE: 5,
    WARNING: 4,
    ERROR: 3,
    CRITICAL: 2,
    ALERT: 1,
    EMERGENCY: 0,
};
export { Loglevel };

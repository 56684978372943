/* eslint-disable react-hooks/exhaustive-deps */
import {
    Typography,
    Box,
    Grid,
    InputAdornment,
    RadioGroup,
    FormControl,
    FormControlLabel,
    Checkbox,
    Radio,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { useTheme } from "@mui/material/styles";
import { FloatInput } from "../../../components/InputFields";
import { CustomTooltip } from "../../../components/CustomTooltip";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";

export default function BuildingComponentParameterAccordion({
    buildingCondition,
    setBuildingCondition,
    buildingConditionTab,
    errorBuildingComponentParameters,
    setErrorBuildingComponentParameters,
    editModeBuildingConditions,
}) {
    const theme = useTheme();

    const componentParametersDisplay = (componentParameters, component) => {
        // get the sum of the areas of a component
        const getComponentAreaRelationSum = (component) => {
            for (
                let index = 0;
                index < buildingCondition[buildingConditionTab].component_parameters[component].length;
                index++
            ) {
                const parameter = buildingCondition[buildingConditionTab].component_parameters[component][index];
                if (parameter.area_separator === true) {
                    return parameter.choices.reduce((acc, val) => acc + val.value.real, 0);
                }
            }
            return 0;
        };

        return (
            <>
                {/* map the component parameters and switch-case on the value_type field */}
                {componentParameters.map((parameter, parameterIndex) => {
                    switch (parameter.value_type) {
                        case "singlevalue": // fallthrough as same case as multivalue
                        case "multivalue":
                            if (parameter.area_separator) {
                                return (
                                    <Box
                                        width="100%"
                                        sx={{ margin: 1, padding: 2, backgroundColor: "#F6F6F6" }}
                                        key={"parameter-" + parameterIndex}
                                    >
                                        <Typography variant="h5" sx={{ mb: 1.5 }}>
                                            {parameter.name}{" "}
                                            <CustomTooltip title={parameter.description}>
                                                <InfoIcon sx={{ fontSize: 20, ml: 0.3, mb: -0.6 }} color="neutral" />
                                            </CustomTooltip>
                                        </Typography>
                                        {parameter.choices.map((choice, choiceIndex) => {
                                            return (
                                                <Box sx={{ mb: 2 }} key={"parameter-choice-" + choiceIndex}>
                                                    <Grid container>
                                                        <FloatInput
                                                            disabled={
                                                                !editModeBuildingConditions ||
                                                                parameter.choices.length === 1
                                                            }
                                                            size="small"
                                                            key={toString(parameterIndex) + toString(choiceIndex)}
                                                            label={choice.name}
                                                            value={choice.value}
                                                            onChange={(val) => {
                                                                setBuildingCondition((cond) => {
                                                                    cond[[buildingConditionTab]].component_parameters[
                                                                        component
                                                                    ][parameterIndex].choices[choiceIndex].value = val;
                                                                    cond[[buildingConditionTab]].component_parameters[
                                                                        component
                                                                    ][parameterIndex].choices[
                                                                        choiceIndex
                                                                    ].calculated_area.display = "?";
                                                                });
                                                            }}
                                                            InputProps={{
                                                                endAdornment: (
                                                                    <InputAdornment position="end">
                                                                        {parameter.unit}
                                                                    </InputAdornment>
                                                                ),
                                                            }}
                                                            validate={(value) => {
                                                                if (value < 0 || value > 100) {
                                                                    return false;
                                                                } else {
                                                                    // calculate the area relation
                                                                    if (
                                                                        getComponentAreaRelationSum(component) !== 100
                                                                    ) {
                                                                        // set all choices to error state
                                                                        setErrorBuildingComponentParameters((error) => {
                                                                            if (!(component in error)) {
                                                                                error[component] = {
                                                                                    [parameterIndex]: {},
                                                                                };
                                                                            }
                                                                            if (!(parameterIndex in error[component])) {
                                                                                error[component][parameterIndex] = {};
                                                                            }
                                                                            parameter.choices.forEach(
                                                                                (choice, choiceIndex) => {
                                                                                    error[component][parameterIndex][
                                                                                        choiceIndex
                                                                                    ] = true;
                                                                                }
                                                                            );
                                                                        });
                                                                        return false;
                                                                    } else {
                                                                        // set all choices to OK state
                                                                        setErrorBuildingComponentParameters((error) => {
                                                                            if (!(component in error)) {
                                                                                error[component] = {
                                                                                    [parameterIndex]: {},
                                                                                };
                                                                            }
                                                                            if (!(parameterIndex in error[component])) {
                                                                                error[component][parameterIndex] = {};
                                                                            }
                                                                            parameter.choices.forEach(
                                                                                (choice, choiceIndex) => {
                                                                                    error[component][parameterIndex][
                                                                                        choiceIndex
                                                                                    ] = false;
                                                                                }
                                                                            );
                                                                        });
                                                                        return true;
                                                                    }
                                                                }
                                                            }}
                                                            error={
                                                                component in errorBuildingComponentParameters &&
                                                                parameterIndex in
                                                                    errorBuildingComponentParameters[component]
                                                                    ? errorBuildingComponentParameters[component][
                                                                          parameterIndex
                                                                      ][choiceIndex]
                                                                    : false
                                                            }
                                                            setError={(val) => {
                                                                setErrorBuildingComponentParameters((error) => {
                                                                    if (!(component in error)) {
                                                                        error[component] = {
                                                                            [parameterIndex]: {
                                                                                [choiceIndex]: val,
                                                                            },
                                                                        };
                                                                    }
                                                                    if (!(parameterIndex in error[component])) {
                                                                        error[component][parameterIndex] = {
                                                                            [choiceIndex]: val,
                                                                        };
                                                                    } else {
                                                                        error[component][parameterIndex][choiceIndex] =
                                                                            val;
                                                                    }
                                                                });
                                                            }}
                                                            sx={{ mr: 1, width: "15ch" }}
                                                        />
                                                        {/* ------------ Display the calculated area ------------ */}

                                                        <ArrowRightAltIcon sx={{ fontSize: 30, mt: 0.3, ml: 1 }} />
                                                        <Box width="18ch">
                                                            <Typography variant="h6" sx={{ mt: 0.8, ml: 1 }}>
                                                                {editModeBuildingConditions
                                                                    ? "?"
                                                                    : parameter.choices[choiceIndex].calculated_area
                                                                          .display}{" "}
                                                                m²
                                                                {parameter.choices[choiceIndex].calculated_area
                                                                    .display === "?" || editModeBuildingConditions ? (
                                                                    <CustomTooltip title="Der berechnete Wert kann erst nach dem Speichern der Angaben angezeigt werden.">
                                                                        <InfoIcon
                                                                            sx={{ fontSize: 20, ml: 0.6, mb: -0.7 }}
                                                                            color="neutral"
                                                                        />
                                                                    </CustomTooltip>
                                                                ) : null}
                                                            </Typography>
                                                        </Box>
                                                        {/* ------------ User input area ------------ */}
                                                        <Box
                                                            // align the user input area to the right
                                                            sx={{
                                                                display: "flex",
                                                                justifyContent: "flex-end",
                                                                width: "16ch",
                                                            }}
                                                        >
                                                            <FloatInput
                                                                disabled={!editModeBuildingConditions}
                                                                size="small"
                                                                key={
                                                                    toString(parameterIndex) +
                                                                    toString(choiceIndex) +
                                                                    "user_input_area"
                                                                }
                                                                label={"Manuelle Eingabe"}
                                                                value={choice.user_input_area}
                                                                onChange={(val) => {
                                                                    setBuildingCondition((cond) => {
                                                                        cond[
                                                                            [buildingConditionTab]
                                                                        ].component_parameters[component][
                                                                            parameterIndex
                                                                        ].choices[choiceIndex].user_input_area = val;
                                                                    });
                                                                }}
                                                                InputProps={{
                                                                    endAdornment: (
                                                                        <InputAdornment position="end">
                                                                            m²
                                                                        </InputAdornment>
                                                                    ),
                                                                }}
                                                                validate={(value) => {
                                                                    if (value < 0) {
                                                                        return false;
                                                                    } else {
                                                                        return true;
                                                                    }
                                                                }}
                                                                error={
                                                                    component in errorBuildingComponentParameters &&
                                                                    parameterIndex in
                                                                        errorBuildingComponentParameters[component]
                                                                        ? errorBuildingComponentParameters[component][
                                                                              parameterIndex
                                                                          ][choiceIndex + "user_input_area"]
                                                                        : false
                                                                }
                                                                setError={(val) => {
                                                                    setErrorBuildingComponentParameters((error) => {
                                                                        if (!(component in error)) {
                                                                            error[component] = {
                                                                                [parameterIndex]: {
                                                                                    [choiceIndex + "user_input_area"]:
                                                                                        val,
                                                                                },
                                                                            };
                                                                        }
                                                                        if (!(parameterIndex in error[component])) {
                                                                            error[component][parameterIndex] = {
                                                                                [choiceIndex + "user_input_area"]: val,
                                                                            };
                                                                        } else {
                                                                            error[component][parameterIndex][
                                                                                choiceIndex + "user_input_area"
                                                                            ] = val;
                                                                        }
                                                                    });
                                                                }}
                                                                sx={{ ml: 2, width: "15ch" }}
                                                            />
                                                        </Box>
                                                    </Grid>
                                                </Box>
                                            );
                                        })}
                                    </Box>
                                );
                            } else {
                                return (
                                    <Box
                                        width="100%"
                                        sx={{ margin: 1, padding: 2, backgroundColor: "#F6F6F6" }}
                                        key={"parameter-" + parameterIndex}
                                    >
                                        <Typography variant="h5" sx={{ mb: 1.5 }}>
                                            {parameter.name}{" "}
                                            <CustomTooltip title={parameter.description}>
                                                <InfoIcon sx={{ fontSize: 20, ml: 0.3, mb: -0.6 }} color="neutral" />
                                            </CustomTooltip>
                                        </Typography>
                                        {parameter.choices.map((choice, choiceIndex) => {
                                            return (
                                                <FloatInput
                                                    disabled={!editModeBuildingConditions}
                                                    size="small"
                                                    key={toString(parameterIndex) + toString(choiceIndex)}
                                                    label={choice.name}
                                                    value={choice.value}
                                                    onChange={(val) => {
                                                        setBuildingCondition((cond) => {
                                                            cond[[buildingConditionTab]].component_parameters[
                                                                component
                                                            ][parameterIndex].choices[choiceIndex].value = val;
                                                        });
                                                    }}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                {parameter.unit}
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                    validate={(value) => {
                                                        if (value < 0 || value > 100) {
                                                            return false;
                                                        } else {
                                                            return true;
                                                        }
                                                    }}
                                                    error={
                                                        component in errorBuildingComponentParameters &&
                                                        parameterIndex in errorBuildingComponentParameters[component]
                                                            ? errorBuildingComponentParameters[component][
                                                                  parameterIndex
                                                              ][choiceIndex]
                                                            : false
                                                    }
                                                    setError={(val) => {
                                                        setErrorBuildingComponentParameters((error) => {
                                                            if (!(component in error)) {
                                                                error[component] = {
                                                                    [parameterIndex]: { [choiceIndex]: val },
                                                                };
                                                            }
                                                            if (!(parameterIndex in error[component])) {
                                                                error[component][parameterIndex] = {
                                                                    [choiceIndex]: val,
                                                                };
                                                            } else {
                                                                error[component][parameterIndex][choiceIndex] = val;
                                                            }
                                                        });
                                                    }}
                                                    sx={{ mr: 1 }}
                                                />
                                            );
                                        })}
                                    </Box>
                                );
                            }
                        case "singlechoice":
                            return (
                                <Box
                                    width="100%"
                                    sx={{ margin: 1, padding: 2, backgroundColor: "#F6F6F6" }}
                                    key={"parameter-" + parameterIndex}
                                >
                                    <Typography variant="h5" sx={{ mb: 1.5 }}>
                                        {parameter.name}{" "}
                                        <CustomTooltip title={parameter.description}>
                                            <InfoIcon sx={{ fontSize: 20, ml: 0.3, mb: -0.6 }} color="neutral" />
                                        </CustomTooltip>
                                    </Typography>
                                    <FormControl component="fieldset">
                                        <RadioGroup
                                            row
                                            aria-labelledby={"parameter-" + parameter.id + "-radio-buttons-group-label"}
                                            name={"parameter-" + parameter.id + "-radio-buttons-group"}
                                            onChange={(event) => {
                                                setBuildingCondition((cond) => {
                                                    for (let i = 0; i < parameter.choices.length; i++) {
                                                        cond[[buildingConditionTab]].component_parameters[component][
                                                            parameterIndex
                                                        ].choices[i].value.real = 0;
                                                    }
                                                    cond[[buildingConditionTab]].component_parameters[component][
                                                        parameterIndex
                                                    ].choices[event.target.value].value.real = 1;
                                                });
                                            }}
                                            sx={{ mt: -0.5, ml: 3 }}
                                        >
                                            {parameter.choices.map((choice, choiceIndex) => {
                                                return (
                                                    <FormControlLabel
                                                        key={"parameter-choice-radio-" + choiceIndex}
                                                        disabled={!editModeBuildingConditions}
                                                        value={choiceIndex}
                                                        control={<Radio />}
                                                        label={choice.name}
                                                        checked={choice.value.real === 1}
                                                    />
                                                );
                                            })}
                                        </RadioGroup>
                                    </FormControl>
                                </Box>
                            );
                        case "multichoice":
                            return (
                                <Box
                                    width="100%"
                                    sx={{ margin: 1, padding: 2, backgroundColor: "#F6F6F6" }}
                                    key={"parameter-" + parameterIndex}
                                >
                                    <Typography variant="h5" sx={{ mb: 1.5 }}>
                                        {parameter.name}{" "}
                                        <CustomTooltip title={parameter.description}>
                                            <InfoIcon sx={{ fontSize: 20, ml: 0.3, mb: -0.6 }} color="neutral" />
                                        </CustomTooltip>
                                    </Typography>
                                    <FormControl component="fieldset" sx={{ mt: -0.5, ml: 3 }}>
                                        <Grid container>
                                            {parameter.choices.map((choice, choiceIndex) => {
                                                return (
                                                    <FormControlLabel
                                                        value={choiceIndex}
                                                        control={
                                                            <Checkbox
                                                                disabled={!editModeBuildingConditions}
                                                                onChange={(event) =>
                                                                    setBuildingCondition((cond) => {
                                                                        cond[
                                                                            [buildingConditionTab]
                                                                        ].component_parameters[component][
                                                                            parameterIndex
                                                                        ].choices[choiceIndex].value.real = event.target
                                                                            .checked
                                                                            ? 1
                                                                            : 0;
                                                                    })
                                                                }
                                                            />
                                                        }
                                                        label={choice.name}
                                                        checked={choice.value.real === 1}
                                                    />
                                                );
                                            })}
                                        </Grid>
                                    </FormControl>
                                </Box>
                            );
                        default:
                            return null;
                    }
                })}
            </>
        );
    };

    return (
        <>
            {/* Display the component parameters for the different building components */}
            <Grid container>
                {/* ----- Fassade ------ */}
                <Box
                    width="65ch"
                    sx={{
                        ml: 2,
                        mt: 2,
                        border: "lightgray",
                        borderStyle: "solid",
                        borderWidth: "1px",
                        borderRadius: "5px",
                    }}
                >
                    <Box
                        sx={{
                            width: "100%",
                            backgroundColor:
                                theme.palette.mode === "dark" ? "rgba(255, 255, 255, .05)" : "rgba(0, 0, 0, .03)",
                        }}
                    >
                        <Typography sx={{ padding: 1, fontSize: 18, fontWeight: "bold" }}>Fassade</Typography>
                    </Box>
                    <Grid container spacing={2} sx={{ padding: 2 }}>
                        {componentParametersDisplay(
                            buildingCondition[buildingConditionTab].component_parameters.hull,
                            "hull"
                        )}
                    </Grid>
                </Box>
                {/* ----------------- */}
                {/* <Divider orientation="vertical" flexItem sx={{ mt: 5 }} /> */}
                {/* ----- Dach ------ */}
                <Box
                    width="65ch"
                    sx={{
                        ml: 2,
                        mt: 2,
                        border: "lightgray",
                        borderStyle: "solid",
                        borderWidth: "1px",
                        borderRadius: "5px",
                    }}
                >
                    <Box
                        sx={{
                            width: "100%",
                            backgroundColor:
                                theme.palette.mode === "dark" ? "rgba(255, 255, 255, .05)" : "rgba(0, 0, 0, .03)",
                        }}
                    >
                        <Typography sx={{ padding: 1, fontSize: 18, fontWeight: "bold" }}>Dach</Typography>
                    </Box>
                    <Grid container spacing={2} sx={{ padding: 2 }}>
                        {componentParametersDisplay(
                            buildingCondition[buildingConditionTab].component_parameters.roof,
                            "roof"
                        )}
                    </Grid>
                </Box>

                {/* ----- Fenster ------ */}
                <Box
                    width="65ch"
                    sx={{
                        ml: 2,
                        mt: 2,
                        border: "lightgray",
                        borderStyle: "solid",
                        borderWidth: "1px",
                        borderRadius: "5px",
                    }}
                >
                    <Box
                        sx={{
                            width: "100%",
                            backgroundColor:
                                theme.palette.mode === "dark" ? "rgba(255, 255, 255, .05)" : "rgba(0, 0, 0, .03)",
                        }}
                    >
                        <Typography sx={{ padding: 1, fontSize: 18, fontWeight: "bold" }}>Fenster</Typography>
                    </Box>
                    <Grid container spacing={2} sx={{ padding: 2 }}>
                        {componentParametersDisplay(
                            buildingCondition[buildingConditionTab].component_parameters.windows,
                            "windows"
                        )}
                    </Grid>
                </Box>

                {/* ----- Keller(-decke) ------ */}
                <Box
                    width="65ch"
                    sx={{
                        ml: 2,
                        mt: 2,
                        border: "lightgray",
                        borderStyle: "solid",
                        borderWidth: "1px",
                        borderRadius: "5px",
                    }}
                >
                    <Box
                        sx={{
                            width: "100%",
                            backgroundColor:
                                theme.palette.mode === "dark" ? "rgba(255, 255, 255, .05)" : "rgba(0, 0, 0, .03)",
                        }}
                    >
                        <Typography sx={{ padding: 1, fontSize: 18, fontWeight: "bold" }}>Keller(-decke)</Typography>
                    </Box>
                    <Grid container spacing={2} sx={{ padding: 2 }}>
                        {componentParametersDisplay(
                            buildingCondition[buildingConditionTab].component_parameters.cellarroof,
                            "cellarroof"
                        )}
                    </Grid>
                </Box>
            </Grid>
        </>
    );
}

import { createContext, useState } from "react";

const ResultTableContext = createContext();

export const ResultTableProvider = ({ children }) => {
    const [filteredBuildings, setFilteredBuildings] = useState([]);

    return (
        <ResultTableContext.Provider value={{ filteredBuildings, setFilteredBuildings }}>
            {children}
        </ResultTableContext.Provider>
    );
};

export default ResultTableContext;

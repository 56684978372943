import { DataGrid, GRID_STRING_COL_DEF } from "@mui/x-data-grid";
import { React, useState } from "react";
import { Box, IconButton } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useImmer } from "use-immer";
import api_client from "../../services/http-common";
import { useContext } from "react";
import ToastContext from "../../contexts/ToastContext";
import { darken, lighten, styled } from "@mui/material/styles";
import { createGermanNumberString } from "../../utils/formatting";
import {
    HighlightedWallHouseIcon,
    HighlightedWindowHouseIcon,
    HighlightedRoofHouseIcon,
    HighlightedBasementHouseIcon,
} from "../../components/Icons/BuildingComponents";

const getBackgroundColor = (color, theme, coefficient) => ({
    backgroundColor: darken(color, coefficient),
    ...theme.applyStyles("light", {
        backgroundColor: lighten(color, coefficient),
    }),
});

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
    "& .modernizationCosts--YearOpen": {
        ...getBackgroundColor(theme.palette.grey[400], theme, 0.7),
        fontSize: "1.3em",
        "&:hover": {
            ...getBackgroundColor(theme.palette.grey[500], theme, 0.6),
        },
        "&.Mui-selected": {
            ...getBackgroundColor(theme.palette.grey[400], theme, 0.7),
            "&:hover": {
                ...getBackgroundColor(theme.palette.grey[500], theme, 0.6),
            },
        },
    },
    "& .modernizationCosts--YearClosed": {
        ...getBackgroundColor(theme.palette.grey[400], theme, 0.7),
        fontSize: "1.3em",
        "&:hover": {
            ...getBackgroundColor(theme.palette.grey[500], theme, 0.6),
        },
        "&.Mui-selected": {
            ...getBackgroundColor(theme.palette.grey[400], theme, 0.7),
            "&:hover": {
                ...getBackgroundColor(theme.palette.grey[500], theme, 0.6),
            },
        },
    },
    "& .modernizationCosts--Building": {
        ...getBackgroundColor(theme.palette.background.default, theme, 0.7),
        "&:hover": {
            ...getBackgroundColor(theme.palette.background.default, theme, 0.6),
        },
        "&.Mui-selected": {
            ...getBackgroundColor(theme.palette.background.default, theme, 0.7),
            "&:hover": {
                ...getBackgroundColor(theme.palette.background.default, theme, 0.6),
            },
        },
    },
}));

const ModernizationCostsTable = ({ data }) => {
    const { addToastMessage } = useContext(ToastContext);

    const [open, setOpen] = useImmer({});

    const [rowData, setRowData] = useImmer(data ? getModernizationCostTableData(data) : []);

    const [yearRowData, setYearRowData] = useImmer({});

    const [fetchingData, setFetchingData] = useState(false);

    // fetch modernization costs for a specific year and add it to the row data and save it additionally in yearRowData (as cache)
    const fetchYearData = async (year) => {
        if (yearRowData[year]) {
            setRowData((draft) => {
                draft.push(...yearRowData[year]);
                draft.sort((a, b) => a.year - b.year);
            });
            return;
        } else {
            setFetchingData(true);
            api_client
                .get(`/user-results/modernization-costs/${year}`)
                .then((response) => {
                    if (response.status === 200) {
                        const yearData = response.data.data.modernization_costs;
                        const fetchedYearRowData = getModernizationCostTableData(yearData);
                        setRowData((draft) => {
                            draft.push(...fetchedYearRowData);
                            draft.sort((a, b) => a.year - b.year);
                        });
                        setYearRowData((draft) => {
                            draft[year] = fetchedYearRowData;
                        });
                    } else {
                        addToastMessage(
                            "Beim Beziehen der Modernisierungsdaten ist ein Fehler aufgetreten, bitte versuchen Sie es erneut.",
                            "error",
                            true,
                            6000,
                            "toast-load-modernization-costs-error"
                        );
                    }
                    setFetchingData(false);
                })
                .catch((error) => {
                    addToastMessage(
                        "Beim Beziehen der Modernisierungsdaten ist ein Fehler aufgetreten, bitte versuchen Sie es erneut.",
                        "error",
                        true,
                        6000,
                        "toast-load-modernization-costs-error"
                    );
                    setFetchingData(false);
                });
        }
    };

    const collapseButtonColumnType = {
        ...GRID_STRING_COL_DEF,
        type: "custom",
        resizable: false,
        filterable: false,
        sortable: false,
        editable: false,
        groupable: false,
        hideable: false,
        display: "flex",
        renderCell: (params) =>
            params.value.year ? (
                <IconButton
                    aria-label="expand row"
                    size="small"
                    onClick={() => {
                        if (!open[params.value.year]) {
                            fetchYearData(params.value.year);
                        } else {
                            setRowData(rowData.filter((row) => row.id.slice(0, 4) !== params.value.year.toString()));
                        }
                        setOpen((open) => {
                            open[params.value.year] = !open[params.value.year];
                        });
                    }}
                >
                    {open[params.value.year] ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>
            ) : (
                <></>
            ),
    };

    const modernizationIconColumnType = {
        ...GRID_STRING_COL_DEF,
        type: "custom",
        resizable: false,
        filterable: false,
        sortable: false,
        editable: false,
        groupable: false,
        display: "flex",
        renderCell: (params) => getModernizedIcons(params.value),
    };

    function getModernizedIcons(modernized) {
        if (modernized === undefined) {
            return <></>;
        }
        return (
            <>
                {modernized.hull ? <HighlightedWallHouseIcon /> : <></>}
                {modernized.roof ? <HighlightedRoofHouseIcon /> : <></>}
                {modernized.windows ? <HighlightedWindowHouseIcon /> : <></>}
                {modernized.cellarroof ? <HighlightedBasementHouseIcon /> : <></>}
            </>
        );
    }

    function getModernizationCostTableData(modernizationCosts) {
        var dataArray = [];
        modernizationCosts.forEach((data, idx) => {
            dataArray.push({
                id: data.building ? data.year.toString() + idx.toString() : idx.toString(),
                collapseButton: data.building ? {} : { year: data.year },
                year: data.year, // maybe only show year for aggregate entries to get "tree structure"?
                building: data.building ? data.building : "",
                modernized: data.modernized,
                estimated_total_cost: data.estimation
                    ? createGermanNumberString(data.estimation.total_cost, true, 0) + " €"
                    : "N/V",
                estimated_total_cost_per_sqm: data.estimation
                    ? createGermanNumberString(data.estimation.total_cost_per_sqm, true, 0) + " €"
                    : "N/V",
                estimated_cost_hull: data.estimation
                    ? createGermanNumberString(data.estimation.cost_hull, true, 0) + " €"
                    : "N/V",
                estimated_cost_per_sqm_hull: data.estimation
                    ? createGermanNumberString(data.estimation.cost_per_sqm_hull, true, 0) + " €"
                    : "N/V",
                estimated_cost_roof: data.estimation
                    ? createGermanNumberString(data.estimation.cost_roof, true, 0) + " €"
                    : "N/V",
                estimated_cost_per_sqm_roof: data.estimation
                    ? createGermanNumberString(data.estimation.cost_per_sqm_roof, true, 0) + " €"
                    : "N/V",
                estimated_cost_windows: data.estimation
                    ? createGermanNumberString(data.estimation.cost_windows, true, 0) + " €"
                    : "N/V",
                estimated_cost_per_sqm_windows: data.estimation
                    ? createGermanNumberString(data.estimation.cost_per_sqm_windows, true, 0) + " €"
                    : "N/V",
                estimated_cost_cellarroof: data.estimation
                    ? createGermanNumberString(data.estimation.cost_cellarroof, true, 0) + " €"
                    : "N/V",
                estimated_cost_per_sqm_cellarroof: data.estimation
                    ? createGermanNumberString(data.estimation.cost_per_sqm_cellarroof, true, 0) + " €"
                    : "N/V",
                user_input_total_cost: data.user_input
                    ? createGermanNumberString(data.user_input.total_cost, true, 0) + " €"
                    : "N/V",
                user_input_total_cost_per_sqm: data.user_input
                    ? createGermanNumberString(data.user_input.total_cost_per_sqm, true, 0) + " €"
                    : "N/V",
                user_input_cost_hull: data.user_input
                    ? createGermanNumberString(data.user_input.cost_hull, true, 0) + " €"
                    : "N/V",
                user_input_cost_per_sqm_hull: data.user_input
                    ? createGermanNumberString(data.user_input.cost_per_sqm_hull, true, 0) + " €"
                    : "N/V",
                user_input_cost_roof: data.user_input
                    ? createGermanNumberString(data.user_input.cost_roof, true, 0) + " €"
                    : "N/V",
                user_input_cost_per_sqm_roof: data.user_input
                    ? createGermanNumberString(data.user_input.cost_per_sqm_roof, true, 0) + " €"
                    : "N/V",
                user_input_cost_windows: data.user_input
                    ? createGermanNumberString(data.user_input.cost_windows, true, 0) + " €"
                    : "N/V",
                user_input_cost_per_sqm_windows: data.user_input
                    ? createGermanNumberString(data.user_input.cost_per_sqm_windows, true, 0) + " €"
                    : "N/V",
                user_input_cost_cellarroof: data.user_input
                    ? createGermanNumberString(data.user_input.cost_cellarroof, true, 0) + " €"
                    : "N/V",
                user_input_cost_per_sqm_cellarroof: data.user_input
                    ? createGermanNumberString(data.user_input.cost_per_sqm_cellarroof, true, 0) + " €"
                    : "N/V",
            });
        });
        return dataArray;
    }

    const ModernizationCostTableColumns = [
        {
            field: "collapseButton",
            ...collapseButtonColumnType,
            headerName: " ",
            width: 50,
        },
        {
            field: "year",
            headerName: "Jahr",
            width: 60,
            sortable: false,
            hideable: false,
        },
        {
            field: "building",
            headerName: "Gebäude",
            width: 250,
            sortable: false,
        },
        {
            field: "modernized",
            ...modernizationIconColumnType,
            headerName: "Modernisiert",
            width: 200,
            sortable: false,
        },
        {
            field: "estimated_total_cost",
            headerName: "Geschätzte Gesamtkosten",
            width: 200,
            sortable: false,
        },
        {
            field: "estimated_total_cost_per_sqm",
            headerName: "Geschätzte Kosten pro m² (Gesamt)",
            width: 250,
            sortable: false,
        },
        {
            field: "estimated_cost_hull",
            headerName: "Geschätzte Kosten (Fassade)",
            width: 250,
            sortable: false,
        },
        {
            field: "estimated_cost_per_sqm_hull",
            headerName: "Geschätzte Kosten pro m² (Fassade)",
            width: 250,
            sortable: false,
        },
        {
            field: "estimated_cost_roof",
            headerName: "Geschätzte Kosten (Dach)",
            width: 250,
            sortable: false,
        },
        {
            field: "estimated_cost_per_sqm_roof",
            headerName: "Geschätzte Kosten pro m² (Dach)",
            width: 250,
            sortable: false,
        },
        {
            field: "estimated_cost_windows",
            headerName: "Geschätzte Kosten (Fenster)",
            width: 250,
            sortable: false,
        },
        {
            field: "estimated_cost_per_sqm_windows",
            headerName: "Geschätzte Kosten pro m² (Fenster)",
            width: 250,
            sortable: false,
        },
        {
            field: "estimated_cost_cellarroof",
            headerName: "Geschätzte Kosten (Kellerdecke)",
            width: 250,
            sortable: false,
        },
        {
            field: "estimated_cost_per_sqm_cellarroof",
            headerName: "Geschätzte Kosten pro m² (Kellerdecke)",
            width: 250,
            sortable: false,
        },
        {
            field: "user_input_total_cost",
            headerName: "Angegebene Gesamtkosten",
            width: 220,
            sortable: false,
        },
        {
            field: "user_input_total_cost_per_sqm",
            headerName: "Angegebene Kosten pro m² (Gesamt)",
            width: 250,
            sortable: false,
        },
        {
            field: "user_input_cost_hull",
            headerName: "Angegebene Kosten (Fassade)",
            width: 250,
            sortable: false,
        },
        {
            field: "user_input_cost_per_sqm_hull",
            headerName: "Angegebene Kosten pro m² (Fassade)",
            width: 250,
            sortable: false,
        },
        {
            field: "user_input_cost_roof",
            headerName: "Angegebene Kosten (Dach)",
            width: 250,
            sortable: false,
        },
        {
            field: "user_input_cost_per_sqm_roof",
            headerName: "Angegebene Kosten pro m² (Dach)",
            width: 250,
            sortable: false,
        },
        {
            field: "user_input_cost_windows",
            headerName: "Angegebene Kosten (Fenster)",
            width: 250,
            sortable: false,
        },
        {
            field: "user_input_cost_per_sqm_windows",
            headerName: "Angegebene Kosten pro m² (Fenster)",
            width: 250,
            sortable: false,
        },
        {
            field: "user_input_cost_cellarroof",
            headerName: "Angegebene Kosten (Kellerdecke)",
            width: 250,
            sortable: false,
        },
        {
            field: "user_input_cost_per_sqm_cellarroof",
            headerName: "Angegebene Kosten pro m² (Kellerdecke)",
            width: 250,
            sortable: false,
        },
    ];

    const InitialColumnVisibility = {
        collapseButton: true,
        year: true,
        building: true,
        modernized: true,
        estimated_total_cost: true,
        estimated_total_cost_per_sqm: true,
        estimated_cost_hull: false,
        estimated_cost_per_sqm_hull: false,
        estimated_cost_roof: false,
        estimated_cost_per_sqm_roof: false,
        estimated_cost_windows: false,
        estimated_cost_per_sqm_windows: false,
        estimated_cost_cellarroof: false,
        estimated_cost_per_sqm_cellarroof: false,
        user_input_total_cost: true,
        user_input_total_cost_per_sqm: true,
        user_input_cost_hull: false,
        user_input_cost_per_sqm_hull: false,
        user_input_cost_roof: false,
        user_input_cost_per_sqm_roof: false,
        user_input_cost_windows: false,
        user_input_cost_per_sqm_windows: false,
        user_input_cost_cellarroof: false,
        user_input_cost_per_sqm_cellarroof: false,
    };

    return (
        <Box style={{ maxHeight: 1000, width: "100%" }}>
            <StyledDataGrid
                loading={fetchingData}
                rows={rowData}
                columns={ModernizationCostTableColumns}
                disableSelectionOnClick
                initialState={{
                    sorting: [
                        { field: "year", sort: "asc" },
                        { field: "building", sort: "asc" },
                    ],
                    columns: {
                        columnVisibilityModel: InitialColumnVisibility,
                    },
                }}
                getRowClassName={(params) =>
                    `modernizationCosts--${
                        params.row.collapseButton.year
                            ? open[params.row.year]
                                ? "YearOpen"
                                : "YearClosed"
                            : "Building"
                    }`
                }
                slotProps={{
                    loadingOverlay: {
                        variant: "circular-progress",
                        noRowsVariant: "skeleton",
                    },
                }}
            />
        </Box>
    );
};

export default ModernizationCostsTable;

import { createContext, useState } from "react";

const EnergyEfficiencyClassesContext = createContext();

/**
 * Context provider for managing energy efficiency classes data across the application.
 *
 * This provider component encapsulates the logic for storing and updating energy efficiency classes data.
 * It uses React's Context API to allow child components to consume and modify this data as needed.
 *
 * Props:
 *   children (ReactNode): Child components that can consume the context.
 *
 * Usage:
 * Wrap your component tree with `EnergyEfficiencyClassesProvider` to provide access to the energy efficiency
 * classes context throughout your application.
 *
 * Example:
 * ```
 * <EnergyEfficiencyClassesProvider>
 *   <YourComponent />
 * </EnergyEfficiencyClassesProvider>
 * ```
 *
 * Returns:
 *   ReactNode: A context provider component that passes down energy efficiency classes data and setters to its children.
 */
export const EnergyEfficiencyClassesProvider = ({ children }) => {
    const [energyEfficiencyClasses, setEnergyEfficiencyClasses] = useState(null);
    return (
        <EnergyEfficiencyClassesContext.Provider value={{ energyEfficiencyClasses, setEnergyEfficiencyClasses }}>
            {children}
        </EnergyEfficiencyClassesContext.Provider>
    );
};

export default EnergyEfficiencyClassesContext;

import { Modal, Box, Typography, Fab, Button } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";

const DeleteConfirmationModal = ({
    deleteConfirmationModalOpen,
    handleCloseDeleteConfirmationModal,
    confirmationText,
    deletionTargetFunction,
}) => {
    return (
        <>
            <Modal
                open={deleteConfirmationModalOpen}
                onClose={handleCloseDeleteConfirmationModal}
                aria-labelledby="delete-confirmation-modal"
                // onKeyUp={(e) => {
                //     if (e.key === "Enter") {
                //         deletionTargetFunction();
                //     }
                // }}
            >
                <Box
                    sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        minWidth: 535,
                        maxWidth: 735,
                        bgcolor: "background.paper",
                        border: "2px solid #000",
                        boxShadow: 24,
                        p: 4,
                    }}
                >
                    <Button
                        sx={{
                            position: "absolute",
                            top: "10px",
                            right: "5px",
                        }}
                        onClick={() => handleCloseDeleteConfirmationModal()}
                    >
                        <CloseIcon />
                    </Button>
                    <Typography id="modal-delete-confirmation-title" variant="h6" component="h2" sx={{ mb: 1 }}>
                        {confirmationText}
                    </Typography>
                    <Fab
                        variant="extended"
                        size="medium"
                        onClick={(e) => handleCloseDeleteConfirmationModal()}
                        color="secondary"
                        sx={{ mt: 0.8, ml: "15%", width: "30%", zIndex: 0, boxShadow: 1, borderRadius: 1 }}
                        data-testid="delete-cancel-button"
                    >
                        Abbrechen
                    </Fab>
                    <Fab
                        variant="extended"
                        size="medium"
                        onClick={(e) => deletionTargetFunction()}
                        color="red"
                        sx={{ mt: 0.8, ml: "10%", width: "30%", zIndex: 0, boxShadow: 1, borderRadius: 1 }}
                        data-testid="delete-confirmation-button"
                    >
                        <DeleteIcon /> Löschen
                    </Fab>
                </Box>
            </Modal>
        </>
    );
};
export default DeleteConfirmationModal;

/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useCallback, useContext, useEffect } from "react";
import { useImmer } from "use-immer";
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography,
    Box,
    Tabs,
    Tab,
    Fab,
    Modal,
    InputAdornment,
    Tooltip,
    Alert,
    Divider,
    Button,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import api_client from "../../services/http-common";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LocalFireDepartmentIcon from "@mui/icons-material/LocalFireDepartment";
import AddIcon from "@mui/icons-material/Add";
import { useTheme } from "@mui/material/styles";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import { FloatInput, BasicTextField, BasicSelectField } from "../../components/InputFields";
import { addDisplayNumbersInJsonArray, cleanNumbersInJson } from "../../utils/formatting";
import { tooltipClasses } from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete";
import ToastContext from "../../contexts/ToastContext";
import UpdateTimeStampContext from "./UpdateTimestampContext";
import DeleteConfirmationModal from "../../components/DeleteConfirmationModal";
import ReportProblemTwoToneIcon from "@mui/icons-material/ReportProblemTwoTone";
import CloseIcon from "@mui/icons-material/Close";
import { LoadingSpinner } from "../../components/LoadingSpinner";

const NumberInput = styled(BasicTextField)`
    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
        display: none;
    }
`;

const CustomTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(
    ({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            maxWidth: "220px",
            fontSize: theme.typography.pxToRem(14),
            border: "1px solid #dadde9",
        },
    })
);

/**
 * Component for displaying and managing building consumption data in an accordion format.
 *
 * This component allows users to view, add, edit, and delete consumption data for a specific building.
 * It provides interactive elements for managing consumption details, including energy sources, consumption types,
 * and efficiency. It also integrates with various contexts for data fetching and toast notifications.
 *
 * Props:
 *   currentBuilding (Object): The building object for which the consumption data are being managed.
 *
 * Returns:
 *   ReactElement: A component that renders an accordion with building consumption details and management options.
 */
export const BuildingConsumptionsAccordion = ({
    currentBuilding,
    energySources,
    consumptionTypes,
    heatingSystemTypes,
    emissionModels,
}) => {
    // -- CONTEXT -- //
    const { addToastMessage } = useContext(ToastContext);
    const { setUpdateTimestamp } = useContext(UpdateTimeStampContext);

    // -- CONSTANTS -- //
    const theme = useTheme();
    const emptyEnergySource = {
        id: 0,
        energy_source: "",
        heating_system_type: "",
        emission_model: "",
        // efficiency: { display: "", real: 0 },
        consumption: { display: "", real: 0 },
        consumption_type: "RWWW",
        unit: "kWh",
    };

    const excludeKeysInAddDisplayNumbers = ["id", "year", "heating_system_type", "emission_model"];

    // -- STATES -- //

    const [fetchingBuildingConsumptionData, setFetchingBuildingConsumptionData] = useState(false);

    // handle functions for addYearModal in building-consumptions
    const [addConsumptionYearModalOpen, setAddConsumptionYearModalOpen] = useState(false);
    const [addConsumptionYearChoice, setAddConsumptionYearChoice] = useState();
    const [addConsumptionYearValid, setAddConsumptionYearValid] = useState(false);
    const handleOpenAddConsumptionYear = () => setAddConsumptionYearModalOpen(true);
    const handleCloseAddConsumptionYear = () => setAddConsumptionYearModalOpen(false);

    // building-consumption data
    const [buildingConsumptionsTab, setBuildingConsumptionsTab] = useState(0);
    const [buildingConsumptions, setBuildingConsumptions] = useImmer([
        {
            year: 2000,
            note: "",
            consumptions: [emptyEnergySource],
        },
    ]);
    const [editModeBuildingConsumptions, setEditModeBuildingConsumptions] = useState(false);
    const [errorBuildingConsumptions, setErrorBuildingConsumptions] = useImmer({});
    const [deleteConfirmationModalOpen, setDeleteConfirmationModalOpen] = useState(false);
    const handleOpenDeleteConfirmationModal = () => setDeleteConfirmationModalOpen(true);
    const handleCloseDeleteConfirmationModal = () => setDeleteConfirmationModalOpen(false);
    const [deleteConfirmationModalText, setDeleteConfirmationModalText] = useState("");
    const [deleteBuildingConsumptionFunc, setDeleteBuildingConsumptionFunc] = useState(() => () => {});

    // handle the switching of building-consumptions year (tab)
    const handleBuildingConsumptionsTab = (event, newValue) => {
        setBuildingConsumptionsTab(newValue);
    };

    // add years (tabs) to building consumptions
    const addBuildingConsumptionsTab = () => {
        handleCloseAddConsumptionYear();
        const newTabIdx = buildingConsumptions.length;
        setBuildingConsumptions((cons) => {
            const newEl = {
                year: parseInt(addConsumptionYearChoice),
                note: "",
                consumptions: [emptyEnergySource],
            };
            cons.push(newEl);
        });
        setAddConsumptionYearChoice("");
        setAddConsumptionYearValid(false);
        setBuildingConsumptionsTab(newTabIdx);
        setEditModeBuildingConsumptions(true);
    };

    // add an energy-source to the building consumptions
    const addEnergySource = () => {
        setBuildingConsumptions((cons) => {
            cons[buildingConsumptionsTab].consumptions.push(emptyEnergySource);
        });
    };

    // handle the toggling of the edit modes
    const handleEditMode = () => {
        if (editModeBuildingConsumptions === true) {
            fetchBuildingConsumptionData().then((resp) => {
                setErrorBuildingConsumptions({});
                setEditModeBuildingConsumptions(false);
            });
        } else {
            setErrorBuildingConsumptions({});
            setEditModeBuildingConsumptions(true);
        }
    };

    // handle the saving after editing
    const handleSave = async () => {
        setEditModeBuildingConsumptions((prevEditMode) => !prevEditMode);
        setFetchingBuildingConsumptionData(true);
        var _buildingConsumption = [cleanNumbersInJson(buildingConsumptions[buildingConsumptionsTab])];
        // post building-consumptions to backend
        api_client
            .post(`/buildings/${currentBuilding.id}/consumptions-year/`, _buildingConsumption)
            .then((response) => {
                const building_consumptions_status = response.status;
                if (building_consumptions_status === 200) {
                    const data = addDisplayNumbersInJsonArray(
                        response.data.consumptions_years,
                        excludeKeysInAddDisplayNumbers
                    );
                    if (data.length > 0) {
                        setBuildingConsumptions((cons) => {
                            cons[buildingConsumptionsTab] = data[0];
                        });
                    } else {
                        setBuildingConsumptions((cons) => {
                            cons.splice(buildingConsumptionsTab, 1);
                        });
                    }
                }
                setUpdateTimestamp(Date.now());
                setFetchingBuildingConsumptionData(false);
            })
            .catch((error) => {
                addToastMessage(
                    "Beim Speichern der Verbrauchsdaten ist ein Fehler aufgetreten, bitte versuchen Sie es erneut.",
                    "error",
                    true,
                    6000,
                    "toast-save-consumption-error"
                );
                setFetchingBuildingConsumptionData(false);
            });
    };

    // delete building consumption year
    const deleteBuildingConsumptionYear = async () => {
        handleCloseDeleteConfirmationModal();
        setFetchingBuildingConsumptionData(true);
        api_client
            .delete(
                `/buildings/${currentBuilding.id}/consumptions-year/${buildingConsumptions[buildingConsumptionsTab].year}/`,
                {}
            )
            .then((response) => {
                setBuildingConsumptionsTab(
                    Math.max(0, Math.min(buildingConsumptions.length - 2, buildingConsumptionsTab))
                );
                if (response.status === 200) {
                    fetchBuildingConsumptionData();
                    setUpdateTimestamp(Date.now());
                    addToastMessage(
                        "Verbrauchsjahr erfolgreich gelöscht.",
                        "success",
                        true,
                        6000,
                        "toast-delete-consumption-year-success"
                    );
                } else {
                    addToastMessage(
                        "Beim Löschen des Verbrauchsjahres ist ein Fehler aufgetreten, bitte versuchen Sie es erneut.",
                        "error",
                        true,
                        6000,
                        "toast-delete-consumption-year-error"
                    );
                }
                setFetchingBuildingConsumptionData(false);
            });
    };

    // delete single building consumption by its ID
    const deleteBuildingConsumption = async (id) => {
        handleCloseDeleteConfirmationModal();
        setFetchingBuildingConsumptionData(true);
        api_client.delete(`/buildings/${currentBuilding.id}/consumptions/${id}/`, {}).then((response) => {
            if (response.status === 200) {
                fetchBuildingConsumptionData();
                setUpdateTimestamp(Date.now());
                addToastMessage(
                    "Verbrauchsangabe erfolgreich gelöscht.",
                    "success",
                    true,
                    6000,
                    "toast-delete-consumption-success"
                );
            } else {
                addToastMessage(
                    "Beim Löschen der Verbrauchsangabe ist ein Fehler aufgetreten, bitte versuchen Sie es erneut.",
                    "error",
                    true,
                    6000,
                    "toast-delete-consumption-error"
                );
            }
            setFetchingBuildingConsumptionData(false);
        });
    };

    // fetch building consumptions from backend
    const fetchBuildingConsumptionData = useCallback(async () => {
        setEditModeBuildingConsumptions(false);
        setFetchingBuildingConsumptionData(true);
        api_client.get(`/buildings/${currentBuilding.id}/consumptions-year/`, {}).then((response) => {
            const data = addDisplayNumbersInJsonArray(response.data.consumptions_years, excludeKeysInAddDisplayNumbers);
            // assign local state
            setBuildingConsumptions((cons) => {
                cons.splice(0, cons.length);
                data.map((val) => {
                    cons.push(val);
                    return cons;
                });
            });
            var tab = Math.max(0, Math.min(data.length - 1, buildingConsumptionsTab));
            setBuildingConsumptionsTab(tab);
            setFetchingBuildingConsumptionData(false);
        });
    }, [currentBuilding, buildingConsumptionsTab]);

    useEffect(() => {
        if (currentBuilding.id !== -1) {
            fetchBuildingConsumptionData();
        }
    }, [currentBuilding]);

    return (
        <>
            {deleteConfirmationModalOpen && (
                <DeleteConfirmationModal
                    deleteConfirmationModalOpen={deleteConfirmationModalOpen}
                    handleCloseDeleteConfirmationModal={handleCloseDeleteConfirmationModal}
                    confirmationText={deleteConfirmationModalText}
                    deletionTargetFunction={deleteBuildingConsumptionFunc}
                />
            )}

            <Box sx={{ mt: 3 }}>
                <Accordion sx={{ width: "160ch" }}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <LocalFireDepartmentIcon
                            sx={{
                                fontSize: 30,
                            }}
                        />{" "}
                        <Typography
                            sx={{
                                ml: 2,
                                fontSize: 20,
                                fontWeight: "bold",
                            }}
                        >
                            Verbrauchsdaten
                        </Typography>
                        {buildingConsumptions.length === 0 && (
                            <CustomTooltip title="Zu diesem Gebäude sind noch keine Verbrauchsdaten vorhanden.">
                                <ReportProblemTwoToneIcon sx={{ color: "#ff9e17", fontSize: 23, mt: 0.6, ml: 2 }} />
                            </CustomTooltip>
                        )}
                    </AccordionSummary>
                    <AccordionDetails
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                        }}
                    >
                        <Divider sx={{ mb: 2, mt: -2, width: "100%" }} />
                        {fetchingBuildingConsumptionData && LoadingSpinner}
                        <Box sx={{ ml: 0 }}>
                            <Fab
                                variant="extended"
                                size="medium"
                                onClick={handleOpenAddConsumptionYear}
                                disabled={editModeBuildingConsumptions}
                                sx={{ zIndex: 0, boxShadow: 1, borderRadius: 1 }}
                            >
                                <AddIcon />
                                Verbrauchsjahr hinzufügen
                            </Fab>
                            {buildingConsumptions.length > 0 && (
                                <Fab
                                    variant="extended"
                                    size="medium"
                                    disabled={editModeBuildingConsumptions}
                                    onClick={(e) => {
                                        setDeleteConfirmationModalText(
                                            "Sind Sie sicher, dass Sie " +
                                                'das Verbrauchsjahr "' +
                                                buildingConsumptions[buildingConsumptionsTab]?.year +
                                                '"' +
                                                " und alle zugehörigen Daten löschen möchten?"
                                        );
                                        setDeleteBuildingConsumptionFunc(() => () => deleteBuildingConsumptionYear());
                                        handleOpenDeleteConfirmationModal();
                                    }}
                                    sx={{ ml: 2, zIndex: 0, boxShadow: 1, borderRadius: 1 }}
                                >
                                    <DeleteIcon />
                                    Verbrauchsjahr löschen
                                </Fab>
                            )}
                            <Modal
                                open={addConsumptionYearModalOpen}
                                onClose={handleCloseAddConsumptionYear}
                                aria-labelledby="add-year-modal"
                                onKeyUp={(e) => {
                                    if (e.key === "Enter") {
                                        if (addConsumptionYearValid) {
                                            addBuildingConsumptionsTab();
                                        }
                                    }
                                }}
                            >
                                <Box
                                    sx={{
                                        position: "absolute",
                                        top: "50%",
                                        left: "50%",
                                        transform: "translate(-50%, -50%)",
                                        width: 500,
                                        bgcolor: "background.paper",
                                        border: "2px solid #000",
                                        boxShadow: 24,
                                        p: 4,
                                    }}
                                >
                                    <Button
                                        sx={{
                                            position: "absolute",
                                            top: "10px",
                                            right: "5px",
                                        }}
                                        onClick={() => handleCloseAddConsumptionYear()}
                                    >
                                        <CloseIcon />
                                    </Button>
                                    <Typography id="modal-modal-title" variant="h6" component="h2">
                                        Für welches Jahr möchten Sie Verbrauchsdaten hinzufügen?
                                    </Typography>
                                    <NumberInput
                                        value={addConsumptionYearChoice}
                                        variant="outlined"
                                        error={!addConsumptionYearValid}
                                        onChange={(e) => {
                                            if (
                                                parseInt(e.target.value) < 2000 ||
                                                parseInt(e.target.value) > 2050 ||
                                                e.target.value.length !== 4 ||
                                                !buildingConsumptions.every((c) => c.year !== parseInt(e.target.value))
                                            ) {
                                                setAddConsumptionYearValid(false);
                                            } else {
                                                setAddConsumptionYearValid(true);
                                            }
                                            setAddConsumptionYearChoice(parseInt(e.target.value));
                                        }}
                                        sx={{ mt: 1 }}
                                        size="small"
                                        label="Jahr"
                                        type="number"
                                    />
                                    <Fab
                                        disabled={!addConsumptionYearValid}
                                        onClick={(e) => addBuildingConsumptionsTab()}
                                        variant="extended"
                                        size="medium"
                                        color="secondary"
                                        sx={{ ml: 3, mt: 0.8, boxShadow: 1, borderRadius: 1 }}
                                    >
                                        Speichern
                                    </Fab>
                                </Box>
                            </Modal>
                            {buildingConsumptions.length > 0 ? (
                                <>
                                    <Box
                                        component="form"
                                        noValidate
                                        autoComplete="off"
                                        onKeyUp={(e) => {
                                            if (e.key === "Enter" && editModeBuildingConsumptions) {
                                                if (
                                                    !(
                                                        (Object.keys(errorBuildingConsumptions).length > 0 &&
                                                            !Object.values(errorBuildingConsumptions).every(
                                                                (item) => item === false
                                                            )) ||
                                                        buildingConsumptions[buildingConsumptionsTab].consumptions[0]
                                                            .energy_source === ""
                                                    )
                                                ) {
                                                    handleSave();
                                                }
                                            }
                                        }}
                                        sx={{ mt: 2 }}
                                        border={1}
                                        borderRadius={2}
                                        borderColor="transparent"
                                    >
                                        <div
                                            sx={{
                                                display: "flex",
                                                flexDirection: "column",
                                                gap: "0.5rem",
                                                border: 1,
                                                borderRadius: 2,
                                            }}
                                        >
                                            <Box
                                                sx={{ bgcolor: theme.palette.secondary.main, borderRadius: "5px" }}
                                                width="100%"
                                            >
                                                <Tabs
                                                    TabIndicatorProps={{
                                                        children: <span className="MuiTabs-indicatorSpan" />,
                                                    }}
                                                    value={buildingConsumptionsTab}
                                                    onChange={handleBuildingConsumptionsTab}
                                                    variant="scrollable"
                                                    scrollButtons={true}
                                                    sx={{
                                                        borderColor: theme.palette.neutral.light,
                                                        height: "20px",
                                                        // bgcolor: theme.palette.primary.main,
                                                        mb: 1,
                                                        mt: 0,
                                                        "& .MuiTabs-scrollButtons.Mui-disabled": {
                                                            opacity: 0.3,
                                                        },
                                                        "& .Mui-selected": {
                                                            color: "primary",
                                                        },
                                                        "& .MuiTabs-indicator": {
                                                            display: "flex",
                                                            justifyContent: "center",
                                                            backgroundColor: "transparent",
                                                            height: 4,
                                                            mb: "7px",
                                                        },
                                                        "& .MuiTabs-indicatorSpan": {
                                                            maxWidth: 50,
                                                            width: "100%",
                                                            backgroundColor: theme.palette.background.default,
                                                            height: "100%",
                                                        },
                                                    }}
                                                >
                                                    {buildingConsumptions.map((val, index) => {
                                                        return (
                                                            <Tab
                                                                disabled={editModeBuildingConsumptions}
                                                                label={val.year}
                                                                id={index}
                                                                key={"consumption-tab-" + index}
                                                                sx={{ fontWeight: "bold", fontSize: "20px" }}
                                                            />
                                                        );
                                                    })}
                                                </Tabs>
                                            </Box>
                                            <div
                                                sx={{
                                                    marginLeft: "auto",
                                                    display: "flex",
                                                    flexDirection: "row",
                                                }}
                                            >
                                                <Fab
                                                    variant="extended"
                                                    size="medium"
                                                    color={editModeBuildingConsumptions ? "default" : "secondary"}
                                                    onClick={() => {
                                                        handleEditMode();
                                                    }}
                                                    sx={{
                                                        marginRight: "1rem",
                                                        zIndex: 0,
                                                        boxShadow: 1,
                                                        borderRadius: 1,
                                                    }}
                                                >
                                                    {editModeBuildingConsumptions ? <></> : <EditIcon sx={{ mr: 1 }} />}
                                                    {editModeBuildingConsumptions ? "Abbrechen" : "Bearbeiten"}
                                                </Fab>
                                                {editModeBuildingConsumptions && (
                                                    <Fab
                                                        disabled={
                                                            (Object.keys(errorBuildingConsumptions).length > 0 &&
                                                                !Object.values(errorBuildingConsumptions).every(
                                                                    (item) => item === false
                                                                )) ||
                                                            buildingConsumptions[buildingConsumptionsTab]
                                                                .consumptions[0].energy_source === ""
                                                        }
                                                        variant="extended"
                                                        size="medium"
                                                        color={editModeBuildingConsumptions ? "secondary" : "default"}
                                                        onClick={() => handleSave()}
                                                        sx={{
                                                            marginRight: "1rem",
                                                            zIndex: 0,
                                                            boxShadow: 1,
                                                            borderRadius: 1,
                                                        }}
                                                    >
                                                        <SaveIcon sx={{ mr: 1 }} />
                                                        Speichern
                                                    </Fab>
                                                )}
                                            </div>

                                            <div>
                                                <BasicTextField
                                                    label="Anmerkung zu den Verbrauchsdaten"
                                                    value={buildingConsumptions[buildingConsumptionsTab].note}
                                                    onChange={(e) =>
                                                        setBuildingConsumptions((cond) => {
                                                            cond[[buildingConsumptionsTab]].note = e.target.value;
                                                        })
                                                    }
                                                    disabled={!editModeBuildingConsumptions}
                                                    multiline
                                                    rows={2}
                                                    sx={{ m: 2, ml: 0, width: "44ch" }}
                                                />
                                            </div>

                                            <div>
                                                <Box
                                                    sx={{
                                                        ml: 0,
                                                        mt: 2,
                                                        mb: 1,
                                                        fontSize: 16,
                                                        fontWeight: "bold",
                                                    }}
                                                >
                                                    Verbräuche
                                                    <Fab
                                                        variant="extended"
                                                        size="small"
                                                        disabled={!editModeBuildingConsumptions}
                                                        onClick={addEnergySource}
                                                        sx={{ ml: 3, zIndex: 0, boxShadow: 1, borderRadius: 1 }}
                                                    >
                                                        <AddIcon />
                                                        Energieträger hinzufügen
                                                    </Fab>
                                                </Box>
                                                {buildingConsumptions[buildingConsumptionsTab].consumptions.map(
                                                    (val, index) => {
                                                        return (
                                                            <div key={"consumption-" + index}>
                                                                <BasicSelectField
                                                                    FormControlProps={{
                                                                        sx: {
                                                                            mt: 1,
                                                                            mr: 1,
                                                                            ml: 0,
                                                                        },
                                                                    }}
                                                                    InputLabelProps={{
                                                                        disabled: !editModeBuildingConsumptions,
                                                                        sx: {
                                                                            mt: val.energy_source ? 0 : -1,
                                                                        },
                                                                    }}
                                                                    label="Energieträger"
                                                                    size="small"
                                                                    value={val.energy_source}
                                                                    onChange={(e) =>
                                                                        setBuildingConsumptions((cond) => {
                                                                            cond[buildingConsumptionsTab].consumptions[
                                                                                index
                                                                            ].energy_source = e.target.value;
                                                                            cond[buildingConsumptionsTab].consumptions[
                                                                                index
                                                                            ].heating_system_type = "";
                                                                            if (e.target.value !== "Heizöl") {
                                                                                cond[
                                                                                    [buildingConsumptionsTab]
                                                                                ].consumptions[index].unit = "kWh";
                                                                            }
                                                                            cond[buildingConsumptionsTab].consumptions[
                                                                                index
                                                                            ].emission_model =
                                                                                emissionModels.find(
                                                                                    (item) =>
                                                                                        item.energy_source ===
                                                                                            e.target.value &&
                                                                                        item.is_default
                                                                                )?.id ?? "";
                                                                        })
                                                                    }
                                                                    disabled={!editModeBuildingConsumptions}
                                                                    sx={{
                                                                        width: "25ch",
                                                                    }}
                                                                    menuItems={energySources.map((item) => {
                                                                        return { name: item.name, value: item.name };
                                                                    })}
                                                                />
                                                                <BasicSelectField
                                                                    FormControlProps={{
                                                                        sx: { mt: 1, mr: 1, ml: 0 },
                                                                    }}
                                                                    InputLabelProps={{
                                                                        disabled: !editModeBuildingConsumptions,
                                                                    }}
                                                                    label="Verbrauchtstyp"
                                                                    size="small"
                                                                    value={val.consumption_type}
                                                                    onChange={(e) =>
                                                                        setBuildingConsumptions((cond) => {
                                                                            cond[buildingConsumptionsTab].consumptions[
                                                                                index
                                                                            ].consumption_type = e.target.value;
                                                                        })
                                                                    }
                                                                    disabled={!editModeBuildingConsumptions}
                                                                    sx={{
                                                                        width: "25ch",
                                                                    }}
                                                                    menuItems={consumptionTypes}
                                                                />
                                                                {val.energy_source === "Heizöl" ? (
                                                                    <>
                                                                        <FloatInput
                                                                            label="Verbrauch"
                                                                            size="small"
                                                                            value={val.consumption}
                                                                            onChange={(value) => {
                                                                                setBuildingConsumptions((cond) => {
                                                                                    cond[
                                                                                        [buildingConsumptionsTab]
                                                                                    ].consumptions[index].consumption =
                                                                                        value;
                                                                                });
                                                                            }}
                                                                            disabled={!editModeBuildingConsumptions}
                                                                            sx={{
                                                                                mt: 1,
                                                                                ml: 0,
                                                                                width: "14ch",
                                                                            }}
                                                                            validate={(val) => {
                                                                                return true;
                                                                            }}
                                                                            error={
                                                                                errorBuildingConsumptions.consumption
                                                                            }
                                                                            setError={(val) => {
                                                                                setErrorBuildingConsumptions((err) => {
                                                                                    err.consumption = val;
                                                                                });
                                                                            }}
                                                                        />
                                                                        <BasicSelectField
                                                                            FormControlProps={{
                                                                                sx: { mt: 1, mr: 1, ml: 0 },
                                                                            }}
                                                                            InputLabelProps={{
                                                                                disabled: !editModeBuildingConsumptions,
                                                                            }}
                                                                            label="Einheit"
                                                                            size="small"
                                                                            value={val.unit}
                                                                            onChange={(e) =>
                                                                                setBuildingConsumptions((cond) => {
                                                                                    cond[
                                                                                        buildingConsumptionsTab
                                                                                    ].consumptions[index].unit =
                                                                                        e.target.value;
                                                                                })
                                                                            }
                                                                            disabled={!editModeBuildingConsumptions}
                                                                            sx={{
                                                                                width: "10ch",
                                                                            }}
                                                                            menuItems={[
                                                                                { name: "Liter", value: "Liter" },
                                                                                { name: "kWh", value: "kWh" },
                                                                            ]}
                                                                        />
                                                                    </>
                                                                ) : (
                                                                    <FloatInput
                                                                        label="Verbrauch"
                                                                        size="small"
                                                                        value={val.consumption}
                                                                        onChange={(val) =>
                                                                            setBuildingConsumptions((cond) => {
                                                                                cond[
                                                                                    [buildingConsumptionsTab]
                                                                                ].consumptions[index].consumption = val;
                                                                            })
                                                                        }
                                                                        disabled={!editModeBuildingConsumptions}
                                                                        sx={{
                                                                            mt: 1,
                                                                            mr: 1,
                                                                            ml: 0,
                                                                            width: "24ch",
                                                                        }}
                                                                        InputProps={{
                                                                            endAdornment: (
                                                                                <InputAdornment position="end">
                                                                                    {"kWh"}
                                                                                </InputAdornment>
                                                                            ),
                                                                        }}
                                                                        validate={(val) => {
                                                                            return true;
                                                                        }}
                                                                        error={errorBuildingConsumptions.consumption}
                                                                        setError={(val) => {
                                                                            setErrorBuildingConsumptions((err) => {
                                                                                err.consumption = val;
                                                                            });
                                                                        }}
                                                                    />
                                                                )}
                                                                <BasicSelectField
                                                                    FormControlProps={{
                                                                        sx: { mt: 1, mr: 1, ml: 0 },
                                                                    }}
                                                                    InputLabelProps={{
                                                                        disabled: !editModeBuildingConsumptions,
                                                                        sx: {
                                                                            mt:
                                                                                !editModeBuildingConsumptions &&
                                                                                val.emission_model
                                                                                    ? 0
                                                                                    : !editModeBuildingConsumptions
                                                                                    ? -0.8
                                                                                    : val.emission_model
                                                                                    ? 0
                                                                                    : -0.8,
                                                                        },
                                                                    }}
                                                                    label="Emissionsmodell"
                                                                    size="small"
                                                                    value={val.emission_model}
                                                                    onChange={(e) =>
                                                                        setBuildingConsumptions((cond) => {
                                                                            cond[
                                                                                [buildingConsumptionsTab]
                                                                            ].consumptions[index].emission_model =
                                                                                e.target.value;
                                                                        })
                                                                    }
                                                                    disabled={
                                                                        !editModeBuildingConsumptions ||
                                                                        val.energy_source === ""
                                                                    }
                                                                    sx={{
                                                                        width: "30ch",
                                                                    }}
                                                                    menuItems={emissionModels.map((item) => {
                                                                        if (item.energy_source === val.energy_source) {
                                                                            return { name: item.name, value: item.id };
                                                                        } else {
                                                                            return null;
                                                                        }
                                                                    })}
                                                                />
                                                                <BasicSelectField
                                                                    FormControlProps={{
                                                                        sx: { mt: 1, mr: 1, ml: 0 },
                                                                    }}
                                                                    InputLabelProps={{
                                                                        disabled: !editModeBuildingConsumptions,
                                                                        sx: {
                                                                            mt:
                                                                                !editModeBuildingConsumptions &&
                                                                                val.heating_system_type
                                                                                    ? 0
                                                                                    : !editModeBuildingConsumptions
                                                                                    ? -0.8
                                                                                    : val.heating_system_type
                                                                                    ? 0
                                                                                    : -0.8,
                                                                        },
                                                                    }}
                                                                    label="Anlagentyp (optional)"
                                                                    size="small"
                                                                    value={val.heating_system_type ?? ""}
                                                                    onChange={(e) =>
                                                                        setBuildingConsumptions((cond) => {
                                                                            cond[buildingConsumptionsTab].consumptions[
                                                                                index
                                                                            ].heating_system_type = e.target.value;
                                                                        })
                                                                    }
                                                                    disabled={
                                                                        !editModeBuildingConsumptions ||
                                                                        val.energy_source === ""
                                                                    }
                                                                    sx={{
                                                                        width: "28ch",
                                                                    }}
                                                                    menuItems={heatingSystemTypes.map((item) => {
                                                                        if (item.energy_source === val.energy_source) {
                                                                            return { name: item.name, value: item.id };
                                                                        } else {
                                                                            return null;
                                                                        }
                                                                    })}
                                                                />
                                                                {/* delete button */}
                                                                {val.id !== 0 && (
                                                                    <Fab
                                                                        variant="circular"
                                                                        size="small"
                                                                        disabled={!editModeBuildingConsumptions}
                                                                        onClick={(e) => {
                                                                            setDeleteConfirmationModalText(
                                                                                "Sind Sie sicher, dass Sie " +
                                                                                    'die Verbrauchsangabe "' +
                                                                                    val.energy_source +
                                                                                    " " +
                                                                                    val.consumption.display +
                                                                                    " " +
                                                                                    val.unit +
                                                                                    '"' +
                                                                                    " und alle zugehörigen Daten löschen möchten?"
                                                                            );
                                                                            setDeleteBuildingConsumptionFunc(
                                                                                () => () =>
                                                                                    deleteBuildingConsumption(val.id)
                                                                            );
                                                                            handleOpenDeleteConfirmationModal();
                                                                        }}
                                                                        sx={{
                                                                            mt: 0.7,
                                                                            ml: 1,
                                                                            zIndex: 0,
                                                                            boxShadow: 1,
                                                                            borderRadius: 1,
                                                                        }}
                                                                    >
                                                                        <DeleteIcon />
                                                                    </Fab>
                                                                )}
                                                            </div>
                                                        );
                                                    }
                                                )}
                                            </div>
                                        </div>
                                    </Box>
                                </>
                            ) : (
                                <Box
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        minHeight: "200px",
                                        width: "100%",
                                    }}
                                >
                                    <Alert severity="info" sx={{ textAlign: "left", height: "8ch", fontSize: 14 }}>
                                        <strong>Keine Verbrauchsdaten verfügbar.</strong>
                                        <br />
                                        Bitte fügen Sie Verbrauchsdaten über "Verbrauchsjahr hinzufügen" hinzu.
                                        <br />
                                    </Alert>
                                </Box>
                            )}
                        </Box>
                    </AccordionDetails>
                </Accordion>
            </Box>
        </>
    );
};

import { createContext, useState } from "react";

const UpdateTimeStampContext = createContext();

/**
 * Context provider for managing a timestamp to trigger updates across the application.
 *
 * This provider component offers a way to store and update a timestamp, which can be used to trigger
 * re-fetching or refreshing of data in child components. It utilizes React's Context API to allow
 * child components to access and modify the timestamp as needed.
 *
 * Props:
 *   children (ReactNode): Child components that can consume the context.
 *
 * Usage:
 * Wrap your component tree with `UpdateTimeStampProvider` to provide access to the update timestamp
 * context throughout your application.
 *
 * Example:
 * ```
 * <UpdateTimeStampProvider>
 *   <YourComponent />
 * </UpdateTimeStampProvider>
 * ```
 *
 * Returns:
 *   ReactNode: A context provider component that passes down the update timestamp and its setter function to its children.
 */
export const UpdateTimeStampProvider = ({ children }) => {
    const [updateTimestamp, setUpdateTimestamp] = useState(0);
    return (
        <UpdateTimeStampContext.Provider value={{ updateTimestamp, setUpdateTimestamp }}>
            {children}
        </UpdateTimeStampContext.Provider>
    );
};

export default UpdateTimeStampContext;

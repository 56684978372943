import { useContext } from "react";
import AuthContext from "../../contexts/AuthContext";
import { redirect, Navigate } from "react-router-dom";

const Logout = () => {
    // const { user } = useContext(AuthContext);
    const { logoutUser } = useContext(AuthContext);

    const handleLogout = () => {
        logoutUser();
    };

    return (
        <>
            {handleLogout()}
            <Navigate to="/login" />
        </>
    );
};

export default Logout;

import { MapContainer } from "react-leaflet/MapContainer";
import { TileLayer } from "react-leaflet/TileLayer";
import { Marker } from "react-leaflet/Marker";
import { Tooltip } from "react-leaflet/Tooltip";
import "leaflet/dist/leaflet.css";
import { Fab, FormControl, FormControlLabel, Paper, Radio, RadioGroup } from "@mui/material";
import { useEffect, useState, useContext } from "react";
import ResultTableContext from "../../contexts/ResultTableContext";
import L from "leaflet";
import "./svg-icon.js";

export const SimpleMarkerMap = ({ height, maxHeight, markers = [] }) => {
    const [map, setMap] = useState(null);
    const { filteredBuildings, setFilteredBuildings } = useContext(ResultTableContext);
    const [colorSelector, setColorSelector] = useState("pi");
    // const onMove = useCallback(() => {
    // }, [map]);

    // useEffect(() => {
    //     if (map) {
    //         map.on("move", onMove);
    //         return () => {
    //             map.off("move", onMove);
    //         };
    //     }
    //     return;
    // }, [map, onMove]);

    useEffect(() => {
        if (map) {
            if (markers.length > 0) {
                map.setView(markers[0].position, 13);
            }
        }
        return () => {};
    }, [markers, map]);

    const toggleFilterBuilding = (e, marker) => {
        const building = filteredBuildings.find((b) => b === marker.id);
        if (building) {
            setFilteredBuildings(filteredBuildings.filter((b) => b !== marker.id));
            e.sourceTarget.setOpacity(0.7);
        } else {
            setFilteredBuildings([...filteredBuildings, marker.id]);
            e.sourceTarget.setOpacity(1);
        }
    };

    return (
        <>
            <link
                rel="stylesheet"
                href="https://unpkg.com/leaflet@1.9.4/dist/leaflet.css"
                integrity="sha256-p4NxAoJBhIIN+hmNHrzRCf9tD/miZyoHS5obTRR9BMY="
                crossOrigin=""
            />
            <script
                src="https://unpkg.com/leaflet@1.9.4/dist/leaflet.js"
                integrity="sha256-20nQCchB9co0qIjJZRGuk2/Z9VM+kNiyxNV1lvTlZBo="
                crossOrigin=""
            ></script>
            <Paper sx={{ width: "100%", mt: 3, padding: 2 }}>
                <FormControl sx={{ width: "100%" }}>
                    <RadioGroup
                        row
                        aria-labelledby="color-selector-radio-buttons-group-label"
                        name="color-selector-row-radio-buttons-group"
                        onChange={(e) => setColorSelector(e.target.value)}
                        sx={{
                            justifyContent: "center",
                            display: "flex",
                            width: "100%",
                        }}
                        data-testid="building-selection-radio-group"
                    >
                        <FormControlLabel
                            value="eec"
                            control={<Radio />}
                            label="Energieeffizienzklasse"
                            data-testid="color-selector-radio-ee-class"
                        />
                        <FormControlLabel
                            sx={{ ml: 1.5 }}
                            value="eeec"
                            control={<Radio />}
                            label="Erwartete Energieeffizienzklasse"
                            data-testid="color-selector-radio-expected-ee-class"
                        />
                        <FormControlLabel
                            value="pi"
                            control={<Radio />}
                            label="Performanceindikator"
                            checked={colorSelector === "pi"}
                            data-testid="color-selector-radio-performance-indicator"
                        />
                    </RadioGroup>
                </FormControl>
                <Fab
                    color="secondary"
                    variant="extended"
                    size="medium"
                    disabled={filteredBuildings.length === 0}
                    sx={{
                        zIndex: 100000,
                        display: "block",
                        justifyContent: "right",
                        justifyItems: "right",
                        left: "calc(100% - 28ch)",
                        top: 55,
                        mt: -4,
                    }}
                    onClick={() => setFilteredBuildings([])}
                >
                    Auswahl zurücksetzen
                </Fab>
                <div id="map">
                    <MapContainer
                        center={markers.length > 0 ? markers[0].position : [49.476, 8.664]}
                        zoom={13}
                        scrollWheelZoom={true}
                        style={{ height: height, maxHeight: maxHeight }}
                        ref={setMap}
                    >
                        <TileLayer
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        />
                        {markers.map((marker, index) => (
                            <Marker
                                key={index}
                                position={marker.position}
                                eventHandlers={{ click: (e) => toggleFilterBuilding(e, marker) }}
                                icon={L.divIcon.svgIcon({
                                    iconSize: [30, 45],
                                    circleRatio: 0.25,
                                    color: marker.color[colorSelector],
                                    fillOpacity: 1,
                                })}
                                opacity={0.7}
                            >
                                <Tooltip direction="top" offset={[0, -20]} opacity={1}>
                                    {marker.address}
                                </Tooltip>
                            </Marker>
                        ))}
                    </MapContainer>
                </div>
            </Paper>
        </>
    );
};

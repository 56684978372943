import React, { useState, useContext, useEffect } from "react";
import {
    Paper,
    Accordion,
    FormControlLabel,
    Checkbox,
    AccordionSummary,
    AccordionDetails,
    Typography,
    TextField,
    Box,
    Divider,
    Modal,
    Fab,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SettingsIcon from "@mui/icons-material/Settings";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import api_client from "../../services/http-common";
import { ValidatedTextField } from "../../components/InputFields";
import AuthContext from "../../contexts/AuthContext";
import ToastContext from "../../contexts/ToastContext";
import { useTheme } from "@mui/material/styles";
import { LoadingSpinner } from "../../components/LoadingSpinner";
import { Loglevel } from "../../services/Logging";
import logger from "../../services/Logging";

const Settings = () => {
    const theme = useTheme();

    const { loginUser } = useContext(AuthContext);

    const { addToastMessage } = useContext(ToastContext);

    const [passwordChangeModalOpen, setPasswordChangeModalOpen] = useState(false);
    const [currentPassword, setCurrentPassword] = useState("");
    const [currentPasswordError, setCurrentPasswordError] = useState(false);
    const [currentPasswordErrorText, setCurrentPasswordErrorText] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [newPasswordError, setNewPasswordError] = useState(false);
    const [newPasswordErrorText, setNewPasswordErrorText] = useState("");

    // Control the spinner
    const [transmittingData, setTransmittingData] = useState(true);

    const handlePasswordChangeModalOpen = () => {
        setPasswordChangeModalOpen(true);
    };

    const handlePasswordChangeModalClose = () => {
        // Reset the form
        setCurrentPassword("");
        setNewPassword("");
        setCurrentPasswordError(false);
        setNewPasswordError(false);
        // Close the modal
        setPasswordChangeModalOpen(false);
    };

    const handlePasswordChange = () => {
        api_client
            .post(`/user/password/change/`, { current_password: currentPassword, new_password: newPassword })
            .then((response) => {
                if (response && response.status) {
                    handlePasswordChangeModalClose();
                }
                if (
                    loginUser(
                        localStorage.getItem("username"),
                        newPassword,
                        () => {
                            return null;
                        },
                        () => {
                            return null;
                        }
                    )
                ) {
                    addToastMessage(
                        "Passwort erfolgreich geändert.",
                        "success",
                        true,
                        6000,
                        "toast-password-change-success"
                    );
                }
            })
            .catch((error) => {
                if (error.response.status === 400) {
                    addToastMessage(
                        "Fehler beim Ändern des Passworts.",
                        "error",
                        true,
                        6000,
                        "toast-password-change-error"
                    );
                    if (error.response.data.current_password) {
                        setCurrentPasswordError(true);
                        setCurrentPasswordErrorText("Angabe zum aktuellen Passwort is fehlerhaft.");
                    }
                    if (error.response.data.new_password) {
                        setNewPasswordError(true);
                        if (error.response.data.new_password.includes("This password is too common.")) {
                            setNewPasswordErrorText("Dieses Passwort ist zu üblich.");
                        } else if (
                            error.response.data.new_password.includes(
                                "The password is too similar to the E-Mail-Adresse."
                            )
                        ) {
                            setNewPasswordErrorText("Dieses Passwort ist zu ähnlich zur gespeicherten E-Mail Adresse.");
                        } else if (
                            error.response.data.new_password.includes(
                                "The password is too similar to the Benutzername."
                            )
                        ) {
                            setNewPasswordErrorText("Dieses Passwort ist zu ähnlich zum gespeicherten Benutzernamen.");
                        } else {
                            setNewPasswordErrorText("Dieses Passwort entspricht nicht unseren Passwort-Vorgaben.");
                        }
                    }
                } else {
                    handlePasswordChangeModalClose();
                }
            });
    };

    const isPasswordValid = () => {
        // MinimumLengthValidator (minimum 8 characters)
        const hasMinimumLength = newPassword.length >= 8;

        // NumericPasswordValidator (must contain at least one digit)
        const hasNumericCharacter = /\d/.test(newPassword);

        if (!hasNumericCharacter) {
            setNewPasswordErrorText("Das Password muss mindestens eine Zahl enthalten.");
            return false;
        }
        if (!hasMinimumLength) {
            setNewPasswordErrorText("Das Password muss mindestens 8 Zeichen lang sein.");
            return false;
        }

        return true;
    };
    //  states for fetching data
    const [userData, setUserData] = useState({
        username: "",
        group: "",
        email: "",
        first_name: "",
        last_name: "",
        use_most_recent_year: false,
        default_result_year: "",
    });
    const [error, setError] = useState(null);
    const [years, setYears] = useState([]);

    // states for form validation
    const [errors, setErrors] = useState({});

    useEffect(() => {
        const fetchData = async () => {
            try {
                // Make both API calls simultaneously using Promise.all
                const [userResponse, resultResponse] = await Promise.all([
                    api_client.get("/user/"),
                    api_client.post(`/building-results/`, {
                        building_filter: "",
                        year: null,
                    }),
                ]);
                // if default_result_year is not in the available years, set it to the first available year
                // if available_years.length === 0
                if (resultResponse.data.available_years.length === 0) {
                    userResponse.data.data.user.default_result_year = "no-years-found";
                } else {
                    if (
                        !userResponse.data.data.user.default_result_year ||
                        !resultResponse.data.available_years.includes(userResponse.data.data.user.default_result_year)
                    ) {
                        userResponse.data.data.user.default_result_year = resultResponse.data.available_years[0];
                    }
                }

                // Handle user data
                setUserData(userResponse.data.data.user);
                // Handle building results
                const resp = resultResponse.data;
                if (!(resp.data.length > 0)) {
                    setTransmittingData(false);
                }

                // Setup choices for year dropdown
                if (years.length === 0) {
                    setYears(resp.available_years);
                }

                // If the current default_result_year is not in the list of available years, set it to the first available year from the years array => as this changes the value of the select, update the state to be set to the first entry, can only happen when the user has deleted entries => fewer years available
                setTransmittingData(false);
            } catch (err) {
                logger.log(Loglevel.ERROR, "Error fetching result and user data in Settings.", err);
                setError("Error fetching data");
                setTransmittingData(false);
            }
        };
        fetchData();
    }, []); // Dependencies for useEffect

    // Handle input change in the form
    const handleInputChange = (e) => {
        const { name, type, checked, value } = e.target;
        // If it's a checkbox, use `checked`, otherwise use `value`
        setUserData({
            ...userData,
            [name]: type === "checkbox" ? checked : value,
        });
    };

    // Handle form submission to update user data
    const handleSubmit = async (e) => {
        e.preventDefault();
        setTransmittingData(true);
        try {
            await api_client.put("/user/", userData);
            addToastMessage(
                "Benutzerdaten erfolgreich geändert.",
                "success",
                true,
                6000,
                "toast-userdata-change-success"
            );
            setErrors({}); // Clear any previous errors
            setError("");
        } catch (err) {
            if (err.response && err.response.data) {
                setErrors(err.response.data); // Capture validation errors from the API
                addToastMessage(
                    "Benutzerdaten konnten nicht geändert werden. Bitte kontrollieren Sie die Eingabe.",
                    "warning",
                    true,
                    6000,
                    "toast-userdata-change-invalid-data"
                );
            } else {
                logger.log(Loglevel.ERROR, "Error updating user data in Settings.", err);
                setError("Benutzerdaten konnten nicht geändert werden.");
                addToastMessage(
                    "Benutzerdaten konnten nicht geändert werden. Bitte versuchen Sie es später erneut.",
                    "error",
                    true,
                    6000,
                    "toast-userdata-change-error"
                );
            }
        }
        setTransmittingData(false);
    };

    if (error) return <div>{error}</div>;

    return (
        <>
            {/* Add the "Nutzerdaten" accordion */}
            <Box sx={{ mt: 3, width: "160ch", maxWidth: "100%" }}>
                <Accordion sx={{ width: "160ch", maxWidth: "100%" }} defaultExpanded>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography variant="h4">
                            <SettingsIcon sx={{ mr: 2, mb: -1.1, fontSize: 35 }} />
                            Nutzerdaten
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        {transmittingData && LoadingSpinner}
                        <Box sx={{ mb: 2, display: "flex", flexDirection: "column" }}>
                            <TextField
                                label="Benutzername"
                                name="username"
                                value={userData.username}
                                onChange={handleInputChange}
                                sx={{ width: "100%", maxWidth: "500px" }}
                                margin="normal"
                                disabled
                            />
                            <TextField
                                label="Bestand"
                                name="group"
                                value={userData.group}
                                onChange={handleInputChange}
                                sx={{ width: "100%", maxWidth: "500px" }}
                                margin="normal"
                                disabled
                            />
                            <TextField
                                label="E-Mail"
                                name="email"
                                value={userData.email}
                                onChange={handleInputChange}
                                sx={{ width: "100%", maxWidth: "500px" }}
                                margin="normal"
                                error={!!errors.email}
                            />
                            <TextField
                                label="Vorname"
                                name="first_name"
                                value={userData.first_name}
                                onChange={handleInputChange}
                                sx={{ width: "100%", maxWidth: "500px" }}
                                margin="normal"
                                error={!!errors.first_name}
                            />
                            <TextField
                                label="Nachname"
                                name="last_name"
                                value={userData.last_name}
                                onChange={handleInputChange}
                                sx={{ width: "100%", maxWidth: "500px" }}
                                margin="normal"
                                error={!!errors.last_name}
                            />

                            <Paper
                                elevation={3}
                                sx={{ p: 2, borderRadius: 2, mt: 2, width: "100%", maxWidth: "500px" }}
                            >
                                <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                    }}
                                >
                                    <Typography variant="h6" sx={{ mb: 2 }}>
                                        Standard-Auswertungsjahr
                                    </Typography>
                                </Box>

                                    <Box
                                        sx={{
                                            mt: 0,
                                            display: "flex",
                                            flexDirection: "column",
                                            gap: 0,
                                        }}
                                    >
                                        <div>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        name="use_most_recent_year"
                                                        checked={userData.use_most_recent_year} // Bind checkbox state
                                                        onChange={handleInputChange}
                                                        sx={{
                                                            color:
                                                                errors.use_most_recent_year !== undefined
                                                                    ? theme.palette.error.main
                                                                    : "inherit",
                                                            "&.Mui-checked": {
                                                                color:
                                                                    errors.use_most_recent_year !== undefined
                                                                        ? theme.palette.error.main
                                                                        : "inherit",
                                                            },
                                                        }}
                                                    />
                                                }
                                                label="Aktuellstes verfügbares Jahr verwenden"
                                                sx={{
                                                    color:
                                                        errors.use_most_recent_year !== undefined
                                                            ? theme.palette.error.main
                                                            : "inherit",
                                                }}
                                            />
                                        </div>

                                        <TextField
                                            select
                                            label="Alternatives Auswertungsjahr"
                                            name="default_result_year"
                                            value={userData.default_result_year}
                                            onChange={handleInputChange}
                                            sx={{ width: "100%", maxWidth: "500px" }}
                                            margin="normal"
                                            disabled={userData.use_most_recent_year}
                                            error={!!errors.default_result_year}
                                            SelectProps={{
                                                native: true,
                                            }}
                                        >
                                            {years.length === 0 && (
                                                <option value="no-years-found">Keine Jahre verfügbar</option>
                                            )}
                                            {years.map((year) => (
                                                <option key={year} value={year}>
                                                    {year}
                                                </option>
                                            ))}
                                        </TextField>
                                    </Box>
                                {/* )} */}
                            </Paper>
                        </Box>

                        <Fab variant="extended"
                                color="default" aria-label="save" onClick={handleSubmit} sx={{ boxShadow: 1, borderRadius: 1, mt: 1, mb: 2 }}>
                            <SaveIcon sx={{ mr: 1 }} />
                            Änderungen speichern
                        </Fab>

                        <Box sx={{ width: "100%", height: 100 }}>
                            <Divider sx={{ mb: 2, mt: 1, width: "100%" }} />
                            <Fab
                                sx={{ boxShadow: 1, borderRadius: 1 }}
                                variant="extended"
                                color="default"
                                onClick={handlePasswordChangeModalOpen}
                            >
                                <EditIcon /> Passwort ändern
                            </Fab>
                        </Box>
                    </AccordionDetails>
                </Accordion>
            </Box>

            {/* Modal for password change */}
            <Modal open={passwordChangeModalOpen} onClose={handlePasswordChangeModalClose}>
                <Box
                    sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        bgcolor: "background.paper",
                        boxShadow: 24,
                        p: 4,
                        outline: "none",
                        width: "330px",
                    }}
                >
                    <Typography variant="h5" sx={{ mb: 3 }}>
                        Passwort ändern
                    </Typography>
                    <ValidatedTextField
                        type="password"
                        label="Aktuelles Passwort"
                        value={currentPassword}
                        onChange={(e) => setCurrentPassword(e.target.value)}
                        fullWidth
                        sx={{ mb: 2 }}
                        validate={(val) => {
                            return true;
                        }}
                        error={currentPasswordError}
                        setError={(val) => {
                            setCurrentPasswordError(val);
                        }}
                        errorText={currentPasswordErrorText}
                    />
                    <ValidatedTextField
                        type="password"
                        label="Neues Passwort"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        fullWidth
                        sx={{ mb: 3 }}
                        validate={(val) => {
                            return isPasswordValid(val);
                        }}
                        error={newPasswordError}
                        setError={(val) => {
                            setNewPasswordError(val);
                        }}
                        errorText={newPasswordErrorText}
                    />
                    <Fab
                        variant="extended"
                        color="default"
                        sx={{ boxShadow: 1, borderRadius: 1 }}
                        onClick={handlePasswordChangeModalClose}
                    >
                        Abbrechen
                    </Fab>
                    <Fab
                        variant="extended"
                        color="secondary"
                        onClick={handlePasswordChange}
                        sx={{ ml: 3, boxShadow: 1, borderRadius: 1 }}
                        disabled={
                            newPasswordError ||
                            currentPasswordError ||
                            newPassword.length === 0 ||
                            currentPassword.length === 0
                        }
                    >
                        <SaveIcon /> Speichern
                    </Fab>
                </Box>
            </Modal>
        </>
    );
};

export default Settings;

import { createContext } from "react";
import { useImmer } from "use-immer";

const ToastContext = createContext();

/**
 * Provides a context for managing toast notifications in the application.
 *
 * This component handles the creation, update, and storage of toast messages. It uses the `useImmer` hook
 * for state management to handle complex state updates in an immutable way.
 *
 * Props:
 *   children (ReactNode): The child components that will consume the toast context.
 *
 * Context Value:
 *   toastList (Array): An array of toast messages.
 *   setToastList (Function): Function to update the toastList state.
 *   addToastMessage (Function): Function to add a new toast message. Takes `message`, `severity`, `display`,
 *                               and an optional `duration` as parameters.
 *   getSetDisplayFunction (Function): Function to get a setter function for the display property of a specific toast.
 *
 * Returns:
 *   ReactNode: A context provider wrapping its children, passing down toast management functions and state.
 */
export const ToastProvider = ({ children }) => {
    const [toastList, setToastList] = useImmer([]);

    const addToastMessage = (message, severity, display = true, duration = 6000, testid = "toast") => {
        const toastIdx = toastList.length;
        setToastList((list) => {
            list.push({
                message: message,
                severity: severity,
                display: display,
                duration: duration,
                data_testid: testid,
            });
        });
        return toastIdx;
    };

    const getSetDisplayFunction = (idx) => {
        return (val) => {
            setToastList((list) => {
                list[idx].display = val;
            });
        };
    };

    return (
        <ToastContext.Provider value={{ toastList, setToastList, addToastMessage, getSetDisplayFunction }}>
            {children}
        </ToastContext.Provider>
    );
};

export default ToastContext;

import { useTheme } from "@mui/material";
import { tokens } from "../themes/theme";
import { ResponsiveBar } from "@nivo/bar";

const BarChart = ({ isPortfolioAnalysis = true, data, keys, label, specific, consumption = false }) => {
    const chartTheme = {
        // background: "#ffffff",
        textColor: "#333333",
        fontSize: 13,
        axis: {
            domain: {
                line: {
                    stroke: "#777777",
                    strokeWidth: 2,
                },
            },
            legend: {
                text: {
                    fontSize: 16,
                    fill: "#000000",
                },
            },
            ticks: {
                line: {
                    stroke: "#777777",
                    strokeWidth: 2,
                },
                text: {
                    fontSize: 13,
                    fill: "#333333",
                },
            },
        },
        grid: {
            line: {
                stroke: "#dddddd",
                strokeWidth: 1,
            },
        },
        legends: {
            title: {
                text: {
                    fontSize: 11,
                    fill: "#333333",
                },
            },
            text: {
                fontSize: 13,
                fill: "#333333",
            },
            ticks: {
                line: {},
                text: {
                    fontSize: 10,
                    fill: "#333333",
                },
            },
        },
        annotations: {
            text: {
                fontSize: 13,
                fill: "#333333",
                outlineWidth: 2,
                outlineColor: "#ffffff",
                outlineOpacity: 1,
            },
            link: {
                stroke: "#000000",
                strokeWidth: 1,
                outlineWidth: 2,
                outlineColor: "#ffffff",
                outlineOpacity: 1,
            },
            outline: {
                stroke: "#000000",
                strokeWidth: 2,
                outlineWidth: 2,
                outlineColor: "#ffffff",
                outlineOpacity: 1,
            },
            symbol: {
                fill: "#000000",
                outlineWidth: 2,
                outlineColor: "#ffffff",
                outlineOpacity: 1,
            },
        },
        tooltip: {
            container: {
                background: "#ffffff",
                color: "#333333",
                fontSize: 12,
            },
            basic: {},
            chip: {},
            table: {},
            tableCell: {},
            tableCellValue: {},
        },
    };

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    return (
        <ResponsiveBar
            data={data}
            indexBy="year"
            keys={keys}
            margin={{ top: 50, right: 210, bottom: 50, left: 80 }}
            padding={0.5}
            valueScale={{ type: "linear" }}
            indexScale={{ type: "band", round: true }}
            colors={["#FFFC20", "#FF8E3B", "#5CB8FF", "#fcc08b", "#a6dbf7"]}
            borderWidth={1}
            borderRadius={4}
            borderColor={{
                from: "color",
                modifiers: [["darker", 1]],
            }}
            // // colorBy="indexValue"
            defs={[
                {
                    id: "lines",
                    type: "patternLines",
                    background: "inherit",
                    color: "#FF8E3B",
                    rotation: -45,
                    lineWidth: 4,
                    spacing: 12,
                },
                {
                    id: "lines2",
                    type: "patternLines",
                    background: "inherit",
                    color: "#5CB8FF",
                    rotation: -45,
                    lineWidth: 4,
                    spacing: 12,
                },
            ]}
            fill={[
                {
                    match: {
                        id: "Warmwasser (Prognose)",
                    },
                    id: "lines2",
                },
                {
                    match: {
                        id: "Raumwärme (Prognose)",
                    },
                    id: "lines",
                },
            ]}
            axisTop={null}
            axisRight={null}
            axisBottom={{
                // renderTick: CustomTickX,
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: "Jahr",
                legendPosition: "middle",
                legendOffset: 32,
            }}
            axisLeft={{
                // renderTick: CustomTickY,
                tickValues: 5,
                tickSize: 3,
                tickPadding: 5,
                tickRotation: 30,
                legend: label,
                legendPosition: "middle",
                legendOffset: -60,
            }}
            enableLabel={false}
            legends={[
                {
                    dataFrom: "keys",
                    anchor: "bottom-right",
                    direction: "column",
                    justify: false,
                    translateX: 120,
                    translateY: 0,
                    itemsSpacing: 2,
                    itemWidth: 100,
                    itemHeight: 30,
                    itemDirection: "left-to-right",
                    itemOpacity: 0.85,
                    symbolSize: 20,
                    effects: [
                        {
                            on: "hover",
                            style: {
                                itemOpacity: 1,
                            },
                        },
                    ],
                },
            ]}
            motionConfig="stiff"
            role="application"
            theme={chartTheme}
            enableGridY
            markers={
                specific && consumption && !isPortfolioAnalysis
                    ? [
                          {
                              axis: "y",
                              value: 0,
                              lineStyle: {
                                  stroke: "rgba(0, 0, 0, .6)",
                                  strokeWidth: 2,
                              },
                          },
                          {
                              axis: "y",
                              value: 130,
                              lineStyle: {
                                  stroke: "rgba(0, 0, 0, .8)",
                                  strokeWidth: 1,
                              },
                              legend: "Obergrenze Effizienzklasse D",
                              legendOrientation: "horizontal",
                          },
                      ]
                    : [
                          {
                              axis: "y",
                              value: 0,
                              lineStyle: {
                                  stroke: "rgba(0, 0, 0, .6)",
                                  strokeWidth: 2,
                              },
                          },
                      ]
            }
        />
    );
};

export default BarChart;

import React from "react";
import { Route, Routes } from "react-router-dom";
import { useContext } from "react";
import Toast from "../../components/Toast";
// global
import Sidebar from "../../components/Sidebar";
import PortfolioAnalysis from "../PortfolioAnalysis/PortfolioAnalysis";
import BuildingDetails from "../BuildingDetails/BuildingDetails";
import Results from "../ResultList/Results";
import Settings from "../Settings/Settings";
import Home from "../Home/Home";
import FAQ from "../FAQ/FAQ";
import Login from "../Login/Login";
import SidebarContext from "../../contexts/SidebarContext";
import ToastContext from "../../contexts/ToastContext";
import AuthContext from "../../contexts/AuthContext";
import NotFound from "../Error/NotFound";

/**
 * Main component of the application that manages the layout and routing.
 *
 * It conditionally renders the sidebar, toast notifications, and main content area based on user authentication status.
 * If the user is authenticated, it displays the sidebar, any active toasts, and the main content area with routing.
 * If the user is not authenticated, it displays the login screen.
 *
 * Returns:
 *   ReactElement: The main layout of the application with conditional rendering based on authentication status.
 */
function Main() {
    const { isCollapsed } = useContext(SidebarContext);
    const { toastList, setToastList } = useContext(ToastContext);
    const { authenticated } = useContext(AuthContext);

    return (
        <>
            {authenticated ? (
                <div style={{ display: "flex", margin: 0 }}>
                    <Sidebar
                        style={{ flex: 0 }}
                        username={localStorage.getItem("username")}
                        usergroup={localStorage.getItem("usergroup")}
                    />
                    {toastList.map((toast, idx) => {
                        return (
                            <Toast
                                display={toast.display}
                                setDisplay={(val) =>
                                    setToastList((list) => {
                                        list[idx].display = val;
                                    })
                                }
                                message={toast.message}
                                severity={toast.severity}
                                duration={toast.duration}
                                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                                data_testid={toast.data_testid}
                            />
                        );
                    })}

                    <main
                        className="content"
                        style={{ flex: 1, margin: 6, height: 100, marginLeft: isCollapsed ? 100 : 280 }}
                    >
                        <Routes>
                            <Route path="dashboard" element={<Home />} />
                            <Route path="results" element={<Results />} />
                            <Route path="buildingdetails/:pCurrentBuildingId" element={<BuildingDetails />} />
                            <Route path="portfolioanalysis" element={<PortfolioAnalysis />} />
                            <Route path="faq" element={<FAQ />} />
                            <Route path="settings" element={<Settings />} />
                            <Route path="*" element={<NotFound />} />
                        </Routes>
                    </main>
                </div>
            ) : (
                <Login />
            )}
        </>
    );
}

export default Main;

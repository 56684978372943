import { ResponsiveRadialBar } from "@nivo/radial-bar";
import { Box } from "@mui/material";
import { useTheme } from "@mui/material";

/**
 * Renders a responsive radial bar chart with custom styling and legends.
 *
 * This component displays a radial bar chart based on the provided data. The chart is styled
 * with a custom theme and includes legends. The chart's start and end angles, inner radius,
 * padding, margin, colors, and other properties are configurable. Legends are positioned
 * to the right of the chart and include hover effects.
 *
 * @param {Object} props - The component props.
 * @param {Array} props.data - An array of data objects for the radial bar chart.
 * @returns {JSX.Element} A box containing the ResponsiveRadialBar chart.
 *
 * @example
 * const data = [
 *   { id: "A", value: 60 },
 *   { id: "B", value: 80 },
 *   // ... more data
 * ];
 * <RadialBarChart data={data} />
 */
const RadialBarChart = ({ data }) => {
    const chartTheme = {
        // background: "#ffffff",
        textColor: "#333333",
        fontSize: 13,
        axis: {
            domain: {
                line: {
                    stroke: "#777777",
                    strokeWidth: 0,
                },
            },
            legend: {
                text: {
                    fontSize: 16,
                    fill: "#000000",
                },
            },
            ticks: {
                line: {
                    stroke: "#777777",
                    strokeWidth: 2,
                },
                text: {
                    fontSize: 13,
                    fill: "#333333",
                },
            },
        },
        grid: {
            line: {
                stroke: "#dddddd",
                strokeWidth: 1,
            },
        },
        legends: {
            title: {
                text: {
                    fontSize: 11,
                    fill: "#333333",
                },
            },
            text: {
                fontSize: 13,
                fill: "#333333",
            },
            ticks: {
                line: {},
                text: {
                    fontSize: 10,
                    fill: "#333333",
                },
            },
        },
        annotations: {
            text: {
                fontSize: 13,
                fill: "#333333",
                outlineWidth: 2,
                outlineColor: "#ffffff",
                outlineOpacity: 1,
            },
            link: {
                stroke: "#000000",
                strokeWidth: 1,
                outlineWidth: 2,
                outlineColor: "#ffffff",
                outlineOpacity: 1,
            },
            outline: {
                stroke: "#000000",
                strokeWidth: 2,
                outlineWidth: 2,
                outlineColor: "#ffffff",
                outlineOpacity: 1,
            },
            symbol: {
                fill: "#000000",
                outlineWidth: 2,
                outlineColor: "#ffffff",
                outlineOpacity: 1,
            },
        },
        tooltip: {
            container: {
                background: "#ffffff",
                color: "#333333",
                fontSize: 12,
            },
            basic: {},
            chip: {},
            table: {},
            tableCell: {},
            tableCellValue: {},
        },
    };

    const theme = useTheme();

    return (
        <Box height="55ch" width="73.2ch">
            <ResponsiveRadialBar
                data={data}
                valueFormat=">-.0f"
                startAngle={0}
                endAngle={270}
                innerRadius={0.2}
                padding={0.3}
                margin={{ top: 30, right: 70, bottom: 40, left: 0 }}
                colors={["#e3210b", "#ffb300", "#49ad2d", "#032C58"]} // ["#ff1745", "#ff9e17", "#49ad2d", "#2ba6c4"
                borderWidth={0}
                borderColor="black"
                enableRadialGrid={true}
                enableCircularGrid={false}
                radialAxisStart={{ tickSize: 8, tickPadding: 4, tickRotation: 0 }}
                circularAxisOuter={{ tickSize: 8, tickPadding: 12, tickRotation: 0 }}
                theme={chartTheme}
                legends={[
                    {
                        anchor: "right",
                        direction: "column",
                        justify: false,
                        translateX: 30,
                        translateY: 0,
                        itemsSpacing: 0,
                        itemDirection: "left-to-right",
                        itemWidth: 66,
                        itemHeight: 37,
                        itemTextColor: "#999",
                        symbolSize: 35,
                        symbolShape: "square",
                        effects: [
                            {
                                on: "hover",
                                style: {
                                    itemTextColor: "#000",
                                },
                            },
                        ],
                    },
                ]}
            />
        </Box>
    );
};

export default RadialBarChart;

import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";

const PDFUploadDropzone = ({ onFileUpload, dropzoneWidth = 100 }) => {
    const dropzoneStyles = {
        border: "2px dashed #cccccc",
        borderRadius: "4px",
        padding: "20px",
        textAlign: "center",
        cursor: "pointer",
        width: dropzoneWidth + "%",
    };

    const onDrop = useCallback(
        (acceptedFiles) => {
            // Do something with the uploaded files, for example, send them to a server.
            // Here, we'll simply call the onFileUpload callback with the acceptedFiles.
            onFileUpload(acceptedFiles);
        },
        [onFileUpload]
    );

    const { getRootProps, getInputProps } = useDropzone({ onDrop, accept: { "application/pdf": [".pdf"] } });

    return (
        <div {...getRootProps()} style={dropzoneStyles}>
            <input {...getInputProps()} />
            <p>Drag & drop oder hier klicken um eine Datei auszuwählen</p>
            <p>
                <i>Es werden nur PDF-Dateien akzeptiert.</i>
            </p>
        </div>
    );
};

export const ImageUploadDropzone = ({ onFileUpload, dropzoneWidth = 100 }) => {
    const dropzoneStyles = {
        border: "2px dashed #cccccc",
        borderRadius: "4px",
        padding: "20px",
        textAlign: "center",
        cursor: "pointer",
        width: dropzoneWidth + "%",
    };

    const onDrop = useCallback(
        (acceptedFiles) => {
            // Do something with the uploaded files, for example, send them to a server.
            // Here, we'll simply call the onFileUpload callback with the acceptedFiles.
            onFileUpload(acceptedFiles);
        },
        [onFileUpload]
    );

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: { "image/jpeg": [".jpg", ".jpeg"], "image/png": [".png"] },
    });

    return (
        <div {...getRootProps()} style={dropzoneStyles}>
            <input {...getInputProps()} />
            <p>Drag & drop oder hier klicken um eine Datei auszuwählen</p>
            <p>
                <i>Es werden nur JPG und PNG Dateien akzeptiert.</i>
            </p>
        </div>
    );
};

export default PDFUploadDropzone;
